import { useMutation, useSuspenseQuery } from "@tanstack/react-query"
import axios, { AxiosResponse } from "axios"
import { z } from "zod"
import { PatientCustomPricingType } from "../components/dashboard/dashbordComponents/customPricing/CustomPricing"
import { queryClient } from "../providers/QueryProvider"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"

export class CelebPaymentsService {
	static _instance: CelebPaymentsService = new CelebPaymentsService()

	private constructor() {
		CelebPaymentsService._instance = this
	}

	static getInstance(): CelebPaymentsService {
		return CelebPaymentsService._instance
	}

	async getSetupIntent() {
		const requestUrl = `${constants.PAYMENTS}/setupintent`
		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}

	async notifySetupVerified() {
		const requestUrl = `${constants.PAYMENTS}/paymentmethods/refresh`
		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}

	async getCards() {
		const requestUrl = `${constants.PAYMENTS}/cards/me`
		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}

	async makeCardDefault( id: string ) {
		const requestUrl = `${constants.PAYMENTS}/default/${id}`
		return await CelebRequestGeneratorService.processPutRequest( requestUrl, null )
	}

	async getTreatmentPrice( tid: string ) {
		const requestUrl = `${constants.BASE_URL}${constants.TREATMENT_PRICING}/pricingDetails/${tid}`
		return await axios.get<PatientPricingType>( requestUrl )
	}

	async updateTreatmentPricing( tid: string, payload: PatientCustomPricingType ) {
		const requestUrl = `${constants.BASE_URL}${constants.TREATMENT_PRICING}/pricingDetails/${tid}`
		return await axios.post<AxiosResponse>( requestUrl, payload )
	}

	async defaultPaymentForAddress( id: string, payload: { paymentMethodId: string } ) {
		return await CelebRequestGeneratorService.processPutRequest(
			`${constants.PAYMENTS}${constants.PREFERRED_PAYMENTS}/${id}`,
			payload,
		)
	}

	async fetchPreferenceDetails( addressId: any ) {
		return await CelebRequestGeneratorService.processGetRequest(
			`${constants.PAYMENTS}${constants.FETCH_PREFERRED_PAYMENTS}/${addressId}`,
		)
	}
}

export const listUseQueryListPaymentCardsFn = () =>
	[ "listCards", `${constants.PAYMENTS}/cards/me` ].filter( Boolean )

export const useQueryListPaymentCards = () =>
	useSuspenseQuery( {
		queryKey: [ "listCards", `${constants.PAYMENTS}/cards/me` ],
		queryFn: () =>
			CelebPaymentsService.getInstance()
				.getCards()
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => {
					return {
						listing: data,
					}
				} ),
	} )

export const useSetupIntentQuery = () =>
	useSuspenseQuery( {
		queryKey: [ "Payment", `${constants.PAYMENTS}/cards/me` ],
		queryFn: () =>
			CelebPaymentsService.getInstance()
				.getSetupIntent()
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => {
					return {
						secretKey: data,
					}
				} ),
	} )

export const useSetupVerifiedMutation = () =>
	useMutation( {
		mutationFn: () => CelebPaymentsService.getInstance().notifySetupVerified(),
		onSuccess: () => {
			return queryClient.invalidateQueries( {
				queryKey: listUseQueryListPaymentCardsFn(),
				exact: false,
			} )
		},
	} )
export const useDefaultPaymentMutation = () =>
	useMutation( {
		mutationFn: ( id: string ) => CelebPaymentsService.getInstance().makeCardDefault( id ),
		onSuccess: () => {
			return queryClient.invalidateQueries( {
				queryKey: listUseQueryListPaymentCardsFn(),
				exact: false,
			} )
		},
	} )

export const useGetPaymentPriceQuery = ( treatmentId: string | null ) => {
	return useSuspenseQuery( {
		queryKey: [],
		queryFn: async () => {
			try {
				const res = await CelebPaymentsService.getInstance().getTreatmentPrice( treatmentId! )
				console.log( "data", res )
				return res.data
			} catch ( error ) {
				console.error( "Error fetching Pricing details:", error )
				throw new Error( "Failed to fetch pricing details" )
			}
		},
	} )
}

export const useUpdateTreatmentPricing = () =>
	useMutation( {
		mutationFn: ( {
			treatmentId,
			payload,
		}: {
			treatmentId: string
			payload: PatientCustomPricingType
		} ) => CelebPaymentsService.getInstance().updateTreatmentPricing( treatmentId, payload ),
		onSuccess: () => {
			return queryClient.invalidateQueries()
		},
	} )

export const useSetPreferredPaymentMutation = () =>
	useMutation( {
		mutationFn: ( { addressId, payload }: { addressId: string; payload: string } ) =>
			CelebPaymentsService.getInstance().defaultPaymentForAddress( addressId, {
				paymentMethodId: payload,
			} ),
		onSuccess: () => {
			return queryClient.invalidateQueries()
		},
	} )

export const FetchPreferenceDetails = z.object( {
	status: z.number(),
	message: z.string(),
	record: z.object( { id: z.string() } ).nullable().default( null ),
} )

export const usePreferencefetchDetails = (
	addressId: string | undefined | null,
	userId?: string,
): { data?: any } => {
	const result = useSuspenseQuery( {
		queryKey: [ "preferredCardPayment", addressId ],
		queryFn: async () => {
			return CelebPaymentsService.getInstance()
				.fetchPreferenceDetails( addressId )
				.then( ( res ) => {
					return {
						data: res?.data?.data?.record?.card_id || null,
					}
				} )
				.catch( ( error ) => {
					console.error( "Error fetching preference details:", error )
					return { data: null }
				} )
		},
	} )

	return result
}

export const treatmentPricingSchema = z.object( {
	price: z.number(),
	status: z.string(),
	is_approved: z.boolean(),
	approved_by: z.string(),
} )

export const userServicePricingsSchema = z.object( {
	priceLower: z.string(),
	priceUpper: z.string(),
	priceBoth: z.string(),
	"service.id": z.string(),
	"service.name": z.string(),
} )

export const servicePricingSchema = z.object( {
	priceLower: z.number(),
	priceUpper: z.number(),
	priceBoth: z.number(),
} )

export const elementSchema = z.object( {
	price: z.number(),
	status: z.string(),
	is_approved: z.boolean(),
	approved_by: z.string(),
} )

export const applicablePricingSchema = z.object( {
	element: elementSchema,
	from: z.enum( [ "servicePricingSchema", "userServicePricingsSchema", "treatmentPricing" ] ),
} )

export const pricingRefTypeSchema = z.object( {
	treatmentPricing: treatmentPricingSchema,
	userServicePricings: userServicePricingsSchema,
	servicePricing: servicePricingSchema,
	applicablePricing: applicablePricingSchema,
} )

export type PatientPricingType = z.infer<typeof pricingRefTypeSchema>
