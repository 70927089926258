import { useState } from "react"
import { Alert, Button, Modal, Nav, Navbar } from "react-bootstrap"
import {
	getTPStatusQuery,
	useTreatmentStatusMutation,
} from "../../../../services/CelebTreatment.Service"
import { usePatientDialogStore } from "../../../../store/patientDailogStore"
import { userSessionStore } from "../../../../store/userSessionStore"

const CheckTPStatus = () => {
	const { setCheckTpStatusForTreatment, checkTpStatusForTreatment } = usePatientDialogStore()

	return (
		<Modal
			show={!!checkTpStatusForTreatment}
			onHide={() => setCheckTpStatusForTreatment( null )}
			centered
			size="lg"
		>
			<Navbar
				style={{ backgroundColor: "#f5f5f5", borderBottom: "1px solid #ccc" }}
				as={Modal.Header}
				closeButton
			>
				<Nav className="w-100" style={{ marginTop: "5px" }}>
					<Nav.Link className="nav-link-opposite" eventKey={"#TPcheck"} active href="#TPcheck">
						Treatment TP Check
					</Nav.Link>
				</Nav>
			</Navbar>

			<CheckTPStatusImp />
		</Modal>
	)
}

const CheckTPStatusImp = () => {
	const { checkTpStatusForTreatment, setCheckTpStatusForTreatment } = usePatientDialogStore()
	const { data } = getTPStatusQuery( checkTpStatusForTreatment )
	const { mutateAsync: updateTaskStatus } = useTreatmentStatusMutation()
	const { setShowToastJson } = userSessionStore()
	const [ showConfirmation, setShowConfirmation ] = useState( false )

	if ( !data ) return null

	return (
		<Modal.Body>
			{data.status == "401" ? (
				<Alert variant="danger" className="mb-0">
					<strong>Status:</strong> {data.status} <br />
					<strong>Message:</strong> {data.message}
				</Alert>
			) : (
				data?.status && (
					<>
						<p style={{ fontSize: "20px" }}>{data.message}</p>
					</>
				)
			)}
			<Button variant="primary" className="mt-2" onClick={() => setShowConfirmation( true )}>
				Re-Upload Data
			</Button>

			<Alert variant="warning" className="mt-3 mb-0" hidden={!showConfirmation}>
				<p className="mb-2">
					Re-uploading the data may overwrite existing treatment records. Are you sure?
				</p>
				<div className="d-flex justify-content-end">
					<Button variant="secondary" className="me-2" onClick={() => setShowConfirmation( false )}>
						Cancel
					</Button>
					<Button
						variant="danger"
						onClick={async ( e ) => {
							const result = await updateTaskStatus( {
								treatmentId: checkTpStatusForTreatment!,
								editorFilestatus: "pending",
							} )
							if ( result ) {
								setCheckTpStatusForTreatment( null )
								setShowToastJson( {
									message: "You can now re-upload the data.",
									status: "Success",
									timer: 5000,
									visible: true,
								} )
							}
						}}
					>
						Confirm
					</Button>
				</div>
			</Alert>
		</Modal.Body>
	)
}

export default CheckTPStatus
