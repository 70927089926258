import { create } from "zustand"
import { PatientSchemaType } from "../services/CelebPatient.Service"

interface CommonStore {
	transferDialogOpen: string | null
	setTransferDialogOpen: ( next: string | null ) => void

	openCustomPricing: string | null
	setOpenCustomPricing: ( next: string | null ) => void

	patientDeatils: PatientSchemaType | null
	setPatientDeatils: ( next: PatientSchemaType | null ) => void

	openInvoiceList: boolean
	setOpenInvoiceList: ( next: boolean ) => void

	ReplaceOrderDetails: {
		PatientId: string
		ReplaceOrderMark?: string
		treatmentId: string
	} | null

	setReplaceOrderDetails: (
		next: {
			PatientId: string
			ReplaceOrderMark?: string
			treatmentId: string
		} | null,
	) => void
	tableData: number
	setTableData: ( next: number ) => void

	reset: () => void
}

export const useCommonStore = create<CommonStore>()( ( set ) => ( {
	transferDialogOpen: null,
	setTransferDialogOpen: ( next: string | null ) => set( { transferDialogOpen: next } ),

	openCustomPricing: null,
	setOpenCustomPricing: ( next: string | null ) => set( { openCustomPricing: next } ),

	patientDeatils: null,
	setPatientDeatils: ( next: PatientSchemaType | null ) => set( { patientDeatils: next } ),

	openInvoiceList: false,
	setOpenInvoiceList: ( next: boolean ) => set( { openInvoiceList: next } ),

	ReplaceOrderDetails: null,
	setReplaceOrderDetails: (
		next: {
			PatientId: string
			ReplaceOrderMark?: string
			treatmentId: string
		} | null,
	) => set( { ReplaceOrderDetails: next } ),

	tableData: 0,
	setTableData: ( next: number ) => set( { tableData: next } ),

	reset: () =>
		set( {
			transferDialogOpen: null,
		} ),
} ) )
