import { ListItemText, MenuItem, MenuList } from "@mui/material"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useGetReplaceOrders } from "../../../services/CelebTreatment.Service"
import { useCommonStore } from "../../../store/commonStore"

const ShowReplaceOrderTable = ( {
	activeOrder,
	patientId,
}: {
	activeOrder?: string
	patientId: string
} ) => {
	const { setReplaceOrderDetails } = useCommonStore()
	const { data: replaceOrderData } = useGetReplaceOrders( patientId )
	const [ searchParams, setSearchParams ] = useSearchParams()
	const newParamsSearch = new URLSearchParams( searchParams )
	const addNewQueryParam = ( patientID: unknown ) => {
		const newParams = new URLSearchParams( searchParams )
		newParams.set( "PatientId", patientID + "" )
		setSearchParams( newParams )
	}
	type OrderDetails = {
		PatientId: string
		ReplaceOrderMark: string
		treatmentId?: string
	}

	const [ originalOrder, setOriginalOrder ] = useState<OrderDetails | undefined>( undefined )

	useEffect( () => {
		if ( replaceOrderData?.orders && replaceOrderData.orders.length > 0 ) {
			setOriginalOrder( {
				PatientId: patientId,
				ReplaceOrderMark: "Original",
				treatmentId: replaceOrderData.orders[ 0 ].originalTreatmentId,
			} )
		}
	}, [ replaceOrderData, patientId ] )

	return (
		<>
			{replaceOrderData?.orders?.map( ( order ) => (
				<MenuList dense key={order.id}>
					<MenuItem
						onClick={( event ) => {
							event.stopPropagation()
							event.preventDefault()
							addNewQueryParam( order.treatmentId )
							setReplaceOrderDetails( {
								PatientId: patientId,
								ReplaceOrderMark: order[ "replacement-number" ]
									? `R${order[ "replacement-number" ]}`
									: "Original",
								treatmentId: order.treatmentId,
							} )
						}}
					>
						<ListItemText className="d-flex justify-content-center">
							<div style={{ fontWeight: "600" }}>
								Replacement {order[ "replacement-number" ]}{" "}
								{newParamsSearch.get( "PatientId" ) == order.treatmentId && (
									<span
										className="dot"
										style={{
											height: "10px",
											width: "10px",
											backgroundColor: "#41f200",
											borderRadius: "50%",
											display: "inline-block",
										}}
									></span>
								)}
							</div>
						</ListItemText>
					</MenuItem>
				</MenuList>
			) )}
			{originalOrder && (
				<MenuList dense>
					<MenuItem
						onClick={( event ) => {
							event.stopPropagation()
							event.preventDefault()
							addNewQueryParam( originalOrder.treatmentId )
							setReplaceOrderDetails( {
								PatientId: patientId,
								ReplaceOrderMark: "O",
								treatmentId: originalOrder.treatmentId!,
							} )
						}}
					>
						<ListItemText className="d-flex justify-content-center">
							<div style={{ fontWeight: "600" }}>
								Original{" "}
								<span
									hidden={newParamsSearch.get( "PatientId" ) !== originalOrder.treatmentId}
									className="dot"
									style={{
										height: "10px",
										width: "10px",
										backgroundColor: "#41f200",
										borderRadius: "50%",
										display: "inline-block",
									}}
								></span>
							</div>
						</ListItemText>
					</MenuItem>
				</MenuList>
			)}
		</>
	)
}

export default ShowReplaceOrderTable
