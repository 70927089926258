import React from "react"
import "./assets/styles/components/celebServiceTabs.css"
import "./assets/styles/components/navbar.css"
import "./assets/styles/pages/landing.page.css"
import { AccountsListDialog } from "./components/dialogs/accounts-v2/AccountsList.dialog"
import PatientEditDialog from "./components/dialogs/editPatients-v2/PatientEdit.dialog"
import PatientAddDialog from "./components/dialogs/patient-v2/PatientAdd.dialog"
import ProfileDialog from "./components/dialogs/profile-v2/Profile.dialog"
import PatientReplaceDialog from "./components/dialogs/replaceOrder-v2/PatientReplace.dialog"
import ServiceListDialog from "./components/dialogs/services-v2/servicePages/ServicesList.dialog"
const TaskCompletionDialog = React.lazy(
	() => import( "./components/dialogs/tasks/TaskCompletion.dialog" ),
)
const PatientTransferDailog = React.lazy(
	() => import( "./components/dialogs/transfer/PatientTransfer.dailog" ),
)

import CheckTPStatus from "./components/dashboard/dashbordComponents/checkTPStatus/CheckTPStatus"
import CustomPricing from "./components/dashboard/dashbordComponents/customPricing/CustomPricing"
import ViewDoctorClinic from "./components/dialogs/accounts-v2/ViewDoctorClinic.tab"
import InvoiceTabDialog from "./components/dialogs/invoiceTab/invoiceTab.dialog"
import PreferredPaymentMethodDialog from "./components/dialogs/payments/PreferredPaymentMethod"
import SalesAssociatedDoctor from "./components/dialogs/sales/SalesAssociatedDoctorList"
import SalesDialog from "./components/dialogs/sales/SalesList.dialog"
import { ToastPopup } from "./components/toast/Toast"
import ProgressBarLoader from "./components/utlity/ProgressBar.Loader"
import { useCommonStore } from "./store/commonStore"
import { usePatientDialogStore } from "./store/patientDailogStore"
import { useProfileDialogStore } from "./store/profileDialogStore"

const AppCommon = React.memo( () => {
	const patientStore = usePatientDialogStore()
	const { openCustomPricing } = useCommonStore()
	const profileStore = useProfileDialogStore()
	return (
		<React.Suspense>
			<ServiceListDialog />
			<AccountsListDialog />
			<ViewDoctorClinic />
			<TaskCompletionDialog />
			<SalesAssociatedDoctor />
			<InvoiceTabDialog />
			<SalesDialog />
			{profileStore.modalOpen && <ProfileDialog />}
			{profileStore.openCreditCardPreference && <PreferredPaymentMethodDialog />}
			{patientStore.replaceOpen.id ? <PatientReplaceDialog /> : <></>}
			{patientStore.typesEditOpen ? <PatientEditDialog /> : <></>}
			{patientStore.transferPatientId ? <PatientTransferDailog /> : <></>}
			<ProgressBarLoader />
			<ToastPopup />
			{patientStore.createOpen && <PatientAddDialog />}
			{patientStore.checkTpStatusForTreatment && <CheckTPStatus />}
			{openCustomPricing && <CustomPricing />}
		</React.Suspense>
	)
} )

export default AppCommon
