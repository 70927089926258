import React from "react"
import { CloseButton } from "react-bootstrap"
import { getFileReadUrlMutatefn } from "../../../services/CelebMedicalFile.Service"
import { getTreatmentFilesByCategoryQuery } from "../../../services/CelebTreatmentFiles.Service"
import {
	fileUploadingType,
	PatientDialogState,
	usePatientDialogStore,
} from "../../../store/patientDailogStore"
import { Addbutton, fileResult } from "./PatientPhotos.tab"
import { photosLibrary } from "./defaultFileShowCase"

const PatientPhotoComposite = () => {
	const PatientDialog = usePatientDialogStore()
	const { patientCompositePhotoJson, setPatientCompositePhotoJson } = usePatientDialogStore()
	const { mutateAsync: getImage } = getFileReadUrlMutatefn()
	const { data: defaultTreatmentFiles } = getTreatmentFilesByCategoryQuery(
		PatientDialog.typesEditOpen,
		[ "patient" ],
	)
	const [ img, setImg ] = React.useState<fileUploadingType>(
		patientCompositePhotoJson[ "patient_photo_composite" ],
	)

	React.useEffect(
		() => setImg( patientCompositePhotoJson[ "patient_photo_composite" ] ),
		[ patientCompositePhotoJson ],
	)

	React.useEffect( () => {
		defaultTreatmentFiles.forEach( async ( file: fileResult ) => {
			if (
				file.fileCategory == "patient_photo" &&
				file.fileSubCategory == "patient_photo_composite"
			) {
				getImage( file.fileUri )
					.then( ( imageUri ) => imageUri )
					.then( ( imgData ) => setImg( { file: imgData, additionalInfo: {} } ) )
			}
		} )
	}, [] )

	const clearPhotoCheck = () => {
		setImg( { additionalInfo: {}, file: null } )
		setPatientCompositePhotoJson( "patient_photo_composite", { additionalInfo: {}, file: null } )
	}
	return (
		<React.Fragment>
			{img.file ? (
				<center>
					<SingleImageDisplay
						img={
							img && img.file
								? typeof img.file === "string"
									? img.file
									: URL.createObjectURL( img.file )
								: ""
						}
						clearPhoto={() => clearPhotoCheck()}
					/>
				</center>
			) : (
				<ImageGrid />
			)}
		</React.Fragment>
	)
}

export default PatientPhotoComposite

const SingleImageDisplay = ( { img, clearPhoto }: { img: string; clearPhoto: () => void } ) => {
	return (
		<>
			<div
				className="card text-bg-dark d-flex mb-8"
				style={{ height: "45%", width: "45%", position: "relative" }}
				onClick={() => ""}
			>
				<img
					alt=""
					src={img ? img : ""}
					hidden={!img}
					className="card-img img-thumbnail"
					style={{ height: "100%", width: "100%", objectFit: "cover" }}
				/>
				{img && (
					<CloseButton
						onClick={( e ) => {
							e.preventDefault()
							clearPhoto()
						}}
						hidden={img ? false : true}
						style={{
							position: "absolute",
							top: "0px",
							right: "0px",
							backgroundColor: "#E3E0F0",
							borderRadius: "50%",
						}}
					/>
				)}
			</div>
		</>
	)
}

export const ImageGrid = () => {
	return (
		<section className="d-flex justify-content-center">
			<div>
				<span className="d-flex gap-3 my-2">
					<DummyImageComponent property="patient_photo_face_side" />
					<DummyImageComponent property="patient_photo_face_front" />
					<DummyImageComponent property="patient_photo_face_front_teeth" />
				</span>
				<span className="d-flex gap-3 my-2">
					<DummyImageComponent property="patient_photo_jaw_upper" />
					<CardComponent property="patient_photo_composite" />
					<DummyImageComponent property="patient_photo_jaw_lower" />
				</span>
				<span className="d-flex gap-3 my-2">
					<DummyImageComponent property="patient_photo_jaw_right" />
					<DummyImageComponent property="patient_photo_jaw_front" />
					<DummyImageComponent property="patient_photo_jaw_left" />
				</span>
			</div>
		</section>
	)
}

const CardComponent = ( {
	property,
}: {
	property: keyof PatientDialogState["patientCompositePhotoJson"]
} ) => {
	const imageRef = React.useRef<HTMLInputElement | null>( null )
	const { patientCompositePhotoJson, setPatientCompositePhotoJson } = usePatientDialogStore()
	const fileImageSaver = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		e.preventDefault()
		if ( e.currentTarget.files ) {
			setPatientCompositePhotoJson( property, {
				file: e.currentTarget.files[ 0 ],
				additionalInfo: {},
			} )
		}
	}
	const img = patientCompositePhotoJson[ property ]

	return (
		<div>
			<span className="d-flex" style={{ height: "10rem", width: "10rem" }}>
				<div
					className="card text-bg-secondary"
					onClick={() => {
						imageRef.current?.click()
					}}
					style={{ width: "10rem" }}
				>
					<img
						alt=""
						src={img && img.file ? URL.createObjectURL( img.file ) : ""}
						className="img-fluid"
						style={{ height: "-webkit-fill-available" }}
					/>
					{img && (
						<CloseButton
							onClick={( e ) => {
								e.preventDefault()
								setPatientCompositePhotoJson( property, {
									file: null,
									additionalInfo: {},
								} )
							}}
							hidden={img.file ? false : true}
							style={{
								position: "absolute",
								top: "0px",
								right: "0px",
								backgroundColor: "#E3E0F0",
								borderRadius: "50%",
							}}
						/>
					)}
					<Addbutton />
				</div>
			</span>
			<input
				type="file"
				accept="image/*"
				ref={imageRef}
				hidden
				onChange={fileImageSaver}
				id="imageChange"
			/>
		</div>
	)
}

const DummyImageComponent = ( {
	property,
}: {
	property: keyof PatientDialogState["individualPhoto"]
} ) => {
	return (
		<span className="d-flex" style={{ height: "10rem", width: "10rem" }}>
			<div className="card text-bg-secondary" style={{ width: "10rem" }}>
				<img
					alt={photosLibrary[ property ]}
					src={photosLibrary[ property ]}
					className="img-fluid"
					style={{ height: "-webkit-fill-available", objectFit: "contain" }}
				/>
			</div>
		</span>
	)
}
