import { useMutation, useSuspenseQuery } from "@tanstack/react-query"
import { z } from "zod"
import { queryClient } from "../providers/QueryProvider"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"
import { ServiceAddress } from "./CelebService.Service"
export class CelebDoctorAddressService {
	static _instance: CelebDoctorAddressService = new CelebDoctorAddressService()

	private constructor() {
		CelebDoctorAddressService._instance = this
	}

	static getInstance(): CelebDoctorAddressService {
		return CelebDoctorAddressService._instance
	}

	async add( requestPayload: AddressSchemaType | ServiceAddress | unknown ) {
		const uri = constants.DOCTOR_ADDRESS
		return await CelebRequestGeneratorService.processPostRequest( uri, requestPayload )
	}

	async edit( id: String, requestPayload: AddressSchemaType ) {
		return await CelebRequestGeneratorService.processPutRequest(
			constants.DOCTOR_ADDRESS + "/" + id,
			requestPayload,
		)
	}

	async getMyAddresses() {
		return await CelebRequestGeneratorService.processGetRequest( constants.DOCTOR_ADDRESS + "/me" )
	}

	async getDoctorAddresses( doctorId: any ) {
		return await CelebRequestGeneratorService.processGetRequest(
			`${constants.DOCTOR_ADDRESS}${constants.DOCTOR}/${doctorId}`,
		)
	}

	async deleteAddress( id: string ) {
		return await CelebRequestGeneratorService.processDeleteRequest(
			`${constants.DOCTOR_ADDRESS}/${id}`,
		)
	}
}

export const listAddressQueryKeyFn = () =>
	[ "listOfAddress", constants.DOCTOR_ADDRESS ].filter( Boolean )

export const useListAddressQuery = ( doctorId?: string | null ) => {
	return useSuspenseQuery( {
		queryKey: [ "listOfAddress", doctorId ],
		queryFn: () => {
			if ( doctorId != null ) {
				return CelebDoctorAddressService.getInstance()
					.getDoctorAddresses( doctorId )
					.then( ( res ) => ( { data: res?.data?.data } ) )
					.then( ( { data } ) => {
						return {
							Address: data || [],
						}
					} )
			} else {
				return CelebDoctorAddressService.getInstance()
					.getMyAddresses()
					.then( ( res ) => ( { data: res?.data?.data } ) )
					.then( ( { data } ) => {
						return {
							Address: data || [],
						}
					} )
			}
		},
	} )
}

export const useGetDoctorAddressesMutate = () =>
	useMutation( {
		mutationFn: ( doctorId: string ) =>
			CelebDoctorAddressService.getInstance()
				.getDoctorAddresses( doctorId )
				.then( ( data ) => data.data.data )
				.then( ( dataValue ) => dataValue ),
		onSuccess: ( data ) => {
			return data
		},
	} )

export const useDeleteAddressMutation = () =>
	useMutation( {
		mutationFn: ( payload: string ) => CelebDoctorAddressService.getInstance().deleteAddress( payload ),
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listAddressQueryKeyFn(), exact: false } )
		},
	} )

export const useInsertAddressMutation = () =>
	useMutation( {
		mutationFn: async ( payload: AddressSchemaType | ServiceAddress | unknown ) => {
			return await CelebDoctorAddressService.getInstance()
				.add( payload )
				.then( ( data ) => data.data )
		},
		onSuccess: async ( data ) => {
			await queryClient.invalidateQueries()
		},
		onError: ( error ) => {
			console.error( "Error inserting address:", error )
		},
	} )

export const useEditAddressMutation = () =>
	useMutation( {
		mutationFn: ( payload: AddressSchemaType ) =>
			CelebDoctorAddressService.getInstance().edit( payload.id!, payload ),
		onSuccess: () => {
			return queryClient.invalidateQueries()
		},
	} )

export const cardAddress = z.array(
	z.object( {
		id: z.string(),
		addressId: z.string(),
		cardId: z.string(),
		updatedBy: z.string(),
		createdAt: z.string(),
		updatedAt: z.string(),
		preferredCard: z.object( {
			id: z.string(),
			stripId: z.string(),
			brand: z.string(),
			expMonth: z.number(),
			expYear: z.number(),
			cardNumber: z.number(),
			type: z.string(),
			isDefault: z.boolean(),
			stripCustomerId: z.string(),
			userId: z.string(),
			createdAt: z.string(),
			updatedAt: z.string(),
		} ),
	} ),
)

export const AddressSchema = z.object( {
	id: z.string().optional(),
	name: z.string().trim().min( 3, { message: "Please enter valid address Name" } ),
	addressLine1: z.string().trim().min( 3, { message: "Please enter valid address" } ),
	addressLine2: z.string().trim().nullable().optional(),
	city: z.string().trim().min( 2, { message: "Please enter valid city" } ),
	state: z.string().trim().min( 2, { message: "Please enter valid state" } ),
	country: z.string().trim().min( 2, { message: "Please enter valid country" } ),
	pincode: z.string().trim().min( 2, { message: "Please enter valid Pincode" } ),
	deleted: z.boolean().optional(),
	deletedAt: z.null().optional(),
	deletedBy: z.null().optional(),
	doctorId: z.string().optional(),
	category: z.string().optional(),
	createdAt: z.string().optional(),
	updatedAt: z.string().optional(),
	preferredPaymentMethods: cardAddress.optional(),
} )

export const PrefCardDetails = z.object( { selectedCardNumber: z.string() } )

export type AddressSchemaType = z.infer<typeof AddressSchema>
