import { DepGraph } from "dependency-graph"

export const hasCyclicDependency = ( tasks: any[] ): boolean => {
	if ( !tasks || tasks.length === 0 ) return false
	const graph = new DepGraph()
	tasks.forEach( ( { id } ) => {
		if ( !graph.hasNode( id.toString() ) ) {
			graph.addNode( id.toString() )
		}
	} )
	tasks.forEach( ( { id, previousTasks } ) => {
		previousTasks?.forEach( ( prev: any ) => {
			const prevId =
				typeof prev === "object" && prev.value ? prev.value.toString() : prev.toString()
			if ( !graph.hasNode( prevId ) ) {
				graph.addNode( prevId )
			}
			graph.addDependency( prevId, id.toString() )
		} )
	} )

	try {
		graph.overallOrder()
		return false
	} catch {
		return true
	}
}
