import React from "react"
import { Placeholder } from "react-bootstrap"

const CompletedTableSkelton = () => {
	const skeletonData = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ]
	return (
		<React.Suspense>
			{skeletonData.map( ( cx ) => {
				return (
					<tr key={cx}>
						<td style={{ height: "3rem" }}>
							<Placeholder xs={10} size="lg" height="2rem" animation="wave" bg="#141e3b" />
						</td>
						<td>
							<Placeholder xs={10} size="lg" height="2rem" animation="wave" bg="#141e3b" />
						</td>
						<td>
							<Placeholder xs={10} animation="wave" bg="" />
						</td>
						<td>
							<Placeholder xs={10} animation="wave" bg="" />
						</td>
						<td>
							<Placeholder xs={10} animation="wave" bg="" />
						</td>
						<td>
							<Placeholder xs={10} animation="wave" bg="" />
						</td>
						<td>
							<Placeholder xs={10} animation="wave" bg="" />
						</td>
						<td>
							<Placeholder xs={10} animation="wave" bg="" />
						</td>
					</tr>
				)
			} )}
		</React.Suspense>
	)
}

export default CompletedTableSkelton
