import { faCalendarDays } from "@fortawesome/free-regular-svg-icons"
import { faFilter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import Pagination from "rc-pagination"
import React from "react"
import {
	Alert,
	Badge,
	Button,
	Col,
	Form,
	Modal,
	Nav,
	Navbar,
	OverlayTrigger,
	Popover,
	PopoverProps,
	Row,
	Table,
} from "react-bootstrap"
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay"
import { CSVLink } from "react-csv"
import Moment from "react-moment"
import { JSX } from "react/jsx-runtime"
import { constants } from "../../../services/CelebApiConstants"
import { invoiceData, useListInvoiceQuery } from "../../../services/CelebInvoice.Service"
import { useCommonStore } from "../../../store/commonStore"
import { userSessionStore } from "../../../store/userSessionStore"
import SearchInput from "../../form/SearchInput"
import { descriptionMap, infoMapperNames } from "../../utlity/GenricFunctions"

const InvoiceTabDialog = () => {
	const commonStore = useCommonStore()
	const MemorizeInvoiceIndexImpl = React.memo( InvoiceIndexImpl )
	return (
		<Modal
			show={commonStore.openInvoiceList}
			size="xl"
			backdrop="static"
			onHide={() => commonStore.setOpenInvoiceList( false )}
			onEscapeKeyDown={() => {
				commonStore.setOpenInvoiceList( false )
			}}
		>
			<MemorizeInvoiceIndexImpl />
		</Modal>
	)
}

const InvoiceIndexImpl = () => {
	const commonStore = useCommonStore()
	const sessionStore = userSessionStore()
	const [ show, setShow ] = React.useState( false )
	const doctorId = sessionStore.user.id
	const [ currentPage, setCurrentPage ] = React.useState( 1 )
	const [ query, setQuery ] = React.useState<{ search?: string; startFrom?: string; endTo?: string }>(
		{},
	)
	const [ filter, setFilter ] = React.useState<{
		startFrom?: string
		endTo?: string
		message?: string
	}>( {} )
	const pageOffset = ( currentPage - 1 ) * constants.PAGE_COUNT
	const {
		data: { data: invoiceInfoData, total },
	} = useListInvoiceQuery(
		{
			pageOffset,
			query: { ...query, startFrom: query.startFrom || "", endTo: query.endTo || "" },
			limit: constants.PAGE_COUNT,
		},
		sessionStore.user.isDoctor ? doctorId : "",
	)
	const handlePagination = ( pageNumber: number ) => {
		setCurrentPage( pageNumber )
	}
	const CsvDownload = CSVLink as unknown as React.FC<any>

	interface TreatmentInfo {
		serviceAddress?: { doctorId: string }[]
		treatmentInfoData: {
			id: string
			email: string
			firstName: string
			lastName: string
			patientFirstName: string
			patientLastName: string
			treatmentDescription: string
			entityId: string
		}
	}

	interface InvoiceData {
		treatmentInfo: TreatmentInfo
		treatmentId: string
	}

	const getReceiptUrl = ( x: InvoiceData ): string => {
		try {
			const serviceAddress = x?.treatmentInfo?.serviceAddress
			const doctorId = serviceAddress?.[ 0 ]?.doctorId
			return `/receipt/${x.treatmentId}?serviceId=${doctorId}&returnUrl=${encodeURIComponent( window?.location?.href )}`
		} catch ( error ) {
			console.error( "Error parsing treatmentInfo:", error )
			return "#"
		}
	}

	const onQueryChange = ( q: any ) => {
		if ( q ) {
			setQuery( { startFrom: q.startFrom, endTo: q.endTo } )
		} else {
			setQuery( {} )
		}
		setCurrentPage( 1 )
		setShow( false )
	}

	const renderPopover = (
		props:
			| ( JSX.IntrinsicAttributes & PopoverProps & React.RefAttributes<HTMLDivElement> )
			| OverlayInjectedProps,
	) => (
		<Popover id="popover-basic" {...props} className="custom-popover-date">
			<Popover.Header as="h3" className="d-flex align-item-center gap-2">
				<FontAwesomeIcon style={{ cursor: "pointer" }} color="#8faadc" icon={faCalendarDays} />
				<p className="my-0">Date Range</p>
			</Popover.Header>
			<Popover.Body>
				<div className="gap-2 d-flex justify-space-between align-items-center">
					<Form
						onSubmit={( e ) => {
							e.preventDefault()
							if ( filter.startFrom && filter.endTo ) {
								onQueryChange( filter )
								filter.message = ""
							} else {
								setFilter( { ...filter, message: "Please Select Date Range" } )
							}
						}}
						className="d-flex align-items-center gap-2"
					>
						<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ISO 8601">
							<div>
								<p className="mb-0" style={{ fontWeight: 500 }}>
									Start From
								</p>
								<DesktopDatePicker
									sx={{
										minWidth: "50%",
										"& .MuiInputBase-root": {
											height: "40px",
											padding: "4px",
										},
										"& .MuiOutlinedInput-root": {
											height: "37px",
										},
										"& .MuiIconButton-root": {
											paddingRight: "15px",
										},
									}}
									format="MM-DD-YYYY"
									name="startFrom"
									onChange={( e ) => {
										if ( e && dayjs( e ).isValid() ) {
											const isoDate = dayjs.utc( e ).startOf( "day" ).toISOString()
											setFilter( { ...filter, startFrom: isoDate } )
										} else {
											console.error( "Invalid date selected:", e )
										}
									}}
									value={filter.startFrom ? dayjs( filter.startFrom ) : null}
								/>
							</div>
						</LocalizationProvider>
						<b className="mt-3">To</b>
						<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ISO 8601">
							<div>
								<p className="mb-0" style={{ fontWeight: 500 }}>
									Till Date
								</p>
								<DesktopDatePicker
									sx={{
										minWidth: "50%",
										"& .MuiInputBase-root": {
											height: "40px",
											padding: "4px",
										},
										"& .MuiOutlinedInput-root": {
											height: "37px",
										},
										"& .MuiIconButton-root": {
											paddingRight: "15px",
										},
									}}
									minDate={filter.startFrom ? dayjs( filter.startFrom ) : undefined}
									format="MM-DD-YYYY"
									name="endTo"
									onChange={( e ) => {
										if ( e && dayjs( e ).isValid() ) {
											const isoDate = dayjs.utc( e ).startOf( "day" ).toISOString()
											setFilter( { ...filter, endTo: isoDate } )
										} else {
											console.error( "Invalid date selected:", e )
										}
									}}
									value={filter.endTo ? dayjs( filter.endTo ) : null}
								/>
							</div>
						</LocalizationProvider>
						<Button className="d-block mt-3 text-center" type="submit">
							Filter
						</Button>
					</Form>
				</div>
				<Alert variant="warning" className="mt-2" hidden={!filter.message}>
					{filter.message}
				</Alert>
			</Popover.Body>
		</Popover>
	)

	return (
		<React.Fragment>
			<Modal.Header closeButton={true} onHide={() => commonStore.setOpenInvoiceList( false )}>
				<Navbar id="navbarScroll" as={Modal.Header}>
					<Nav variant="tabs" defaultActiveKey="#Financials" className="d-flex mt-2 me-auto">
						<Nav.Link href="#Financials" defaultChecked active>
							Financials
						</Nav.Link>
					</Nav>
				</Navbar>
			</Modal.Header>
			<Modal.Body>
				<Col style={{ overflow: "visible" }}>
					<span className="d-flex justify-content-between mb-2">
						<div className="d-flex align-items-center">
							<SearchInput
								callback={( e ) => {
									setQuery( { search: e } )
									setCurrentPage( 1 )
								}}
							/>
							<Badge bg="secondary" className="mx-2 d-grid align-content-center h-100">
								{total} {total < 2 ? "account" : "accounts"}
							</Badge>
						</div>
						{/*
						//not working on csv for now

						<CsvDownload
							data={invoiceInfoData.map( ( e: any ) => flattenObject( e ) )}
							filename="invoice_data.csv"
						>
							<FontAwesomeIcon
								style={{ cursor: "pointer" }}
								color="#8faadc"
								size="2x"
								icon={faFileCsv}
							/>
						</CsvDownload> */}
					</span>
					<Table striped hover bordered responsive={"xl"}>
						<thead>
							<tr className="table-header" style={{ textWrap: "nowrap" }}>
								<th style={{ textAlign: "start" }}>No.</th>
								<th style={{ textAlign: "start" }}>Patient ID</th>
								<th style={{ textAlign: "start" }}>Doctor</th>
								<th style={{ textAlign: "start" }}>Treatment Type</th>
								<th style={{ textAlign: "start" }}>Amount</th>
								<th style={{ textAlign: "start" }}>
									Date{" "}
									<OverlayTrigger
										show={show}
										trigger="click"
										placement="bottom"
										overlay={( props ) => renderPopover( props )}
										onToggle={() => setShow( !show )}
									>
										<FontAwesomeIcon
											icon={faFilter}
											style={{ cursor: "pointer", color: "#8faadc", marginLeft: "5px" }}
										/>
									</OverlayTrigger>
								</th>
								<th style={{ textAlign: "start" }}>Action</th>
							</tr>
						</thead>
						<tbody>
							{invoiceInfoData.map(
								( x: invoiceData & { treatmentInfo: TreatmentInfo }, index: number ) => (
									<tr key={index} className="table-data" style={{ verticalAlign: "middle" }}>
										<td style={{ textAlign: "start" }}>{index + 1}</td>
										<td style={{ textAlign: "start" }}>
											{infoMapperNames( x?.treatmentInfo?.treatmentInfoData, [
												"id",
												"email",
												"firstName",
												"lastName",
												"patientFirstName",
												"patientLastName",
												"treatmentDescription",
											] )}
										</td>
										<td style={{ textAlign: "start" }}>
											{infoMapperNames( x.treatmentInfo?.treatmentInfoData, [
												"id",
												"email",
												"treatmentDescription",
												"patientFirstName",
												"patientLastName",
												"entityId",
											] )}
										</td>
										<td style={{ textAlign: "start" }}>
											{
												descriptionMap[
													infoMapperNames( x.treatmentInfo.treatmentInfoData, [
														"id",
														"email",
														"firstName",
														"lastName",
														"patientFirstName",
														"patientLastName",
														"entityId",
													] ) as keyof typeof descriptionMap
												]
											}
										</td>
										<td style={{ textAlign: "start" }}>{x.treatmentPayment.amount}</td>

										<td style={{ textAlign: "start" }}>
											<Moment format="MM-DD-YYYY">{x.createdAt}</Moment>
										</td>
										<td style={{ textAlign: "start" }}>
											<Button
												className="view-receipt-btn"
												href={getReceiptUrl( x )}
												size="sm"
												target="_blank"
											>
												View
											</Button>
										</td>
									</tr>
								),
							)}
						</tbody>
					</Table>
				</Col>
				<Row>
					<Pagination
						total={total}
						pageSize={constants.PAGE_COUNT}
						onChange={handlePagination}
						align={"center"}
					/>
				</Row>
			</Modal.Body>
		</React.Fragment>
	)
}

export default InvoiceTabDialog
function preventDefault() {
	throw new Error( "Function not implemented." )
}
