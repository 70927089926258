import { useMutation } from "@tanstack/react-query"
import React from "react"
import { Button, Modal, Nav, Navbar } from "react-bootstrap"
import { FieldErrors, useFormContext } from "react-hook-form"
import { useLocation } from "react-router"
import useCustomErrorMarkerTry from "../../../hooks/patient/customErrorMarker"
import { queryClient } from "../../../providers/QueryProvider"
import { CelebMedicalFileService } from "../../../services/CelebMedicalFile.Service"
import { PatientSchemaType, usePatientCreateMutation } from "../../../services/CelebPatient.Service"
import { fileUploadingType, usePatientDialogStore } from "../../../store/patientDailogStore"
import { useTeethStore } from "../../../store/toothSelectionStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { TreatmentFileCategory } from "../../../utils/appConstants"
import { clearFormState, clearHash } from "../../utlity/GenricFunctions"
import "./Patient.css"
import { photosLibraryReset } from "./defaultFileShowCase"
const PatientNavbar = () => {
	const { setValue, getValues, control, reset, watch, unregister } =
		useFormContext<PatientSchemaType>()
	const localData = JSON.parse( localStorage.getItem( "patient" ) + "" )
	const clearHashClear = clearHash()
	const {
		cbctDocumentJson,
		individualPhoto,
		xRays,
		scan,
		patientCompositePhotoJson,
		navErrors,
		setErrorToShow,
		clearState,
		setTypesEditOpen,
		setIsUploadFromScanner,
		setIsPatientDatainProcess,
		setCreateOpen,
		setCloseModalDialog,
	} = usePatientDialogStore()
	const { mutateAsync: createPatient } = usePatientCreateMutation()
	const { teethBonding, teethExtraction, resetTeethSelection } = useTeethStore()
	const { setShowToastJson } = userSessionStore()
	const { customErrorMarker } = useCustomErrorMarkerTry()
	const locationHash = useLocation()

	const additionalApiRequirments = async () => {
		setShowToastJson( {
			visible: true,
			status: "Secondary",
			message: `Uploading Patient..`,
			uploadPercentage: 0,
		} )
		const doctorAddress = getValues( "selectedOffice" )
		setValue( "office", doctorAddress )
		setValue( "treatmentPlan.treatmentPlanDoctorAddressId", doctorAddress )
		await setFormValues()
		control.handleSubmit( checkSecondaryFilesValidation, onError )()
	}

	const setFormValues = async () => {
		const date = Date.now()
		const doctorAddress = getValues( "selectedOffice" )
		const assignedDoctor = watch( "assignedDoctor" )
		setValue( "office", doctorAddress )
		setValue( "treatmentPlan.teethBonding", teethBonding )
		setValue( "treatmentPlan.teethExtraction", teethExtraction )
		setValue( "treatmentPlan.treatmentStart", date )
		const currentDoctorsWithAccess = watch( "treatmentPlan.doctorsWithAccess" )
		const updatedDoctorsWithAccess = [ assignedDoctor ]
			.concat( currentDoctorsWithAccess )
			.filter( Boolean )
		const uniqueDoctorsWithAccess = Array?.from( new Set( updatedDoctorsWithAccess ) )
		setValue( "treatmentPlan.doctorsWithAccess", uniqueDoctorsWithAccess )
	}

	const uploadDocumentsToServer = async () => {
		try {
			setCreateOpen( false )
			setShowToastJson( {
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 30000,
				uploadPercentage: 10,
			} )
			const uploadCbctData = await uploadFiles( cbctDocumentJson, TreatmentFileCategory.PATIENT_CBCT )
			setShowToastJson( {
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 15000,
				uploadPercentage: 20,
			} )
			const uploadPhotoDataComposite = await uploadFiles(
				patientCompositePhotoJson,
				TreatmentFileCategory.PATIENT_PHOTO,
			)
			const uploadPhotoDataJson = await uploadFiles(
				individualPhoto,
				TreatmentFileCategory.PATIENT_PHOTO,
			)
			setShowToastJson( {
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 15000,
				uploadPercentage: 30,
			} )
			const uploadXRaysData = await uploadFiles( xRays, TreatmentFileCategory.PATIENT_XRAY )
			setShowToastJson( {
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 15000,
				uploadPercentage: 40,
			} )
			const uploadScanData = await uploadFiles( scan, TreatmentFileCategory.PATIENT_SCAN )
			setShowToastJson( {
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 15000,
				uploadPercentage: 60,
			} )
			const treatmentFiles = [
				...uploadCbctData,
				...uploadPhotoDataComposite,
				...uploadXRaysData,
				...uploadScanData,
				...uploadPhotoDataJson,
			].filter( ( file ): file is UploadData =>
				file !== undefined && file.uri.length >= 1 ? true : false,
			)
			setShowToastJson( {
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 15000,
				uploadPercentage: 70,
			} )
			setValue( "treatmentPlan.treatmentFiles", treatmentFiles )

			const treatmentType = getTreatmentType()
			setValue( "treatmentPlan.treatmentType", treatmentType )
			control.handleSubmit( submit, onError )()
		} catch ( error ) {
			console.error( error )
			setShowToastJson( {
				visible: true,
				status: "Secondary",
				message: `Creating Patient Failed`,
				timer: 50000,
				uploadPercentage: undefined,
			} )
		}
	}

	React.useEffect( () => {
		const doctorAddress = getValues( "selectedOffice" )
		if ( doctorAddress ) {
			setValue( "office", doctorAddress )
			setValue( "treatmentPlan.treatmentPlanDoctorAddressId", doctorAddress )
		}
		watch( ( formValues ) => {
			localStorage.setItem( "patient", JSON.stringify( formValues ) )
		} )
	}, [ watch ] )

	const uploadFiles = async ( files: { [key: string]: fileUploadingType }, category: string ) => {
		const uploadPromises = Object.keys( files ).map( async ( fileKey ) => {
			const file = files[ fileKey ]
			if ( file.file ) {
				return await uploadMedicalFiles( file.file, category, fileKey )
			}
			return undefined
		} )
		return await Promise.all( uploadPromises )
	}

	const checkSecondaryFilesValidation = () => {
		setValue( "treatmentPlan.upperBracketSlotSize", "0.018" )
		setValue( "treatmentPlan.lowerBracketSlotSize", "0.018" )
		if ( customErrorMarker() ) return null
		uploadDocumentsToServer()
	}

	const getTreatmentType = () => {
		const isLowerSelected =
			Object.entries( teethBonding ).filter(
				( [ key, value ] ) => key.startsWith( "L" ) && value === "selected",
			).length > 0

		const isUpperSelected =
			Object.entries( teethBonding ).filter(
				( [ key, value ] ) => key.startsWith( "U" ) && value === "selected",
			).length > 0

		if ( isLowerSelected && isUpperSelected ) {
			return "both"
		} else if ( isLowerSelected ) {
			return "lower"
		} else if ( isUpperSelected ) {
			return "upper"
		}

		return ""
	}

	const submit = async ( value: PatientSchemaType ) => {
		await createPatient( value )
			.then( () => {
				clearFormData()
				setShowToastJson( {
					visible: true,
					status: "Secondary",
					message: `Uplading Patient ${value.firstName + " " + value.lastName} Details Completed`,
					timer: 3000,
					uploadPercentage: 100,
				} )
				localStorage.removeItem( "patient" )
			} )
			.catch( ( error ) => {
				setShowToastJson( {
					visible: true,
					status: "Secondary",
					message: `Create Patient ${value.firstName + " " + value.lastName} Failed`,
					timer: 10000,
					uploadPercentage: undefined,
				} )
				setCreateOpen( true )
				return error
			} )
	}

	const onError = ( errorData: FieldErrors<PatientSchemaType> ) => {
		console.error( "error :", errorData )
		customErrorMarker( errorData )
	}

	const closeDialog = () => {
		setCloseModalDialog( true )
		// clearFormData()
	}

	const clearFormData = () => {
		reset()
		resetTeethSelection()
		clearState()
		setTypesEditOpen( null )
		setIsUploadFromScanner( null )
		photosLibraryReset()
		clearHashClear()
		setErrorToShow( "" )
		setIsPatientDatainProcess( false )
		queryClient.invalidateQueries()
		window.localStorage.removeItem( "patient" )
		clearFormState( localData, unregister )
	}

	return (
		<React.Fragment>
			<Navbar id="navbarScroll" as={Modal.Header}>
				<Nav variant="tabs" defaultActiveKey="#PatientInfo" className="d-flex mt-2 me-auto">
					<Nav.Link
						href="#PatientInfo"
						active={locationHash.hash == "#PatientInfo"}
						className={navErrors.infoTab}
					>
						Patient Info
					</Nav.Link>
					<Nav.Link
						href="#Scan"
						active={locationHash.hash == "#Scan"}
						className={navErrors.scanTab}
					>
						Scan
					</Nav.Link>
					<Nav.Link
						href="#Instructions"
						active={locationHash.hash == "#Instructions"}
						className={navErrors.instructions}
					>
						Instructions
					</Nav.Link>
					<Nav.Link
						href="#Photos"
						active={locationHash.hash == "#Photos"}
						className={navErrors.photos}
					>
						Photos
					</Nav.Link>
					<Nav.Link href="#X-rays" active={locationHash.hash == "#X-rays"}>
						X-rays
					</Nav.Link>
					<Nav.Link href="#CBCT" active={locationHash.hash == "#CBCT"}>
						CBCT
					</Nav.Link>
				</Nav>
				<span>
					<Button onClick={additionalApiRequirments} className="mx-1">
						Add New Patient
					</Button>
					<Button type="reset" onClick={closeDialog} variant="secondary">
						Cancel
					</Button>
				</span>
			</Navbar>
		</React.Fragment>
	)
}

export const uploadMedicalFiles = async (
	file: File,
	category: string,
	subCategory: string,
	additionalInfo?: { note?: string },
) => {
	const medicalFileService = CelebMedicalFileService.getInstance()
	const fileUploadResponse = await medicalFileService.getUploadUrls( [ file?.name ] )
	const uploadUrl = fileUploadResponse.data.data[ 0 ].uploadUrl
	const response = await medicalFileService.uploadFileToS3( uploadUrl, file )
	if ( response.status === 200 ) {
		const fileName = uploadUrl.split( "?" )[ 0 ].split( "/" )[ 3 ]
		const dataReturn = {
			category: category,
			subCategory: subCategory,
			originalFileName: file?.name,
			uri: fileName,
			note: additionalInfo?.note + "",
		}
		return dataReturn
	}
}

export const updateTaskFilesMutation = () => {
	return useMutation( {
		mutationFn: ( {
			fileData,
			category,
			subCategory,
		}: {
			fileData: File
			category: string
			subCategory: string | ""
		} ) => uploadMedicalFiles( fileData, category, subCategory ),
		onSuccess: () => {
			return queryClient.clear()
		},
	} )
}

type UploadData = {
	category: string
	subCategory: string
	originalFileName: string
	uri: string
	note: string
}

export default PatientNavbar
