import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query"
import axios, { AxiosResponse } from "axios"
import { FieldArrayWithId } from "react-hook-form"
import { z } from "zod"
import { queryClient } from "../providers/QueryProvider"
import { TaskCategory } from "../utils/appConstants"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"

export class CelebServiceService {
	static _instance: CelebServiceService = new CelebServiceService()

	private constructor() {
		CelebServiceService._instance = this
	}

	static getInstance(): CelebServiceService {
		return CelebServiceService._instance
	}

	async listServices( offset?: number, search?: string ) {
		let queryParam = `?offset=${offset ?? 0}`
		if ( search && search.length > 0 ) {
			queryParam += `&search=${search}`
		}
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.SERVICE}${queryParam}`
		return await axios.get<AxiosResponse<ServiceListType[]>>( requestUrl )
	}

	async listMyServices() {
		const requestUrl = `${constants.SERVICE}/me?categories=true`
		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}

	async createService( requestPayload: any ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.SERVICE}`
		return await axios.post( requestUrl, requestPayload )
	}

	async fetchDetails( serviceId: String ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.SERVICE}/${serviceId}`
		return await axios.get( requestUrl )
	}

	async fetchPricingDetails( treatmentId?: String ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.PAYMENTS}${constants.TREATMENT}/${treatmentId}`
		return await axios.get<AxiosResponse<PaymentData>>( requestUrl )
		//used any because not sure how client receive data
	}

	async fetchTrteatmentCount( serviceId?: String, doctorId?: string | null ) {
		let queryParam = ""
		if ( doctorId && doctorId.length >= 1 && doctorId != "undefined" ) {
			queryParam += `&doctorId=${doctorId}`
		}
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.TREATMENT_TASKS}${constants.COUNT}?serviceId=${serviceId}${queryParam}`
		return await axios.get<CountTasksType>( requestUrl )
	}

	async fetchCategoryDetails( serviceId: String ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.TASK_CATEGORIES}/${serviceId}`
		return await axios.get( requestUrl )
	}

	async updateService( serviceId: EditId, requestPayload: any ) {
		if ( serviceId == null ) return
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.SERVICE}/${serviceId}`
		return await axios.put( requestUrl, requestPayload )
	}

	async deleteservice( id: string ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.SERVICE}/${id}`
		return await axios.delete( requestUrl )
	}

	async loadUserServices( doctorId: string ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.USER_SERVICE_PRICINGS}${constants.DOCTOR}/${doctorId}`
		return await axios.get( requestUrl )
	}
}

export const listServiceQueryKeyFn = ( props?: UseListServiceQueryProps ) =>
	[ "list", props ].filter( Boolean )

export const useListServicesQuery = ( props?: UseListServiceQueryProps ) =>
	useSuspenseQuery( {
		queryKey: [ "list", constants.SERVICE, props ],
		queryFn: () =>
			CelebServiceService.getInstance()
				.listServices( props?.pageOffset, props?.query )
				.then( ( res ) => ( { data: res.data, total: res.headers[ "x-total-count" ] } ) )
				.then( ( { data, total } ) => {
					return {
						services: data.data,
						total: total,
					}
				} ),
	} )

export const useListAllServicesQuery = () =>
	useQuery( {
		queryKey: [ "list", constants.SERVICE ],
		queryFn: () =>
			CelebServiceService.getInstance()
				.listMyServices()
				.then( ( res ) => ( { data: res.data.data, total: res.headers[ "x-total-count" ] } ) )
				.then( ( { data, total } ) => {
					return {
						services: data,
						total: total,
					}
				} ),
	} )

export const getServiceTreatmentCount = (
	serviceId?: string,
	status?: string,
	doctorId?: string | null,
) =>
	useSuspenseQuery( {
		queryKey: [ "count", constants.SERVICE, serviceId, status, doctorId ],
		queryFn: () =>
			CelebServiceService.getInstance()
				.fetchTrteatmentCount( serviceId, doctorId )
				.then( ( res ) => ( { data: res.data.count } ) )
				.then( ( data ) => data ),
	} )

export const useServicefetchDetails = ( serviceId: string ) =>
	useSuspenseQuery( {
		queryKey: [ "list", constants.SERVICE, serviceId ],
		queryFn: () =>
			CelebServiceService.getInstance()
				.fetchDetails( serviceId )
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => {
					return {
						services: data,
						tasks: data.tasks,
					}
				} ),
	} )

export const useCategoryfetchDetails = ( serviceId: string ) =>
	useSuspenseQuery( {
		queryKey: [ "listCategoryProvider", constants.TASK_CATEGORIES, serviceId ],
		queryFn: () =>
			CelebServiceService.getInstance()
				.fetchCategoryDetails( serviceId )
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => data ),
	} )

export const useServicefetchDetailsMutate = () =>
	useMutation( {
		mutationFn: ( serviceId: string ) => {
			return CelebServiceService.getInstance()
				.fetchDetails( serviceId )
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => {
					return {
						services: data,
						tasks: data.tasks,
					}
				} )
		},
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listServiceQueryKeyFn(), exact: false } )
		},
	} )

export const useServicePricingsfetchDetails = ( treatmentId?: string ) => {
	return useSuspenseQuery( {
		queryKey: [ "pricing" ],
		queryFn: () =>
			CelebServiceService.getInstance()
				.fetchPricingDetails( treatmentId )
				.then( ( res ) => res?.data )
				.then( ( data ) => data?.data ),
	} )
}

export const useListUserServicePricesQuery = ( doctorId: string ) =>
	useSuspenseQuery( {
		queryKey: [ "list", constants.USER_SERVICE_PRICINGS, constants.DOCTOR, doctorId ],
		queryFn: () =>
			CelebServiceService.getInstance()
				.loadUserServices( doctorId )
				.then( ( res ) => res.data.data )
				.then( ( data ) => data ),
	} )

export const useCreateServiceMutation = () =>
	useMutation( {
		mutationFn: ( payload: any ) => {
			return CelebServiceService.getInstance().createService( payload )
		},
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listServiceQueryKeyFn(), exact: false } )
		},
		onError: ( error ) => {
			console.error( "Error creating service:", error )
		},
	} )

export const useEditServiceMutation = () =>
	useMutation( {
		mutationFn: ( serviceUpdate: { serviceId: EditId; requestPayload: any } ) =>
			CelebServiceService.getInstance().updateService(
				serviceUpdate.serviceId,
				serviceUpdate.requestPayload,
			),
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listServiceQueryKeyFn(), exact: false } )
		},
	} )

export const useDeleteServiceMutation = () =>
	useMutation( {
		mutationFn: ( payload: string ) => CelebServiceService.getInstance().deleteservice( payload ),
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listServiceQueryKeyFn(), exact: false } )
		},
	} )

export const ServiceSchema = z.object( {
	id: z.string(),
	entityId: z.number(),
	name: z.string(),
	description: z.string().nullable(),
	logo: z.string().nullable(),
	email: z.string().email().nullable().optional(),
	contactNo: z.string().nullable().optional(),
	isActive: z.boolean(),
	deleted: z.boolean(),
	deletedBy: z.string().datetime().nullable(),
	deletedAt: z.string().datetime().nullable(),
	createdAt: z.string().datetime(),
	updatedAt: z.string().datetime(),
} )

export const UserServiceSchema = z.object( {
	id: z.string(),
	priceUpper: z.number(),
	priceLower: z.number(),
	priceBoth: z.number(),
	doctorId: z.string(),
	serviceId: z.string(),
	createdAt: z.string().datetime(),
	updatedAt: z.string().datetime(),
	service: z.object( {
		name: z.string(),
		id: z.string(),
		entityId: z.number(),
	} ),
} )

export const ServiceTaskForm = z.object( {
	id: z.string().optional(),
	name: z.string().trim().min( 1, { message: "required task name" } ),
	providerCategoryId: z.string().min( 1, { message: "Provider category is mandatory" } ),
	consumerCategoryId: z.string().min( 1, { message: "Consumer category is mandatory" } ),
	approvalMessage: z.string().optional().nullable(),
	rejectionMessage: z.string().optional().nullable(),
	writeNote: z.boolean(),
	uploadFile: z.boolean(),
	checkmark: z.boolean(),
	approvalTask: z.boolean().optional().default( false ),
	previousTasks: z.array( z.any() ).default( [] ),
	rollBackToTask: z.array( z.any() ).default( [] ),
	hasCustomMessage: z.boolean().default( false ),
	paymentStage: z.boolean().default( false ),
	replaceOrderTask: z.boolean().default( false ),
	daysToComplete: z.number().default( 1 ).nullable().optional(),
	isActive: z.boolean().optional().nullable(),
	isNew: z.boolean().optional().nullable(),
	serviceId: z.string().optional().nullable(),
} )

export const ServiceAddressSchema = z
	.object( {
		id: z.string().optional(),
		name: z.string(),
		addressLine1: z.string(),
		addressLine2: z.string().nullable().optional(),
		city: z.string(),
		state: z.string(),
		country: z.string(),
		pincode: z.string(),
		deleted: z.boolean().optional(),
		deletedAt: z.null().optional(),
		deletedBy: z.null().optional(),
		doctorId: z.string().optional(),
		category: z.string().optional(),
		createdAt: z.string().optional(),
		updatedAt: z.string().optional(),
	} )
	.array()
	.optional()

export const ServiceSchemaForm = z.object( {
	entityId: z.any().optional(),
	name: z.string().trim().min( 1, { message: "Service Name is mandatory" } ),
	logo: z.any().optional().nullish(),
	status: z.boolean().default( true ),
	contactNo: z.string().optional(),
	email: z
		.string( { message: "Please enter a valid Email" } )
		.min( 1, "Email is mandatory" )
		.email( { message: "Please enter a valid email address" } )
		.max( 100, "Email must be less than 100 characters" )
		.refine( ( value ) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test( value ), {
			message: "Invalid email format",
		} )
		.transform( ( value ) => value.toLowerCase() )
		.optional(),
	priceLower: z.number( { message: "Please enter a valid price" } ),
	priceUpper: z.number( { message: "Please enter a valid price" } ),
	priceBoth: z.number( { message: "Please enter a valid price" } ),
	address: ServiceAddressSchema,
	tasks: z
		.object( {
			id: z.string().min( 2, { message: "Task id not assigned" } ),
			name: z.string(),
			providerCategoryId: z.string(),
			consumerCategoryId: z.string(),
			approvalMessage: z.string().optional().nullable(),
			rejectionMessage: z.string().optional().nullable(),
			writeNote: z.boolean(),
			uploadFile: z.boolean(),
			checkmark: z.boolean(),
			previousTasks: z.array( z.any() ).default( [] ),
			rollBackToTask: z.array( z.any() ).default( [] ),
			paymentStage: z.boolean().optional().default( false ),
			approvalTask: z.boolean().optional().default( false ),
			hasCustomMessage: z.boolean().default( false ),
			replaceOrderTask: z.boolean().optional().default( false ),
			daysToComplete: z.number().default( 1 ).nullable().optional(),
			isActive: z.boolean().optional().nullable(),
			isNew: z.boolean().optional().nullable(),
			serviceId: z.string().optional().nullable(),
		} )
		.array()
		.min( 1, {
			message: "Add at least two tasks and ensure the 'Approval Task' option is enabled.",
		} )
		.refine( ( tasks ) => tasks.some( ( task ) => task.replaceOrderTask === true ), {
			message: "Ensure that at least one task has the 'Replace Order' option enabled.",
		} )
		.refine( ( tasks ) => tasks.some( ( task ) => task.paymentStage === true ), {
			message: "Ensure that at least one task has the 'Payment Task' option enabled.",
		} )
		.refine( ( tasks ) => tasks.some( ( task ) => task.approvalTask === true ), {
			message: "Ensure that at least one task has the 'Approval Task' option enabled.",
		} ),
	taskErrors: z.string().optional(),
} )

export const PaymentData = z.object( {
	id: z.string(),
	amount: z.number(),
	status: z.string(),
	createdAt: z.string(),
	taxBreakdown: z.array( z.object( { amount: z.number(), percentage: z.number() } ) ),
	treatmentPaymentTreatment: z.object( { treatmentType: z.string().optional() } ),
} )

export type UseListServiceQueryProps = {
	pageOffset?: number
	query?: string
	limit?: number
}

export type PaymentData = z.infer<typeof PaymentData>
export type ServiceAddress = z.infer<typeof ServiceAddressSchema>
export type ServiceListType = z.infer<typeof ServiceSchema>

export type labelFinderType = FieldArrayWithId<
	{
		tasks: {
			id?: string
			name: string
			providerCategoryId: string
			consumerCategoryId: string
			writeNote: boolean
			uploadFile: boolean
			checkmark: boolean
			previousTasks: any[]
			rollBackToTask: any[]
			replaceOrderTask: boolean
			paymentStage?: boolean | undefined
			daysToComplete?: number | undefined | null
		}[]
		name: string
		status: boolean
		logo?: any
	},
	"tasks",
	"uid"
>[]

export type PreviousRollBackType = {
	previousTasks: {}[]
	rollBackToTask: {}[]
}

export type ReactSelectCreatableCustom = {
	value: string
	label: string
	type: string
	show?: TaskCategory.PROVIDER | TaskCategory.OTHERS | string
}

export type UserServiceSchemaType = z.infer<typeof UserServiceSchema>
type EditId = string | null

export interface CategoriesResponseType {
	id: string
	entityId: number
	name: string
	description: any
	logo: any
	email: any
	contactNo: any
	isActive: boolean
	deleted: boolean
	deletedAt: any
	deletedBy: any
	createdAt: string
	updatedAt: string
	categories: CategoriesType[]
}

export interface CategoriesType {
	id: string
	name: string
	type: string
	deleted: boolean
	deletedBy: any
	deletedOn: any
	serviceId: string
	createdAt: string
	updatedAt: string
}

export type FinancialsData = {
	message: string
	data: {
		id: string
		amount: number
		status: string
		createdAt: string
	}
	status: number
}

export interface Data {
	data: any
	id: string
	amount: number
	status: string
	createdAt: string
}

export interface CountTasksType {
	status: number
	message: string

	count: {
		countForAdminOperator: {
			providerCategoryId: string
			record_count: string
			name: string
			"treatmentPlanTaskProviderCategory.name": string
		}[]
		categoriesCount: {
			categoryId: string
			count: number
		}[]
		actionNeedTasks: number
		completedPauseArchiveCount: {
			status: string
			count: number
		}[]
	}
}
