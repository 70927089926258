import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Button, CloseButton } from "react-bootstrap"
import { PatientDialogState, usePatientDialogStore } from "../../../store/patientDailogStore"
import { photosLibrary } from "../editPatients-v2/defaultFileShowCase"
import { Addbutton } from "./PatientPhotos.tab"

const PatientXrayTab = () => {
	return (
		<>
			<div className="d-flex justify-content-center gap-5 p-5">
				<CardComponent property="patient_xray_skull" />
				<CardComponent property="patient_xray_teeth" />
			</div>
			<footer className="d-flex flex-row-reverse gap-2 position-absolute m-3 end-0 bottom-0 ">
				<Button onClick={( e ) => ( window.location.hash = "#CBCT" )}>
					<FontAwesomeIcon
						icon={faChevronRight}
						style={{ objectFit: "contain", width: "1rem", height: "1rem" }}
						className="m-0 p-0"
					/>
				</Button>
				<Button onClick={( e ) => ( window.location.hash = "#Photos" )}>
					<FontAwesomeIcon
						icon={faChevronLeft}
						style={{ objectFit: "contain", width: "1rem", height: "1rem" }}
						className="m-0 p-0"
					/>
				</Button>
			</footer>
		</>
	)
}

export default PatientXrayTab

const CardComponent = ( { property }: { property: keyof PatientDialogState["xRays"] } ) => {
	const imageRef = React.useRef<HTMLInputElement | null>( null )
	const { xRays, setXRays } = usePatientDialogStore()
	const fileImageSaver = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		e.preventDefault()
		if ( e.currentTarget.files ) {
			setXRays( property, {
				file: e.currentTarget.files[ 0 ],
				additionalInfo: {},
			} )
		}
	}
	const img = xRays[ property ]
	const check = photosLibrary[ property ]
	return (
		<div>
			<span className="d-flex" style={{ height: "15rem", width: "15rem" }}>
				<div
					className="card d-grid align-items-center"
					onClick={() => {
						check.length >= 3 ? imageRef.current?.click() : ""
					}}
					style={{ borderRadius: "5px" }}
				>
					<img
						alt=""
						src={img.file ? URL.createObjectURL( img.file ) : photosLibrary[ property ]}
						className="img-fluid"
						style={{ borderRadius: "5px" }}
					/>
					{img && (
						<CloseButton
							onClick={( e ) => {
								e.preventDefault()
								setXRays( property, {
									additionalInfo: {},
									file: null,
								} )
							}}
							style={{
								position: "absolute",
								top: "0px",
								right: "0px",
								backgroundColor: "#E3E0F0",
								borderRadius: "50%",
							}}
						/>
					)}
					<Addbutton />
				</div>
			</span>
			<input type="file" ref={imageRef} hidden onChange={fileImageSaver} />
		</div>
	)
}
