import { faCreditCard, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import { Button, Table } from "react-bootstrap"
import {
	AddressSchemaType,
	useDeleteAddressMutation,
	useListAddressQuery,
} from "../../../services/CelebDoctorAddress.Service"
import { useProfileDialogStore } from "../../../store/profileDialogStore"
import { userSessionStore } from "../../../store/userSessionStore"
import DeleteDialogModal from "../../utlity/DeleteDialog"
import { infoMapper } from "../../utlity/GenricFunctions"

import { useQueryClient } from "@tanstack/react-query"
import MoonLoader from "react-spinners/MoonLoader"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import { EditTrash } from "../../utlity/EditTrash"
import PreferredPaymentMethodDialog from "../payments/PreferredPaymentMethod"
import ProfileAddressAddDialog from "./ProfileAddressAdd.dialog"

const ProfileAddressesTab = () => {
	return (
		<React.Suspense fallback={<MoonLoader />}>
			<ProfileAddressesTabImp />
		</React.Suspense>
	)
}

const ProfileAddressesTabImp = () => {
	const profileStore = useProfileDialogStore()
	const { data } = useListAddressQuery( profileStore.selectedDoctorId ?? null )
	const addressData: AddressSchemaType[] = data?.Address ?? []
	const { setdeleteDialogOpen } = userSessionStore()
	const { mutate: deleteAddress } = useDeleteAddressMutation()
	const queryClient = useQueryClient()

	const handleDeleteModal = ( dataOffice: AddressSchemaType ) => {
		setdeleteDialogOpen( {
			deletingEntityLabel: dataOffice.name,
			hideShow: true,
			handleDelete: () => deleteAddressMutation( dataOffice.id! ),
		} )
	}

	const handlePrefCreditCardModal = ( x: string | undefined ) => {
		profileStore.setOpenCreditCardPreference( x )
	}

	const deleteAddressMutation = ( id: string ) => {
		deleteAddress( id, {
			onSuccess: () => {
				queryClient.invalidateQueries()
			},
		} )

		setdeleteDialogOpen( {
			hideShow: false,
		} )
	}

	return (
		<React.Fragment>
			<div className="text-end">
				<CheckGenericPermission genericLabel="Add Office">
					<Button onClick={() => profileStore.setCreateOpen( true )}>Add Clinic</Button>
				</CheckGenericPermission>
			</div>
			<Table striped bordered hover className="my-1">
				<thead>
					<tr>
						<th>Office Name</th>
						<th>Address</th>
						<th>Payment Method</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<CheckGenericPermission genericLabel="Office List">
						{addressData.map( ( office: AddressSchemaType ) => {
							return (
								<tr>
									<td>{office.name}</td>
									<td>
										{infoMapper( office, [
											"id",
											"createdAt",
											"updatedAt",
											"doctorId",
											"deleted",
											"preferredPaymentMethods",
										] )}
									</td>
									<td className="text-start">
										<div style={{ margin: 0, padding: 0, textWrap: "wrap", maxWidth: "13.5rem" }}>
											<p className="mb-0">
												{office.preferredPaymentMethods?.[ 0 ]?.preferredCard?.brand ?? (
													<p style={{ color: "GrayText", fontSize: "15px" }}>
														No Preferred Payment Method Selected
													</p>
												)}
											</p>
											<p
												hidden={!office.preferredPaymentMethods?.[ 0 ]?.preferredCard?.cardNumber}
												className="mb-0"
												style={{ color: "grey", fontSize: "15px" }}
											>
												{office.preferredPaymentMethods?.[ 0 ]?.preferredCard?.cardNumber}
											</p>
										</div>
									</td>
									<td className="text-end">
										<CheckGenericPermission genericLabel="Office Edit">
											<EditTrash
												editIcon={{ icon: faPencil, color: "#8faadc" }}
												trashIcon={{ icon: faTrash, color: "#d9d9d9" }}
												deleteCallBack={() => handleDeleteModal( office )}
												editCallback={() => profileStore.setEditOpen( office )}
												customIcon={{ icon: faCreditCard, color: "#8faadc" }}
												customFunctionCallBack={() => handlePrefCreditCardModal( office?.id )}
											/>
										</CheckGenericPermission>
									</td>
								</tr>
							)
						} )}
					</CheckGenericPermission>
				</tbody>
			</Table>
			<DeleteDialogModal />
			<ProfileAddressAddDialog />
			{profileStore.openCreditCardPreference && <PreferredPaymentMethodDialog />}
		</React.Fragment>
	)
}

export default ProfileAddressesTab
