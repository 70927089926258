import React from "react"
import { Button, Modal, Nav, Navbar } from "react-bootstrap"
import { FieldErrors, useFormContext } from "react-hook-form"
import { useLocation } from "react-router"
import useCustomErrorMarkerTry from "../../../hooks/patient/customErrorMarker"
import { queryClient } from "../../../providers/QueryProvider"
import {
	PatientSchemaType,
	ReplaceOrderSchemaType,
	usePatientReplaceMutation,
	usePatientUpdateReplaceMutation,
} from "../../../services/CelebPatient.Service"
import { getPatientByIdQuery } from "../../../services/CelebTreatment.Service"
import { getPatientTreatmentFilesQuery } from "../../../services/CelebTreatmentFiles.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { useTeethStore } from "../../../store/toothSelectionStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { TreatmentFileCategory } from "../../../utils/appConstants"
import { clearHash } from "../../utlity/GenricFunctions"
import { photosLibraryReset } from "../editPatients-v2/defaultFileShowCase"
import { uploadMedicalFiles } from "../patient-v2/PatientNavbar"
import "./Patient.css"
import { fileResult } from "./PatientPhotos.tab"
const PatientNavbar = () => {
	const { setValue, getValues, control } = useFormContext<ReplaceOrderSchemaType>()
	const { customErrorMarker } = useCustomErrorMarkerTry()
	const locationHash = useLocation()
	const clearHashClear = clearHash()
	const {
		replaceOrderPhoto,
		cbctDocumentJson,
		xRays,
		scan,
		navErrors,
		replaceOpen,
		clearState,
		setModalOpen,
		setReplaceOpen,
	} = usePatientDialogStore()
	const { mutateAsync: replaceOrder } = usePatientReplaceMutation()
	const { mutateAsync: updateOrder } = usePatientUpdateReplaceMutation()
	const { teethReplacement, resetTeethSelection } = useTeethStore()
	const { setShowToastJson } = userSessionStore()
	const { data: defaultTreatmentFiles } = getPatientTreatmentFilesQuery( replaceOpen.id )
	const { data: defaultValuesEdit } = getPatientByIdQuery( replaceOpen.id )
	const additionalApiRequirments = async () => {
		setShowToastJson( {
			visible: true,
			status: "Secondary",
			message: `Uploading Treatment Files..`,
			timer: 30000,
			uploadPercentage: 0,
		} )
		const doctorAddress = getValues( "selectedOffice" )
		setValue( "office", doctorAddress )
		setValue( "replaceOrder.braces", teethReplacement )
		control.handleSubmit( checkSecondaryFilesValidation, onError )()
	}
	const uploadDocumentsToServer = async () => {
		try {
			setModalOpen( false )
			setShowToastJson( {
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 30000,
				uploadPercentage: 0,
			} )
			const uploadCbctData = await uploadFiles( cbctDocumentJson, TreatmentFileCategory.PATIENT_CBCT )
			setShowToastJson( {
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 30000,
				uploadPercentage: 20,
			} )
			const uploadPhotoData = await uploadFiles(
				replaceOrderPhoto,
				TreatmentFileCategory.REPLACE_ORDER_PHOTO,
			)

			setShowToastJson( {
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 30000,
				uploadPercentage: 30,
			} )
			const uploadXRaysData = await uploadFiles( xRays, TreatmentFileCategory.PATIENT_XRAY )
			const uploadScanData = await uploadFiles( scan, TreatmentFileCategory.PATIENT_SCAN )
			setShowToastJson( {
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 30000,
				uploadPercentage: 40,
			} )
			setShowToastJson( {
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 30000,
				uploadPercentage: 50,
			} )
			const treatmentFiles = [
				...uploadPhotoData,
				...uploadCbctData,
				...uploadXRaysData,
				...uploadScanData,
			].filter( ( file ): file is UploadData =>
				file !== undefined && file.uri.length >= 1 ? true : false,
			)
			setShowToastJson( {
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 30000,
				uploadPercentage: 80,
			} )

			setValue( "replaceOrder.treatmentFiles", treatmentFiles )
			control.handleSubmit( submit, onError )()
		} catch ( error ) {
			console.error( error )
			setModalOpen( true )
		}
	}

	const uploadFiles = async (
		files: {
			[key: string]: {
				file: File | null
				additionalInfo: {
					note?: string
				}
				deleted?: boolean | null
			}
		},
		category: string,
	) => {
		const uploadPromises = Object.keys( files ).map( async ( fileKey ) => {
			const file = files[ fileKey ]
			const tempObj = {
				category: "",
				subCategory: "",
				originalFileName: "",
				uri: "",
				note: file.additionalInfo.note,
				deleted: file.deleted || false,
			}
			const checkDuplicateFile = file.file?.name.split( "$%alreadyExist" ).length == 2
			if ( file.file && checkDuplicateFile == false ) {
				return await uploadMedicalFiles( file.file, category, fileKey, file.additionalInfo )
			} else {
				defaultTreatmentFiles.forEach( ( fileData: fileResult ) => {
					if ( fileData.fileSubCategory == fileKey ) {
						tempObj.category = fileData.fileCategory
						tempObj.subCategory = fileData.fileSubCategory
						tempObj.originalFileName = fileData.originalFileName
						tempObj.uri = fileData.fileUri
						tempObj.deleted = file.deleted ?? false
					}
				} )
				return tempObj
			}
		} )
		return await Promise.all( uploadPromises )
	}

	const checkSecondaryFilesValidation = () => {
		if ( customErrorMarker() ) return null
		uploadDocumentsToServer()
	}

	const submit = async ( value: ReplaceOrderSchemaType ) => {
		if ( defaultValuesEdit?.isReplacementOrder && replaceOpen.isReplacementNew == false ) {
			await updateOrder( { id: replaceOpen.id, payload: value } )
		} else {
			await replaceOrder( { id: replaceOpen.id, payload: value } )
		}
		setShowToastJson( {
			visible: true,
			status: "Secondary",
			message: `Uploading Treatment Files Completed..`,
			timer: 30000,
			uploadPercentage: 100,
		} )
		clearFormData()
	}

	const onError = ( errorData: FieldErrors<PatientSchemaType> ) => {
		console.error( errorData )
		customErrorMarker( errorData )
	}

	const clearFormData = () => {
		clearState()
		setReplaceOpen( { id: "" } )
		resetTeethSelection()
		photosLibraryReset()
		clearHashClear()
		queryClient.clear()
	}

	return (
		<React.Fragment>
			<Navbar as={Modal.Header} id="navbarScroll">
				<Nav variant="tabs" defaultActiveKey="#PatientInfo" className="d-flex mt-2 me-auto">
					<Nav.Link
						href="#PatientInfo"
						active={locationHash.hash == "#PatientInfo"}
						className={navErrors.infoTab}
					>
						Patient Replace Info
					</Nav.Link>
					<Nav.Link
						href="#Scan"
						active={locationHash.hash == "#Scan"}
						className={navErrors.scanTab}
					>
						Scan
					</Nav.Link>
					<Nav.Link
						href="#Replace"
						active={locationHash.hash == "#Replace"}
						className={navErrors.replace}
					>
						{defaultValuesEdit?.isReplacementOrder ? "Edit Replace Order" : "Replace Order"}
					</Nav.Link>
					<Nav.Link
						href="#Photos"
						active={locationHash.hash == "#Photos"}
						className={navErrors.photos}
					>
						Photos
					</Nav.Link>
					<Nav.Link href="#X-rays" active={locationHash.hash == "#X-rays"}>
						X-rays
					</Nav.Link>
					<Nav.Link href="#CBCT" active={locationHash.hash == "#CBCT"}>
						CBCT
					</Nav.Link>
				</Nav>
				<span>
					<Button onClick={additionalApiRequirments} className="mx-2">
						{!replaceOpen.isReplacementNew ? "Update Replacement" : "Request Replacement"}
					</Button>
					<Button variant="secondary" onClick={clearFormData}>
						Cancel
					</Button>
				</span>
			</Navbar>
		</React.Fragment>
	)
}

export type UploadData = {
	category: string
	subCategory: string
	originalFileName: string
	uri: string
	deleted?: boolean
	note: string
}

export default PatientNavbar
