import { CSSProperties, useEffect, useState } from "react"
import { Form } from "react-bootstrap"

const SearchInput = ( {
	callback,
	style,
	placeholder,
}: {
	placeholder?: string
	callback: ( search: string ) => void
	style?: CSSProperties
} ) => {
	const [ search, setSearch ] = useState<string>( "" )

	useEffect( () => {
		const newTimer = setTimeout( () => {
			callback( search )
		}, 500 )
		return () => clearTimeout( newTimer )
	}, [ search ] )

	const handleKeyDown = ( e: React.KeyboardEvent<HTMLInputElement> ) => {
		if ( e.key === "Enter" ) {
			e.preventDefault()
		}
	}

	return (
		<div style={{ position: "relative", ...style }}>
			<Form.Control
				type="search"
				placeholder={placeholder ? placeholder : "Search"}
				onChange={( e ) => setSearch( e.currentTarget.value )}
				onKeyDown={handleKeyDown}
			/>
		</div>
	)
}

export default SearchInput
