import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Alert, Modal } from "react-bootstrap"
import { FormProvider, useForm } from "react-hook-form"
import { Params, useLocation, useParams } from "react-router"
import { queryClient } from "../../../providers/QueryProvider"
import { PatientSchema, PatientSchemaType } from "../../../services/CelebPatient.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { useTeethStore } from "../../../store/toothSelectionStore"
import CloseDialog from "../../utlity/CloseDialog"
import { clearFormState, clearHash } from "../../utlity/GenricFunctions"
import { photosLibraryReset } from "./defaultFileShowCase"
import PatientCbctTab from "./PatientCBCT.tab"
import PatientInfoTab from "./PatientInfo.tab"
import PatientNavbar from "./PatientNavbar"
import PatientPhotosTab from "./PatientPhotos.tab"
import PatientScanTab from "./PatientScan.tab"
import PatientTreatmentInstructionTab from "./PatientTreatmentInstructions.tab"
import PatientXrayTab from "./PatientXray.tab"

const PatientAddDialog = () => {
	const PatientDialog = usePatientDialogStore()
	const teethStore = useTeethStore()
	const clearHashClear = clearHash()
	const patientData = window.localStorage.getItem( "patient" )
	const paramsData: Readonly<Params<string>> = useParams()
	const methods = useForm<PatientSchemaType>( {
		resolver: zodResolver( PatientSchema ),
		mode: "onChange",
		defaultValues: patientData && patientData.length >= 1 ? JSON.parse( patientData ) : {},
	} )
	const form = JSON.parse( localStorage.getItem( "patient" ) + "" )

	methods.setValue( "treatmentPlan.serviceId", paramsData.id! )
	React.useEffect( () => {
		if ( patientData && patientData.length >= 1 ) {
			const parsed = JSON.parse( patientData )
			teethStore.toggleToothBondingJsonFile( parsed.treatmentPlan?.teethBonding )
			teethStore.toggleToothExtractionJsonFile( parsed.treatmentPlan?.teethExtraction )
		}
	}, [] )

	const handleClose = ( action: string ) => {
		if ( action === "minimize" ) {
			return handleMinimize()
		}
		closeForm()
	}

	const closeForm = () => {
		teethStore.resetTeethSelection()
		photosLibraryReset()
		clearHashClear()
		clearFormState( form, methods.unregister )
		PatientDialog.clearState()
		queryClient.invalidateQueries()
	}

	const handleMinimize = () => {
		methods.watch( async ( formValues ) => {
			localStorage.setItem( "patient", JSON.stringify( formValues ) )
		} )
		PatientDialog.setCreateOpen( false )
		PatientDialog.setCloseModalDialog( false )
		PatientDialog.setIsPatientDatainProcess( false )
	}

	return (
		<React.Fragment>
			<FormProvider {...methods}>
				<Modal
					show={PatientDialog.createOpen}
					size="xl"
					backdrop="static"
					onEscapeKeyDown={() => {
						PatientDialog.setCloseModalDialog( true )
					}}
				>
					<PatientNavbar />
					<Alert
						className="m-2"
						variant="warning"
						hidden={!PatientDialog.errorToShow.createPatient}
					>
						{PatientDialog.errorToShow.createPatient}
					</Alert>
					<PatientAddImpl />
				</Modal>
			</FormProvider>
			<CloseDialog visible={PatientDialog.closeModalDialog} callBackFunction={handleClose} />
		</React.Fragment>
	)
}

const PatientAddImpl = () => {
	return (
		<React.Fragment>
			<main className="p-3">
				<ActiveTabExporter />
			</main>
		</React.Fragment>
	)
}

const ActiveTabExporter = () => {
	const location = useLocation()

	switch ( location.hash ) {
		case "#PatientInfo":
			return <PatientInfoTab />
		case "#Scan":
			return <PatientScanTab />
		case "#Instructions":
			return <PatientTreatmentInstructionTab />
		case "#Photos":
			return <PatientPhotosTab />
		case "#X-rays":
			return <PatientXrayTab />
		case "#CBCT":
			return <PatientCbctTab />
		default:
			return <PatientInfoTab />
	}
}

export default PatientAddDialog
