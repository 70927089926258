import { create } from "zustand"
import { SearchTreatmentType } from "../services/CelebTreatment.Service"
import { TreatmentSchemaType } from "../services/CelebTreatmentTask.Service"
import { PatientDialogState } from "./patientDailogStore"

interface SideBarState {
	patientId: string
	setpatientId: ( next: string ) => void
	sideBarPatientData: {
		activeTab: string
		PatientData: PatientDataResponseType
	}
	setSideBarPatientData: ( next: { activeTab: string; PatientData: PatientDataResponseType } ) => void
	xRays: {
		patient_xray_teeth: File | null
		patient_xray_skull: File | null
		[key: string]: File | null
	}
	setXRays: ( property: keyof PatientDialogState["xRays"], next: File | null ) => void

	individualPhoto: {
		patient_photo_face_side: File | null
		patient_photo_face_front: File | null
		patient_photo_face_front_teeth: File | null
		patient_photo_jaw_upper: File | null
		patient_photo_jaw_lower: File | null
		patient_photo_jaw_right: File | null
		patient_photo_jaw_front: File | null
		patient_photo_jaw_left: File | null
		[key: string]: File | null
	}

	downloadingFileIndexes: number[]
	addDownloadingFile: ( index: number ) => void

	removeDownloadingFile: ( index: number ) => void

	downloadingTimelineFileIndexes: number[]
	addTimelineDownloadingFile: ( index: number ) => void
	removeTimelineDownloadingFile: ( index: number ) => void

	setIndividualPhoto: (
		property: keyof PatientDialogState["individualPhoto"],
		next: File | null,
	) => void

	timelineData: TreatmentSchemaType | SearchTreatmentType | null
	setTimelineData: ( next: TreatmentSchemaType | SearchTreatmentType ) => void
	reset: () => void
}

export const useSidebarStore = create<SideBarState>( ( set ) => ( {
	patientId: "",
	setpatientId: ( next: string ) => set( { patientId: next } ),
	sideBarPatientData: {
		activeTab: "PatientInfo",
		PatientData: {} as PatientDataResponseType,
	},
	xRays: {
		patient_xray_skull: null,
		patient_xray_teeth: null,
	},
	individualPhoto: {
		patient_photo_face_side: null,
		patient_photo_face_front: null,
		patient_photo_face_front_teeth: null,
		patient_photo_jaw_upper: null,
		patient_photo_jaw_lower: null,
		patient_photo_jaw_right: null,
		patient_photo_jaw_front: null,
		patient_photo_jaw_left: null,
	},
	setSideBarPatientData: ( next ) =>
		set( ( state ) => {
			return {
				sideBarPatientData: {
					...state.sideBarPatientData,
					...next,
				},
			}
		} ),
	setXRays: ( property: keyof PatientDialogState["xRays"], next: File | null ) =>
		set( ( state ) => ( {
			xRays: {
				...state.xRays,
				[ property ]: next,
			},
		} ) ),
	setIndividualPhoto: ( property: keyof PatientDialogState["individualPhoto"], next: File | null ) =>
		set( ( state ) => ( {
			individualPhoto: {
				...state.individualPhoto,
				[ property ]: next,
			},
		} ) ),

	downloadingFileIndexes: [],
	addDownloadingFile: ( index ) =>
		set( ( state ) => ( {
			downloadingFileIndexes: [ ...state.downloadingFileIndexes, index ],
		} ) ),
	removeDownloadingFile: ( index ) =>
		set( ( state ) => ( {
			downloadingFileIndexes: state.downloadingFileIndexes.filter( ( id ) => id !== index ),
		} ) ),

	downloadingTimelineFileIndexes: [],
	addTimelineDownloadingFile: ( index ) =>
		set( ( state ) => ( {
			downloadingTimelineFileIndexes: [ ...state.downloadingTimelineFileIndexes, index ],
		} ) ),
	removeTimelineDownloadingFile: ( index ) =>
		set( ( state ) => ( {
			downloadingTimelineFileIndexes: state.downloadingTimelineFileIndexes.filter(
				( id ) => id !== index,
			),
		} ) ),

	timelineData: null,
	setTimelineData: ( next: TreatmentSchemaType | SearchTreatmentType ) => set( { timelineData: next } ),
	reset: () => "",
} ) )

export interface PatientDataResponseType {
	id: string
	treatmentTitle: any
	instruction: string
	treatmentStart: string
	treatmentType: any
	upperBracketSlotSize: string
	lowerBracketSlotSize: string
	status: string
	isReplacementOrder: boolean
	patientId: string
	doctorsWithAccess: string[]
	doctorId: string
	serviceId: string
	treatmentPlanDoctorAddressId: string
	createdAt: string
	updatedAt: string
	doctor: {
		firstName: string
		lastName: string
		id: string
	}
	treatmentPlanDoctorAddress: {
		name: string
		addressLine1: string
		addressLine2: string
		city: string
		state: string
		pincode: string
		id: string
	}
	treatmentPlanService?: {
		id?: string
		name?: string
		priceLower?: number
		priceUpper?: number
		priceBoth?: number
	}
	patient: {
		firstName: string
		lastName: string
		entityId: number
		id: string
		dob: string | Date
		gender: string
		ethenicity: string
		email: string
		phone: string
	}
	replacedOrders: {
		"replacement-number"?: number
		id?: string
		treatmentId?: string
	}
}
