import { faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Accordion, Alert, Button, Form, Modal, Nav, Navbar, Table } from "react-bootstrap"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { useLocation } from "react-router"
import Select from "react-select"
import "../../../index.css"
import { useListPermissionsQuery } from "../../../services/CelebGenericPermission.Service"
import {
	RoleCreationSchema,
	RoleCreationSchemaType,
	SpecialRoleEnumType,
	TaskPermissions,
	useGetRoleMutaion,
	useListRolesQuery,
	useSaveRoleMutaion,
} from "../../../services/CelebRole.Service"
import {
	useListServicesQuery,
	useServicefetchDetails,
} from "../../../services/CelebService.Service"
import {
	GenericPermissionType,
	PermissionType,
	TaskPermissionType,
	useAccountsDialogStore,
} from "../../../store/accountsDialogStore"
import SearchInput from "../../form/SearchInput"
import { clearHash, sortForReactSelect, SortReactSelectType } from "../../utlity/GenricFunctions"
import { BoundControlTypes } from "./AccountFormFields"

const AccountTypesAddDialog = () => {
	const methods = useForm<RoleCreationSchemaType>({
		resolver: zodResolver(RoleCreationSchema),
		mode: "all",
	})
	const { typesCreateOpen, resetState } = useAccountsDialogStore()
	const clearHashClear = clearHash()

	return (
		<React.Suspense>
			<FormProvider {...methods}>
				<Modal
					show={typesCreateOpen}
					size="xl"
					backdrop="static"
					onHide={() => {
						resetState()
						methods.reset()
						clearHashClear()
					}}
				>
					<Navbar style={{ backgroundColor: "#d9d9d9" }} as={Modal.Header} closeButton>
						<Nav>
							<Nav.Link className="nav-link-opposite mt-1" active={true}>
								Accounts
							</Nav.Link>
						</Nav>
					</Navbar>
					<AccountTabImpl />
				</Modal>
			</FormProvider>
		</React.Suspense>
	)
}

const AccountTabImpl = React.memo(() => {
	const method = useFormContext<RoleCreationSchemaType>()
	const {
		data: { data: roles },
	} = useListRolesQuery()
	const [rolesOption, setRolesOption] = React.useState<SortReactSelectType>([])
	const { mutateAsync: getRoleInformation } = useGetRoleMutaion()
	const { mutateAsync: saveRole } = useSaveRoleMutaion()
	const [reponseError, setReponseError] = React.useState<string | null>(null)
	const { setPermissions, setGenericPermissions, setServiceOpted, resetState } =
		useAccountsDialogStore()

	React.useEffect(() => {
		setRolesOption(sortForReactSelect(roles, "id", "name"))
		method.setValue("specialRole", "none")
	}, [])

	const setRoleInformatopm = async (value: any) => {
		const data = await getRoleInformation(value.value)
		const services: string[] = []
		const mappedPermissions = data.taskPermissions.map((item: TaskPermissionType) => {
			services.includes(item.roleTaskPermissionTask.serviceId) ||
				services.push(item.roleTaskPermissionTask.serviceId)
			return {
				taskId: item.taskId,
				permission: item.permission,
				serviceId: item.roleTaskPermissionTask.serviceId,
			}
		})
		const genericPermissionsMapped = data.genericPermissions.map(
			(item: { genericPermissionId: string; permission: string }) => ({
				permissionId: item.genericPermissionId,
				permission: item.permission,
			}),
		)
		setServiceOpted(services)
		setPermissions(mappedPermissions)
		setGenericPermissions(genericPermissionsMapped)
	}
	const onSave = async (value: RoleCreationSchemaType) => {
		const response = await saveRole(value)
		if (response.status == 400) {
			return setReponseError(response.message)
		}
		method.reset()
		resetState()
	}

	return (
		<React.Fragment>
			<Modal.Body style={{ overflowY: "scroll", scrollbarWidth: "none" }}>
				<Alert variant="warning" show={reponseError != null}>
					{reponseError}{" "}
				</Alert>
				<Form onSubmit={method.handleSubmit(onSave)}>
					<section className="d-flex w-100 justify-content-between mb-3">
						<span className="w-25">
							<BoundControlTypes
								name="name"
								label="Account Role Name"
								placeholder="Enter Account Name"
							/>
						</span>
						<span className="w-25">
							<Form.Label>Start From</Form.Label>
							<div style={{ zIndex: 101, position: "relative" }}>
								<Select options={rolesOption} onChange={setRoleInformatopm}></Select>
							</div>
						</span>
						<span className="auto">
							<Form.Label>Account Type</Form.Label>
							<Form.Select
								onChange={(e) =>
									method.setValue("specialRole", e.currentTarget.value as SpecialRoleEnumType)
								}
							>
								<option value="doctor">Doctor</option>
								<option value="none" selected>
									Operator
								</option>
								<option value="sales">Sales</option>
							</Form.Select>
						</span>
						<span>
							<Form.Label>Account Status</Form.Label>
							<Form.Check defaultChecked={true} label={"Active"} {...method.register("status")} />
						</span>
						<span>
							<Button className="mt-4" type="submit">
								Save Account Type
							</Button>
						</span>
					</section>

					<ExpandableNavbarTabs />
				</Form>
			</Modal.Body>
		</React.Fragment>
	)
})

export const ExpandableNavbarTabs = React.memo(() => {
	const { data: servicesData } = useListServicesQuery()
	const [service, setService] = React.useState<{ value: string; label: string }[]>([])
	const { serviceOpted, permissions, setPermissions } = useAccountsDialogStore()
	const [servicesList, setServiceList] = React.useState<{ value: string; label: string }[]>([])

	React.useEffect(() => {
		const options = sortForReactSelect(servicesData.services, "id", "name")
		setServiceList(options)
		window.location.href = "#genericpermissions"
	}, [])

	React.useEffect(() => {
		const sortedService = servicesData?.services.filter((x) => serviceOpted.includes(x.id))
		const sortedService2 = servicesData?.services.filter((x) => !serviceOpted.includes(x.id))
		const options = sortForReactSelect(sortedService2, "id", "name")
		const options2 = sortForReactSelect(sortedService, "id", "name")
		setServiceList(options)
		setService(options2)
	}, [serviceOpted])

	const addServiceToList = (e: any) => {
		const check = service.find((x) => x.value == e.value)
		const updateService = servicesList.filter((x) => x.value != e.value)
		if (check?.label) return null
		setService((prev) => [...prev, { value: e.value, label: e.label + "" }])
		setServiceList(updateService)
	}

	const removeFromService = (
		e: React.MouseEvent<SVGSVGElement, MouseEvent>,
		x: { value: string; label: string },
	) => {
		e.preventDefault()
		setService((prev) => prev.filter((item) => item.value !== x.value))
		const newEra = permissions.filter((r) => r.serviceId != x.value)
		setPermissions(newEra)

		setServiceList((prev) => [...prev, x])
		window.location.href = "#genericpermissions"
	}

	return (
		<section>
			<Navbar as={Modal.Header}>
				<Nav
					variant="tabs"
					defaultActiveKey="#genericpermissions"
					className="align-items-end mt-1 w-100"
				>
					<Nav.Link href="#genericpermissions" className="nav-link">
						Generic Permissisons
					</Nav.Link>
					{service.map((x) => {
						return (
							<Nav.Link className="nav-link" key={x.value} href={"#" + x.value}>
								{x?.label}

								<FontAwesomeIcon
									icon={faClose}
									onClick={(e) => removeFromService(e, x)}
								></FontAwesomeIcon>
							</Nav.Link>
						)
					})}
					<span style={{ zIndex: "100" }}>
						<Select
							options={servicesList}
							onChange={addServiceToList}
							value={null}
							className="mx-1"
						></Select>
					</span>
				</Nav>
			</Navbar>
			<PermissionsShowCase />
		</section>
	)
})

export const PermissionsShowCase = React.memo(() => {
	const location = useLocation().hash.substring(1)
	const [selectedGroupId] = React.useState<string>("")
	const { data: permissionData } = useListPermissionsQuery()
	const { data: serviceData } = useServicefetchDetails(location)
	const genericPermissionsList =
		location == "genericpermissions" ? permissionData : serviceData.tasks
	const method = useFormContext<RoleCreationSchemaType>()
	const {
		permissions,
		addPermission,
		removePermission,
		genericPermissions,
		addGenericPermissions,
		removeGenericPermissions,
	} = useAccountsDialogStore()

	const [query, setQuery] = React.useState("")
	const filteredPermissions = React.useMemo(() => {
		if (location == "genericpermissions") {
			return genericPermissionsList?.filter(
				(task: TaskPermissions) =>
					task.name.toLowerCase().includes(query.toLowerCase()) &&
					task.celebgenericpermissiongroupid.includes(selectedGroupId),
			)
		} else {
			return genericPermissionsList?.filter((task: TaskPermissions) =>
				task.name.toLowerCase().includes(query.toLowerCase()),
			)
		}
	}, [genericPermissionsList, query, selectedGroupId, location])

	React.useEffect(() => savePermissions(), [genericPermissions, permissions])

	const handlePermissionChange = (
		taskId: string,
		value: number,
		calledBy: "generic" | "service",
	) => {
		if (location == "genericpermissions" && calledBy == "generic") {
			const genericPermission = { permissionId: taskId, permission: value }
			removeGenericPermissions(taskId)
			addGenericPermissions(genericPermission)
		} else {
			const newPermission = { taskId, permission: value }
			removePermission(taskId)
			addPermission(newPermission)
		}
	}

	const savePermissions = () => {
		method.setValue("genericPermissions", genericPermissions)
		method.setValue("permissions", permissions)
	}

	const getPermissionValue = (
		taskId: string,
		calledBy: "generic" | "service",
		taskPermissions: string,
	): number | undefined => {
		if (location === "genericpermissions") {
			const permission = genericPermissions.find(
				(p: GenericPermissionType) => p.permissionId === taskId,
			)
			if (permission?.permission == undefined && calledBy == "generic") {
				handlePermissionChange(taskId, Number(taskPermissions), "generic")
			}
			return permission?.permission
		} else {
			const permission = permissions.find((p: PermissionType) => p.taskId === taskId)
			if (permission?.permission == undefined && calledBy == "service") {
				handlePermissionChange(taskId, 0, "service")
			}
			return permission?.permission
		}
	}

	const result: { [key: string]: any } = Object.groupBy(
		permissionData,
		(celebgenericpermissiongroupid: any) =>
			celebgenericpermissiongroupid.celebgenericpermissiongroupid,
	)

	return (
		<React.Fragment>
			<div>
				<Accordion
					hidden={location !== "genericpermissions"}
					style={{ maxHeight: "60vh", overflowY: "scroll", scrollbarWidth: "thin" }}
				>
					{Object.keys(result).map((x, itemIndex) => (
						<Accordion.Item eventKey={itemIndex + ""} key={itemIndex}>
							<Accordion.Header as={"h2"}>{x}</Accordion.Header>
							<Accordion.Body>
								<Table striped bordered>
									<thead>
										<tr>
											<th scope="col">Task</th>
											<th scope="col">No View</th>
											<th scope="col">View Only</th>
											<th scope="col">Edit</th>
										</tr>
									</thead>
									<tbody>
										{result[x].map((task: any, index: number) => {
											const currentPermission = getPermissionValue(
												task.id,
												"generic",
												task.permission,
											)
											return (
												<tr key={task.id}>
													<td className="font-weight-bold">{task.name}</td>
													<td>
														<Form.Check
															type="radio"
															name={task.name}
															id={`task-${index}-no-view`}
															checked={currentPermission === 0}
															onChange={() => handlePermissionChange(task.id, 0, "generic")}
														/>
													</td>
													<td>
														<Form.Check
															type="radio"
															label=""
															name={task.name}
															id={`task-${index}-view-only`}
															checked={currentPermission === 1}
															onChange={() => handlePermissionChange(task.id, 1, "generic")}
														/>
													</td>
													<td>
														<Form.Check
															type="radio"
															label=""
															name={task.name}
															id={`task-${index}-edit`}
															checked={currentPermission === 2}
															onChange={() => handlePermissionChange(task.id, 2, "generic")}
														/>
													</td>
												</tr>
											)
										})}
									</tbody>
								</Table>
							</Accordion.Body>
						</Accordion.Item>
					))}
				</Accordion>

				{location != "genericpermissions" && (
					<Table striped bordered className="table">
						<thead>
							<tr>
								<th scope="col" style={{ width: "17rem" }}>
									<span
										className="d-flex flex-column justify-content-center gap-2 align-items-center"
										style={{ width: "100%" }}
									>
										Task{" "}
										<SearchInput callback={(e) => setQuery(e)} placeholder="Search Permissions" />
									</span>
								</th>
								<th scope="col">No View</th>
								<th scope="col">View Only</th>
								<th scope="col">Edit</th>
							</tr>
						</thead>
						<tbody>
							{filteredPermissions?.map((task: any, index: number) => {
								const currentPermission = getPermissionValue(task.id, "service", task.permission)
								return (
									<tr key={index}>
										<td className="font-weight-bold">{task.name}</td>
										<td>
											<Form.Check
												type="radio"
												label=""
												name={task.name}
												id={`task-${index}-no-view`}
												checked={currentPermission === 0}
												onChange={() => handlePermissionChange(task.id, 0, "service")}
											/>
										</td>
										<td>
											<Form.Check
												type="radio"
												label=""
												name={task.name}
												id={`task-${index}-view-only`}
												checked={currentPermission === 1}
												onChange={() => handlePermissionChange(task.id, 1, "service")}
											/>
										</td>
										<td>
											<Form.Check
												type="radio"
												label=""
												name={task.name}
												id={`task-${index}-edit`}
												checked={currentPermission === 2}
												onChange={() => handlePermissionChange(task.id, 2, "service")}
											/>
										</td>
									</tr>
								)
							})}
						</tbody>
					</Table>
				)}
			</div>
		</React.Fragment>
	)
})

export default AccountTypesAddDialog

export const SpecialRoleOptions: SortReactSelectType = [
	{ label: "doctor", value: "doctor" },
	{ label: "operator", value: "none" },
]
