import { useSuspenseQuery } from "@tanstack/react-query"
import axios from "axios"
import { TreatmentInfoData } from "../components/receipt/receiptCreater"
import { constants } from "./CelebApiConstants"
import { AddressSchemaType } from "./CelebDoctorAddress.Service"
import { PatientSchema } from "./CelebPatient.Service"

export class CelebInvoiceService {
	static _instance: CelebInvoiceService = new CelebInvoiceService()

	private constructor() {
		CelebInvoiceService._instance = this
	}

	static getInstance(): CelebInvoiceService {
		return CelebInvoiceService._instance
	}

	async invoiceDetails( treatmentId: string ) {
		const requestUrl = `${constants.BASE_URL}${constants.INVOICE}${constants.TREATMENT}/invoices/${treatmentId}`
		return await axios.get( requestUrl )
	}

	async invoiceList(
		pageOffset?: number,
		query?: { search?: string; startFrom?: string; endTo: string } | undefined,
		limit?: number,
		userId?: string,
	) {
		let queryParam = `?offset=${pageOffset ?? 0}`
		if ( query && query?.search !== undefined ) {
			queryParam += `&search=${query.search ? query.search : ""}`
		}
		if ( query && query?.startFrom !== "" && query?.endTo !== "" ) {
			queryParam += `&startFrom=${query.startFrom}&endTo=${query.endTo}`
		}
		if ( limit ) {
			queryParam += `&limit=${limit}`
		}
		if ( userId ) {
			queryParam += `&userId=${userId}`
		}
		const requestUrl = `${constants.BASE_URL}${constants.INVOICE}${queryParam}`
		return await axios.get( requestUrl )
	}
}

export const useInvoiceDetailsQuery = ( treatmentId: string ) =>
	useSuspenseQuery( {
		queryKey: [ "treatmentId", treatmentId ],
		queryFn: () =>
			CelebInvoiceService.getInstance()
				.invoiceDetails( treatmentId )
				.then( ( res ) => res.data.data ),
	} )

export const useListInvoiceQuery = ( props?: UseListInvoiceQueryProps, userId?: string ) =>
	useSuspenseQuery( {
		queryKey: [ "invoiceList", constants.INVOICE, props ],
		queryFn: () =>
			CelebInvoiceService.getInstance()
				.invoiceList( props?.pageOffset, props?.query, props?.limit, userId ?? "" )
				.then( ( res ) => ( { data: res.data, total: res.headers[ "x-total-count" ] } ) )
				.then( ( { data, total } ) => {
					return {
						data: data,
						total: total,
					}
				} ),
	} )

export type UseListInvoiceQueryProps = {
	pageOffset?: number
	query?: { search?: string; startFrom?: string; endTo: string }
	limit?: number
}

export type invoicesList = invoiceData[]
export interface invoiceData {
	id: string
	invoiceNumber: string
	userId: string
	status: string
	treatmentInfo: string
	treatmentId: string
	createdAt: string
	treatmentPayment: TreatmentPayment
}

export interface TreatmentPayment {
	amount: number
	status: string
	byUserId: string
}

export interface PatientTransferData {
	patientTransfer: PatientTransfer
	treatmentData: typeof PatientSchema
	invoiceData: InvoiceData
}

export interface PatientTransfer {
	id: string
	patientId: string
	transferFromDoctor: string
	transferToAddressId: string
	transferToDoctor: string
	note: string
	rejectionMessage: any
	status: string
	createdBy: string
	serviceId: string
	createdAt: string
	updatedAt: string
}

export interface Patient {
	id: string
	entityId: string
	firstName: string
	lastName: string
	email: string
	phone: string
	dob: string
	isTransfered: boolean
	gender: string
	ethenicity: string
	createdAt: string
	updatedAt: string
}

export interface InvoiceData {
	id: string
	invoiceNumber: string
	userId: string
	dueDate: string
	discountAmout: any
	discountType: any
	status: string
	transactionId: string
	treatmentId: string
	treatmentInfo: string
	paymentMethod: any
	paidAmount: any
	creditUsed: any
	currency: string
	notes: any
	createdAt: string
	updatedAt: string
}

export interface TreatmentInfo {
	treatmentPlanAddress: TreatmentPlanAddress
	serviceAddress: AddressSchemaType[]
	treatmentInfoData: TreatmentInfoData
}

export interface TreatmentPlanAddress {
	serviceId: string
	treatmentPlanDoctorAddress: AddressSchemaType
	doctor: Doctor
}

export interface Doctor {
	id: string
	entityId: number
	firstName: string
	lastName: string
	email: string
	contactNumber: string
	password: string
	description: any
	profilePictureUri: any
	status: boolean
	clinicalPreference: any
	emailNotificationsSubscribed: boolean
	resetPasswordCode: string
	lastPermissionUpdatedOn: any
	isSuperAdmin: any
	enableTwoFactorAuth: boolean
	specialRole: string
	deleted: boolean
	deletedAt: any
	deletedBy: any
	parentAccountId: string
	roleId: string
	createdAt: string
	updatedAt: string
}
