import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Alert, Modal } from "react-bootstrap"
import { FormProvider, useForm } from "react-hook-form"
import { useLocation } from "react-router"
import { PatientSchema, PatientSchemaType } from "../../../services/CelebPatient.Service"
import { getPatientByIdQuery } from "../../../services/CelebTreatment.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { useTeethStore } from "../../../store/toothSelectionStore"
import { clearHash } from "../../utlity/GenricFunctions"
import PatientCbctTab from "./PatientCBCT.tab"
import PatientInfoTab from "./PatientInfo.tab"
import PatientNavbar from "./PatientNavbar"
import PatientPhotosTab from "./PatientPhotos.tab"
import PatientScanTab from "./PatientScan.tab"
import PatientTreatmentInstructionTab from "./PatientTreatmentInstructions.tab"
import PatientXrayTab from "./PatientXray.tab"
import { photosLibraryReset } from "./defaultFileShowCase"

const PatientEditDialog = () => {
	const PatientDialog = usePatientDialogStore()
	const clearHashClear = clearHash()
	const { data: defaultValuesEdit } = getPatientByIdQuery( PatientDialog.typesEditOpen )

	const { toggleToothBondingJsonFile, toggleToothExtractionJsonFile, resetTeethSelection } =
		useTeethStore()
	const methods = useForm<PatientSchemaType>( {
		resolver: zodResolver( PatientSchema ),
		mode: "all",
		defaultValues: {
			...defaultValuesEdit.patient,
			...defaultValuesEdit,
		},
	} )

	React.useEffect( () => {
		methods.setValue( "treatmentPlan.instruction", defaultValuesEdit.instruction )
		methods.setValue( "treatmentPlan.lowerBracketSlotSize", defaultValuesEdit.lowerBracketSlotSize )
		methods.setValue( "treatmentPlan.upperBracketSlotSize", defaultValuesEdit.upperBracketSlotSize )
		methods.setValue( "treatmentPlan.doctorsWithAccess", defaultValuesEdit.doctorsWithAccess )
		methods.setValue( "treatmentPlan.serviceId", defaultValuesEdit.serviceId )
		methods.setValue( "assignedDoctor", defaultValuesEdit.doctor.id )
		toggleToothBondingJsonFile( defaultValuesEdit.teethBonding )
		toggleToothExtractionJsonFile( defaultValuesEdit.teethExtraction )
		PatientDialog.typesEditOpen == null
			? PatientDialog.setModalOpen( false )
			: PatientDialog.setModalOpen( true )
	}, [] )

	const clearFormData = () => {
		methods.reset()
		resetTeethSelection()
		PatientDialog.clearState()
		clearHashClear()
		PatientDialog.setReplaceOpen( { id: "" } )
		photosLibraryReset()
	}

	return (
		<React.Fragment>
			<FormProvider {...methods}>
				<Modal show={PatientDialog.modalOpen} onHide={clearFormData} size="xl" backdrop="static">
					<PatientNavbar />
					<Alert
						className="m-2"
						variant="warning"
						hidden={!PatientDialog.errorToShow.createPatient}
					>
						{PatientDialog.errorToShow.createPatient}
					</Alert>
					<PatientAddImpl />
				</Modal>
			</FormProvider>
		</React.Fragment>
	)
}

const PatientAddImpl = () => {
	return (
		<React.Fragment>
			<main className="p-2">
				<ActiveTabExporter />
			</main>
		</React.Fragment>
	)
}

const ActiveTabExporter = () => {
	const location = useLocation()

	switch ( location.hash ) {
		case "#PatientInfo":
			return <PatientInfoTab />
		case "#Scan":
			return <PatientScanTab />
		case "#Instructions":
			return <PatientTreatmentInstructionTab />
		case "#Photos":
			return <PatientPhotosTab />
		case "#X-rays":
			return <PatientXrayTab />
		case "#CBCT":
			return <PatientCbctTab />
		default:
			return <h1>hello</h1>
	}
}

export default PatientEditDialog
