import React from "react"
import { Button, Navbar, Nav, Placeholder, Card } from "react-bootstrap"
import SearchInput from "../components/form/SearchInput"
import { CheckSales, CheckGenericPermission } from "../components/permissions/CheckPermision"

const SubNavbarSkelton = () => {
	return (
		<React.Suspense>
			<nav style={{ backgroundColor: "#d9d9d9" }}>
				<div className="d-flex justify-content-between align-items-center p-2">
					<div className="d-flex gap-2">
						<SearchInput callback={() => ""} />
						<CheckSales>
							<div style={{ width: "12rem" }}>Locking the sales rep</div>
						</CheckSales>
					</div>
					<span className="text-end d-flex justify-content-end mx-2">
						<CheckGenericPermission genericLabel={"Task Completion"}>
							<a href="#TaskCompletion" className="btn btn-primary me-2">
								Complete task
							</a>
						</CheckGenericPermission>
						<CheckGenericPermission genericLabel={"Patient Create"}>
							<Button href="#PatientInfo" variant="primary" className="me-2">
								New Patient
							</Button>
						</CheckGenericPermission>
					</span>
				</div>
				<Navbar expand="lg" className="p-0">
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<div className="w-100 text-start" style={{ backgroundColor: "#d9d9d9" }}>
							<div className="w-100">
								<Nav>
									<Placeholder
										as={Card.Footer}
										size="lg"
										style={{
											height: "2.5rem",
											backgroundColor: "blue !important",
											width: "100% !important",
										}}
									></Placeholder>
								</Nav>
							</div>
						</div>
					</Navbar.Collapse>
				</Navbar>
			</nav>
		</React.Suspense>
	)
}

export default SubNavbarSkelton
