import React from "react"
import { Modal } from "react-bootstrap"

interface CommonDialogModalProps {
	show: boolean
	message: string
	onDelete: () => void
	onClose: () => void
}

const CommonDialogModal: React.FC<CommonDialogModalProps> = ( {
	show = false,
	message = "",
	onDelete,
	onClose,
} ) => {
	return (
		<Modal
			show={show}
			onHide={onClose}
			size="xl"
			backdrop="static"
			style={{ height: "100vh", backdropFilter: "blur(10px)" }}
		>
			<Modal.Body>
				<div>
					<p>{message}</p>
					<div style={{ textAlign: "end" }}>
						<button className="btn btn-primary mx-2" onClick={onDelete}>
							Yes
						</button>
						<button className="btn btn-secondary" onClick={onClose}>
							No
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default CommonDialogModal
