import { useSearchParams } from "react-router-dom"
import ToothModel from "../../../assets/components/toothModel"
import { useSidebarStore } from "../../../store/sideBarStore"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import { infoMapperNames } from "../../utlity/GenricFunctions"
import "./sidebar.css"

const SideInstructions = () => {
	const [ searchParams ] = useSearchParams()
	const patientId = searchParams.get( "PatientId" )
	if ( !patientId ) {
		return <div className="sideBarPatientInfo">No Patient Selected</div>
	}
	const sidebar = useSidebarStore()
	const patientData = sidebar.sideBarPatientData.PatientData || {}
	return (
		<div
			className="sideBarPatientInfo"
			style={{
				height: "100%",
				backgroundColor: "#d9d9d9",
				width: "100%",
				overflow: "scroll",
				scrollbarWidth: "none",
			}}
		>
			<CheckGenericPermission genericLabel="tx.Instructions : Orthodontist Name">
				<div>
					<span className="label">Orthodontist:</span>{" "}
					<span className="label-data">{infoMapperNames( patientData.doctor, [ "id" ] ) || "N/A"}</span>
				</div>
			</CheckGenericPermission>
			<CheckGenericPermission genericLabel="tx.Instructions : Orthodontist Name">
				<div>
					<span className="label">Clinic:</span>{" "}
					<span className="label-data">
						{infoMapperNames( patientData.treatmentPlanDoctorAddress, [ "id" ] ) || "N/A"}
					</span>
				</div>
			</CheckGenericPermission>
			<CheckGenericPermission genericLabel="tx.Instructions : Instructions">
				<span className="label">Treatment Instructions:</span>{" "}
				<span className="label-data">{patientData.instruction || "N/A"}</span>
			</CheckGenericPermission>

			<CheckGenericPermission genericLabel="tx.Instructions : Bonding teeth">
				<div className="mt-3">
					<span className="label">Bonded Teeth :</span> <ToothModel show={"teethBonding"} />
				</div>
			</CheckGenericPermission>
			<CheckGenericPermission genericLabel="tx.Instructions : Bonding teeth">
				<div className="mt-3">
					<span className="label">Extractions :</span> <ToothModel show={"teethExtraction"} />
				</div>
			</CheckGenericPermission>
		</div>
	)
}

export default SideInstructions
