export const getButtonProps = ( status: string ) => {
	switch ( status ) {
		case "processed":
			return { className: "", text: "View TP" }
		case "processing":
			return { className: "processing", text: "Processing" }
		case "failed":
			return { className: "failed", text: "Failed" }
		case "pending":
			return { className: "pending", text: "Pending" }
		default:
			return { className: "", text: "Unknown" }
	}
}
