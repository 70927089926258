import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Button, Form, Modal, Nav, Navbar, Table } from "react-bootstrap"
import { FormProvider, useForm } from "react-hook-form"
import { MoonLoader } from "react-spinners"

import { PrefCardDetails } from "../../../services/CelebDoctorAddress.Service"
import {
	usePreferencefetchDetails,
	useQueryListPaymentCards,
	useSetPreferredPaymentMutation,
} from "../../../services/CelebPayments.Service"
import { useProfileDialogStore } from "../../../store/profileDialogStore"
import { formatletters } from "../../utlity/GenricFunctions"

const PreferredPaymentMethodDialog = () => {
	return (
		<React.Suspense fallback={<MoonLoader />}>
			<PreferredPaymentMethodImp />
		</React.Suspense>
	)
}

const PreferredPaymentMethodImp = () => {
	const profileDialogStore = useProfileDialogStore()
	const { data } = useQueryListPaymentCards()
	const cardListing = data?.listing ?? []
	const { mutate: setPreferredCard } = useSetPreferredPaymentMutation()
	const { data: preferredCardId } = usePreferencefetchDetails(
		profileDialogStore.openCreditCardPreference,
	) || { data: null }

	const methods = useForm<{ selectedCardNumber: string }>( {
		resolver: zodResolver( PrefCardDetails ),
		defaultValues: {
			selectedCardNumber: preferredCardId?.data ? preferredCardId.data : "",
		},
		mode: "all",
	} )

	React.useEffect( () => {
		if ( preferredCardId?.data ) {
			methods.setValue( "selectedCardNumber", preferredCardId.data )
		}
	}, [ preferredCardId, methods ] )

	const submitHandler = methods.handleSubmit( ( formData ) => {
		if ( profileDialogStore.openCreditCardPreference && formData.selectedCardNumber ) {
			setPreferredCard( {
				addressId: profileDialogStore.openCreditCardPreference,
				payload: formData.selectedCardNumber,
			} )
			closeDialog()
		}
	} )

	const closeDialog = () => {
		profileDialogStore.setOpenCreditCardPreference( null )
		methods.reset()
	}
	return (
		<Modal
			show={profileDialogStore.openCreditCardPreference !== null}
			onHide={closeDialog}
			size="xl"
			backdrop="static"
			style={{ height: "100vh", backdropFilter: "blur(10px)" }}
		>
			<Modal.Header closeButton>
				<Navbar id="navbarScroll" as={Modal.Header}>
					<Nav variant="tabs" defaultActiveKey="#PreferredCards" className="d-flex mt-2 me-auto">
						<Nav.Link href="#PreferredCards" defaultChecked active>
							Select Preferred Card
						</Nav.Link>
					</Nav>
				</Navbar>
			</Modal.Header>
			<Modal.Body>
				<FormProvider {...methods}>
					<Form onSubmit={submitHandler}>
						<span className="d-flex justify-content-end">
							<Button type="submit" className="mt-2 btn">
								Submit
							</Button>
						</span>
						<Table striped bordered hover className="my-1">
							<thead>
								<tr>
									<th>Payment</th>
									<th>Card/Bank Details</th>
									<th>Expiry Date</th>
									<th>Payment Type</th>
									<th className="text-center">Action</th>
								</tr>
							</thead>
							<tbody>
								{cardListing?.map( ( paymentMethod: any, index: number ) => (
									<tr key={paymentMethod.id || index}>
										<td>{formatletters( paymentMethod.brand )}</td>
										<td>{paymentMethod.cardNumber}</td>
										<td>
											{paymentMethod?.expMonth}/{paymentMethod?.expYear}
										</td>
										<td>{formatletters( paymentMethod.type.toUpperCase() )}</td>
										<td className="text-center">
											<Form.Group>
												<Form.Check
													type="radio"
													{...methods.register( "selectedCardNumber" )}
													name="selectedCardNumber"
													id={`preferred-${index}`}
													checked={methods.watch( "selectedCardNumber" ) == paymentMethod?.id}
													value={paymentMethod.id}
												/>
											</Form.Group>
										</td>
									</tr>
								) )}
							</tbody>
						</Table>
					</Form>
				</FormProvider>
			</Modal.Body>
		</Modal>
	)
}

export default PreferredPaymentMethodDialog
