import { forwardRef, useImperativeHandle, useState } from "react"
import { Modal } from "react-bootstrap"

export interface AccountDeleteDialog {
	showDialog: ( data: any ) => void
	closeDialog: () => void
}

interface AccountDeleteDialogProps {
	onDeleteOkCallback: ( id: string ) => void
	deletingEntityName: string
	nameFieldKey: string | string[]
}

const DeleteDialog = forwardRef<AccountDeleteDialog, AccountDeleteDialogProps>( ( props, ref ) => {
	const [ showmodal, setshowmodal ] = useState( false )
	const { onDeleteOkCallback, deletingEntityName, nameFieldKey = "name" } = props
	const [ mainData, setMainData ] = useState<any>( { id: "", name: "" } )
	useImperativeHandle( ref, () => ( {
		showDialog,
		closeDialog,
	} ) )

	const closeDialog = () => {
		setshowmodal( false )
		resetState()
	}

	const resetState = () => {
		setMainData( { id: "", name: "" } )
	}
	const showDialog = ( data: any ) => {
		setshowmodal( true )
		setMainData( data )
	}

	const handleDelete = () => {
		// Perform any necessary delete action here
		// Example: deleteAccountType();
		const id = mainData?.id
		// Call the callback function passed from props
		onDeleteOkCallback( id )
		// Close the dialog
		closeDialog()
	}

	const getEntityName = () => {
		if ( Array.isArray( nameFieldKey ) ) {
			return nameFieldKey.map( ( key ) => mainData[ key ] ).join( " " )
		} else {
			return mainData[ nameFieldKey ] || ""
		}
	}

	return (
		<Modal
			show={showmodal}
			onHide={closeDialog}
			size="xl"
			backdrop="static"
			style={{ height: "100vh", backdropFilter: "blur(10px)" }}
		>
			<Modal.Body>
				<div>
					<p>
						Are you sure you want to delete <b>'{getEntityName()}' </b>
						{deletingEntityName}?
					</p>
					<div style={{ textAlign: "end" }}>
						<button className="btn btn-primary mx-2" onClick={handleDelete}>
							Yes
						</button>
						<button className="btn btn-secondary" onClick={closeDialog}>
							No
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
} )

export { DeleteDialog }
