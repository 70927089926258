import { useCallback } from "react"
import { FieldErrors, useFormContext } from "react-hook-form"

import { fileUploadingType, usePatientDialogStore } from "../../store/patientDailogStore"
import { useServiceDialogStore } from "../../store/serviceDialogStore"
import { validEmail } from "../../utils/Validations"

const useCustomErrorMarkerTry = () => {
	const { getValues, control } = useFormContext<any>()
	const checkingCondition = {
		email: true,
		treatmentInfo: true,
		treatmentPlan: true,
		scan: true,
		photos: true,
		replaceOrder: true,
	}
	const {
		cbctDocumentJson,
		individualPhoto,
		xRays,
		scan,
		replaceOrderPhoto,
		setNavErrors,
		setCustomError,
	} = usePatientDialogStore()

	const resetFirst = () => {
		checkingCondition.email = true
		checkingCondition.treatmentInfo = true
		checkingCondition.treatmentPlan = true
		checkingCondition.scan = true
		checkingCondition.photos = true
		checkingCondition.replaceOrder = true
	}

	const customErrorMarker = useCallback(
		( errorData?: FieldErrors<any> ) => {
			const {
				treatmentPlan,
				firstName,
				replaceOrder,
				lastName,
				gender,
				assignedDoctor,
				selectedOffice,
			} = control._formState.errors || errorData
			resetFirst()

			if ( treatmentPlan ) {
				setNavErrors( "instructions", "red" )
				checkingCondition.treatmentPlan = false
				window.location.href = "#Instructions"
			}

			if ( replaceOrder ) {
				setNavErrors( "replace", "red" )
				checkingCondition.replaceOrder = false
				window.location.href = "#Replace"
			}

			const email = getValues( "email" )

			if ( email ) {
				const checkEmail = validEmail.test( email )
				if ( !checkEmail ) {
					setCustomError( "email", "Email is not valid" )
					setNavErrors( "infoTab", "red" )
					checkingCondition.email = false
					window.location.href = "#PatientInfo"
				} else {
					setCustomError( "email", "" )
					setNavErrors( "infoTab", "none" )
					checkingCondition.email = true
				}
			} else {
				setCustomError( "email", "" )
				setNavErrors( "infoTab", "none" )
			}

			if ( firstName || lastName || gender || selectedOffice || assignedDoctor ) {
				setNavErrors( "infoTab", "red" )
				checkingCondition.treatmentInfo = false
				window.location.href = "#PatientInfo"
			}

			const checking = customValidationFunction(
				cbctDocumentJson,
				individualPhoto,
				xRays,
				scan,
				replaceOrderPhoto,
			)

			if ( checking.checkScan.length <= 1 ) {
				checkingCondition.scan = true
			}

			if ( checking.checkIndividualPhotos.length <= 6 ) {
				if ( checking.checkPatientCompositePhotoJson.length <= 0 ) {
					setNavErrors( "photos", "none" )
					checkingCondition.photos = true
				}
			}

			const check = Object.values( checkingCondition ).map( ( x ) => {
				if ( x == false ) {
					return "fail"
				} else {
					return "pass"
				}
			} )

			return check.includes( "fail" )
		},

		[],
	)

	return { customErrorMarker }
}

export const useCustomErrorMarkerWithService = () => {
	const { watch, control } = useFormContext<any>()
	const checkingCondition = {
		serviceInfo: true,
		tasks: true,
		price: true,
		address: true,
	}

	const { setNavErrors, setCustomError } = useServiceDialogStore()

	const customErrorMarker = useCallback( ( errorData?: FieldErrors<any> ) => {
		const { name, address, tasks, priceLower, priceUpper, priceBoth } =
			control._formState.errors || errorData
		const email = watch( "email" )

		if ( !email ) {
			setNavErrors( "ServiceInfo", "red" )
			setCustomError( "email", "Email is not valid" )
			window.location.href = "#ServiceInfo"
			return false
		}

		if ( name || address ) {
			setNavErrors( "ServiceInfo", "red" )
			checkingCondition.serviceInfo = false
			window.location.href = "#ServiceInfo"
			return false
		}

		if (
			( priceLower && priceLower.type === "invalid_type" ) ||
			( priceUpper && priceUpper.type === "invalid_type" ) ||
			( priceBoth && priceBoth.type === "invalid_type" )
		) {
			setNavErrors( "ServiceInfo", "red" )
			checkingCondition.price = false
			window.location.href = "#ServiceInfo"
			return false
		}

		if ( tasks ) {
			setNavErrors( "Tasks", "red" )
			checkingCondition.tasks = false
			setCustomError( "tasks", "Please add at least two tasks" )
			window.location.href = "#Tasks"
			return false
		}
		return true
	}, [] )

	return { customErrorMarker }
}

export const customValidationFunction = (
	cbctDocumentJson: { [key: string]: fileUploadingType },
	individualPhoto: { [key: string]: fileUploadingType },
	xRays: { [key: string]: fileUploadingType },
	scan: { [key: string]: fileUploadingType },
	patientCompositePhotoJson: { [key: string]: fileUploadingType },
) => {
	const checkIndividualPhotos = Object.values( individualPhoto ).filter( Boolean )
	const checkCbctDocumentJson = Object.values( cbctDocumentJson ).filter( Boolean )
	const checkXRays = Object.values( xRays ).filter( Boolean )
	const checkScan = Object.values( scan ).filter( Boolean )
	const checkPatientCompositePhotoJson = Object.values( patientCompositePhotoJson ).filter( Boolean )

	return {
		checkIndividualPhotos,
		checkCbctDocumentJson,
		checkXRays,
		checkScan,
		checkPatientCompositePhotoJson,
	}
}

export default useCustomErrorMarkerTry
