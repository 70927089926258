import React from "react"
import { Modal } from "react-bootstrap"
import { DeleteDialogType, userSessionStore } from "../../store/userSessionStore"

const DeleteDialogModal = () => {
	const { deleteDialogOpen, setdeleteDialogOpen } = userSessionStore()
	const dataToClear: DeleteDialogType = {
		deletingEntityLabel: "",
		handleDelete: () => "",
		hideShow: false,
	}
	return (
		<React.Fragment>
			<Modal
				show={deleteDialogOpen.hideShow}
				onHide={() => setdeleteDialogOpen( dataToClear )}
				size="xl"
				backdrop="static"
				style={{ height: "100vh", backdropFilter: "blur(10px)" }}
			>
				<Modal.Body>
					<div>
						<p>
							Are you sure you want to delete <b>{deleteDialogOpen.deletingEntityLabel}</b> ?
						</p>
						<div style={{ textAlign: "end" }}>
							<button className="btn btn-primary mx-2" onClick={deleteDialogOpen.handleDelete}>
								Yes
							</button>
							<button
								className="btn btn-secondary"
								onClick={() => setdeleteDialogOpen( dataToClear )}
							>
								No
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default DeleteDialogModal
