import { Button, Container, Navbar } from "react-bootstrap"
import Moment from "react-moment"
import { Params, useParams, useSearchParams } from "react-router-dom"
import generatePDF from "react-to-pdf"
import { constants } from "../../services/CelebApiConstants"
import { InvoiceData, useInvoiceDetailsQuery } from "../../services/CelebInvoice.Service"
import {
	PaymentData,
	useServicefetchDetails,
	useServicePricingsfetchDetails,
} from "../../services/CelebService.Service"
import { getPatientByIdQuery, getPatientTransferById } from "../../services/CelebTreatment.Service"
import { JawType } from "../../utils/appConstants"
import { descriptionMap } from "../utlity/GenricFunctions"
import "./receiptGenerator.css"

const ReceiptGenerator = () => {
	return (
		<>
			<NavbarReciept />
			<div id="content-id" className="d-flex">
				<Receipt />
			</div>
		</>
	)
}

const Receipt = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const [ searchParams ] = useSearchParams()
	const newParams = new URLSearchParams( searchParams )
	const { data: defaultValuesEdit } = getPatientByIdQuery( paramsData.id ?? "null" )
	const { data: newDefaultValuesEdit } = getPatientTransferById( paramsData.id ?? "null" )
	const { data: invoiceInfoData } = useInvoiceDetailsQuery( paramsData.id ?? "null" )
	type TreatmentInfoType = {
		serviceAddress?: AddressRenderType[]
		treatmentInfoData: TreatmentInfoData
		clinicAddress?: AddressRenderType
	}
	let treatmentInfo: TreatmentInfoType = {
		treatmentInfoData: undefined as unknown as TreatmentInfoData,
		clinicAddress: undefined as unknown as AddressRenderType,
	}
	let doctorDetails: TreatmentInfoData | undefined = undefined
	let invoiceData: InvoiceData | {} = {}
	let serviceAddress = undefined
	let doctorAddress: AddressRenderType | undefined = undefined
	treatmentInfo = invoiceInfoData[ 0 ]?.treatmentInfo || "{}"
	serviceAddress = treatmentInfo?.serviceAddress?.[ 0 ] ?? null
	doctorAddress = treatmentInfo?.clinicAddress
	doctorDetails = treatmentInfo.treatmentInfoData
	invoiceData = newDefaultValuesEdit?.invoiceData ?? {}

	const { data: data } = useServicePricingsfetchDetails( paramsData.id )
	const serviceId = newParams.get( "serviceId" )
	const { data: serviceData } = useServicefetchDetails( serviceId! )
	const treatmentType =
		( doctorDetails?.treatmentDescription as JawType ) ??
		( data.treatmentPaymentTreatment.treatmentType as JawType )
	if ( !serviceAddress ) {
		//for old patient who dont have entry in invoices table
		serviceAddress = serviceData.services.address?.[ 0 ]
	}
	console.log( "serviceAddress", serviceData )

	const description = descriptionMap[ treatmentType ] || "Celebrace Braces"
	const calculateActualPrice = ( data: PaymentData ) => {
		const taxPercentage = data?.taxBreakdown?.[ 0 ]?.percentage
		const amountWithTax = data?.amount
		const actualPrice = amountWithTax / ( 1 + taxPercentage / 100 )
		return Math.round( actualPrice * 100 ) / 100
	}
	const renderAddress = ( address: AddressRenderType ) => {
		if ( !address ) return null
		return (
			<>
				<div>{address.name}, </div>
				<div>{address.addressLine1}, </div>
				<div hidden={!address.addressLine2 || address.addressLine2 != null}>
					{address.addressLine2 ?? ""}
				</div>
				<div>
					{address.city}, {address.state}, {address.pincode}
				</div>
			</>
		)
	}

	const renderField = ( value: string | number | undefined ) => {
		return value ? value : "-"
	}

	return (
		<Container style={{ border: "2px solid #d9d9d9", padding: "5rem", marginTop: "2rem" }}>
			<div style={{ display: "flex", marginBottom: "2%", justifyContent: "space-between" }}>
				<div>
					{serviceAddress ? (
						<>
							<h1>{serviceData.services.name}</h1>
							<div>{renderField( serviceAddress.name )},</div>
							<div>
								{renderField( serviceAddress.addressLine1 )},{" "}
								{renderField( serviceAddress.addressLine2 )}, {renderField( serviceAddress.city )},{" "}
								{renderField( serviceAddress.state )},{renderField( serviceAddress.country )},{" "}
								{renderField( serviceAddress.pincode )}
							</div>
							<span>
								Email : <a href={serviceData?.services?.email}>{serviceData?.services?.email}</a>{" "}
							</span>
							<div>Phone : {serviceData?.services.contactNo}</div>
						</>
					) : (
						<>
							<h1>{serviceData.services.name}</h1>
						</>
					)}
				</div>
				<div>
					<img
						src={constants.IMAGEURL + serviceData?.services?.logo}
						height={100}
						width={100}
						style={{ objectFit: "contain" }}
						onError={( { currentTarget } ) => {
							currentTarget.onerror = null
							currentTarget.src = `${process.env.PUBLIC_URL}/celebLogo.png`
						}}
						alt="Celebrace Logo"
					/>
				</div>
				<div>
					<h1 className="text-end">RECEIPT</h1>
				</div>
			</div>
			<hr />
			<div className="mt-5">
				<div className="d-flex justify-content-between">
					<div>
						<strong className="mb-3">Bill To:</strong>
						<div>
							Dr.{" "}
							{doctorDetails
								? doctorDetails.firstName + " " + doctorDetails.lastName
								: defaultValuesEdit?.doctor?.firstName + " " + defaultValuesEdit?.doctor?.lastName}
							,
						</div>
						<>
							<div>
								{renderAddress(
									doctorAddress ? doctorAddress : defaultValuesEdit?.treatmentPlanDoctorAddress,
								)}
							</div>
						</>
					</div>
					<div>
						<strong>Ship To:</strong>
						{doctorAddress
							? renderAddress( doctorAddress )
							: renderAddress( defaultValuesEdit?.treatmentPlanDoctorAddress )}
					</div>
					<div>
						<div className="d-flex justify-content-between">
							<b>Receipt # </b>
							<p className="mx-2">{( invoiceData as InvoiceData )?.invoiceNumber ?? "NA"}</p>
						</div>
						<div className="d-flex justify-content-between">
							<b>Invoice Date </b>
							<p className="mx-2">
								<Moment format="MM/DD/YYYY" utc>
									{( invoiceData as InvoiceData )?.createdAt
										? ( invoiceData as InvoiceData ).createdAt
										: data.createdAt}
								</Moment>
							</p>
						</div>
					</div>
				</div>
				<table style={{ width: "100%", padding: "2px" }} className="mt-4">
					<thead>
						<tr className="myRow">
							<th className="center myHead myRow">QTY</th>
							<th className="center myHead myRow">Patient ID</th>
							<th className="center myHead myRow">Patient Name</th>
							<th className="center myHead myRow">Description</th>
							<th className="center myHead myRow">Unit Price</th>
							<th className="center myHead myRow">Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr className="myRow">
							<td className="center">1</td>
							<td className="myCol">{defaultValuesEdit.patient.entityId}</td>
							<td className="myCol">
								{defaultValuesEdit.patient.firstName + " " + defaultValuesEdit.patient.lastName}{" "}
							</td>
							<td className="myCol">{description}</td>
							<td className="myCol">$ {renderField( calculateActualPrice( data ) )}</td>
							<td>$ {renderField( calculateActualPrice( data ) )}</td>
						</tr>
						<tr>
							<td className="center"></td>
							<td className="center"></td>
							<td className="center"></td>
							<td className="center"></td>
							<td className="myRow">SubTotal</td>
							<td className="myRow">$ {renderField( calculateActualPrice( data ) )}</td>
						</tr>
						<tr>
							<td className="center"></td>
							<td className="center"></td>
							<td className="center"></td>
							<td className="left"></td>
							<td className="myRow">Sales Tax {data?.taxBreakdown?.[ 0 ]?.percentage}%</td>
							<td className="myRow">$ {renderField( data?.taxBreakdown?.[ 0 ]?.amount )}</td>
						</tr>
						<tr>
							<td className="center"></td>
							<td className="center"></td>
							<td className="center"></td>
							<td className="left"></td>
							<td className="myRow">
								<b>Total</b>
							</td>
							<td className="myRow">
								<b>$ {renderField( data?.amount )}</b>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Container>
	)
}

const NavbarReciept = () => {
	const handleGoBack = () => {
		window.close()
	}
	const getTargetElement = () => document.getElementById( "content-id" )
	return (
		<Navbar className="bg-body-tertiary justify-content-between" style={{ width: "100%" }}>
			<div>
				<Button onClick={() => handleGoBack()}>Close</Button>
			</div>

			<div>
				<Button onClick={() => generatePDF( getTargetElement )}>Download</Button>
			</div>
		</Navbar>
	)
}

export interface AddressRenderType {
	id: string
	name: string
	addressLine1: string
	addressLine2: string
	city: string
	state: string
	country: string
	pincode: string
	deleted: boolean
	deletedAt: any
	deletedBy: any
	doctorId: string
	category: string
	createdAt: string
	updatedAt: string
}

export interface TreatmentInfoData {
	firstName: string
	lastName: string
	email: string
	id: string
	treatmentDescription: string
}

export default ReceiptGenerator
