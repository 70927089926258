import { useSuspenseQuery } from "@tanstack/react-query"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"

export class CelebTreatmentFilesService {
	static _instance: CelebTreatmentFilesService = new CelebTreatmentFilesService()

	private constructor() {
		CelebTreatmentFilesService._instance = this
	}

	static getInstance(): CelebTreatmentFilesService {
		return CelebTreatmentFilesService._instance
	}

	async getByTreatment( {
		treatmentId,
		patientId,
		fileCategory,
	}: {
		treatmentId: string | null
		patientId?: string | null
		fileCategory?: string[]
	} ) {
		const requestUrl = `${constants.TREATMENT_FILES}/treatment/${treatmentId}`
		let queryString = patientId ? "patientsId=" + patientId : ""
		if ( fileCategory && fileCategory.length > 0 ) {
			fileCategory?.map( ( category ) => {
				queryString += "&category=" + category
			} )
		}
		queryString = queryString ? "?" + queryString : ""
		return await CelebRequestGeneratorService.processGetRequest( requestUrl + queryString )
	}
}
export const getPatientTreatmentFilesQuery = ( treatmentId: string | null, patientId?: string ) => {
	return useSuspenseQuery( {
		queryKey: [ "PatientDataFiles", treatmentId ],
		queryFn: () => {
			if ( treatmentId === "null" ) return Promise.resolve( null )
			return CelebTreatmentFilesService.getInstance()
				.getByTreatment( { treatmentId: treatmentId, patientId: patientId } )
				.then( ( res ) => res.data.data )
				.then( ( res ) => res )
		},
	} )
}

export const getTreatmentFilesByCategoryQuery = (
	treatmentId: string | null,
	fileCategory?: string[],
) => {
	return useSuspenseQuery( {
		queryKey: [ "PatientDataCategoryFiles", treatmentId ],
		queryFn: () => {
			if ( treatmentId === "null" ) return Promise.resolve( null )
			return CelebTreatmentFilesService.getInstance()
				.getByTreatment( { treatmentId: treatmentId, fileCategory: fileCategory } )
				.then( ( res ) => res.data.data )
				.then( ( res ) => res )
		},
	} )
}

export const getPatientTreatmentFile = ( treatmentId: string | null, patientId?: string ) => {
	return useSuspenseQuery( {
		queryKey: [ "ReplacementDataFiles", treatmentId ],
		queryFn: () => {
			if ( treatmentId === "null" ) return Promise.resolve( null )
			return CelebTreatmentFilesService.getInstance()
				.getByTreatment( { treatmentId: treatmentId, patientId: patientId } )
				.then( ( res ) => res.data.data )
				.then( ( res ) => res )
		},
	} )
}
