import React from "react"
import { Button, Form, Spinner, Table } from "react-bootstrap"
import {
	useDefaultPaymentMutation,
	useQueryListPaymentCards,
	useSetupVerifiedMutation,
} from "../../../services/CelebPayments.Service"
import { useProfileDialogStore } from "../../../store/profileDialogStore"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import PaymentMethodAddDialogDialog from "./PaymentMethodAddDialog.dialog"
const ProfilePaymentMethodsTab = () => {
	return (
		<React.Suspense>
			<ProfilePaymentMethodsImpl />
		</React.Suspense>
	)
}

const ProfilePaymentMethodsImpl = () => {
	const profile = useProfileDialogStore()
	const { data } = useQueryListPaymentCards()
	const { mutate: makeCardDefault, isPending } = useDefaultPaymentMutation()
	const cardListing = data.listing
	return (
		<React.Fragment>
			<div className="text-end d-flex gap-3 flex-end justify-content-end align-items-center">
				<CheckGenericPermission genericLabel="Add Payment Method">
					<Button
						className="btn btn-primary mb-1"
						onClick={() => profile.setPaymentCreateOpen( true )}
					>
						Add payment method
					</Button>
				</CheckGenericPermission>
			</div>
			<Table striped bordered className="my-1">
				<thead style={{ backgroundColor: "grey" }} className="thead-dark p-2">
					<tr>
						<th>Card Type</th>
						<th>Last 4 Digits</th>
						<th>Expiration</th>
						<th style={{ width: "20%" }}>
							Default {isPending && <Spinner variant="secondary" size="sm" animation="border" />}
						</th>
					</tr>
				</thead>
				<tbody>
					{cardListing.map( ( card: Card ) => (
						<tr key={card.id}>
							<td>{card.brand}</td>
							<td>{card.cardNumber}</td>
							<td>
								{card.expMonth}/{card.expYear}
							</td>
							<td>
								<Form.Check
									type="radio"
									checked={card.isDefault}
									id={`payemnt-card`}
									onChange={() => makeCardDefault( card.id )}
								/>
							</td>
						</tr>
					) )}
				</tbody>
			</Table>

			{profile.paymentCreateOpen && <PaymentMethodAddDialogDialog />}
		</React.Fragment>
	)
}
interface Card {
	id: string
	stripId: string
	brand: string
	expMonth: number
	expYear: number
	cardNumber: number
	isDefault: boolean
	stripCustomerId: string
	userId: string
	createdAt: string
	updatedAt: string
}
export default ProfilePaymentMethodsTab
