import { Button, Modal } from "react-bootstrap"
import { useLoginPageStore } from "../../store/loginStore"
import { LoginRequest, useOtpandLoginMutation } from "../../services/CelebAuth.Service"
import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { userSessionStore } from "../../store/userSessionStore"

const OTPModal = () => {
	const { setOtpModal, otpModal } = useLoginPageStore()
	const { mutateAsync: otpLoginMutation } = useOtpandLoginMutation()
	const [ otp, setOtp ] = useState( new Array( 6 ).fill( "" ) )
	const { getValues } = useFormContext<LoginRequest>()
	const { setSession } = userSessionStore()
	const [ errorMessage, setErrorMessage ] = useState<string | null>( null )
	const [ timeLeft, setTimeLeft ] = useState( 900 )

	useEffect( () => {
		const interval = setInterval( () => {
			setTimeLeft( ( prevTimeLeft ) => prevTimeLeft - 1 )
		}, 1000 )
		return () => clearInterval( interval )
	}, [] )

	const handleChange = ( element: EventTarget & HTMLInputElement, index: number ) => {
		if ( isNaN( Number( element.value ) ) ) return

		const newOtp = [ ...otp ]
		newOtp[ index ] = element.value
		setOtp( newOtp )

		if ( element.nextSibling instanceof HTMLElement ) {
			element.nextSibling.focus()
		}
	}

	const handleKeyDown = ( event: React.KeyboardEvent<HTMLInputElement>, index: number ) => {
		if ( event.key === "Backspace" && !otp[ index ] && index > 0 ) {
			const newOtp = [ ...otp ]
			newOtp[ index - 1 ] = ""
			setOtp( newOtp )
			if ( event.currentTarget.previousSibling instanceof HTMLElement ) {
				event.currentTarget.previousSibling.focus()
			}
		}
	}

	const handlePaste = ( event: React.ClipboardEvent<HTMLInputElement> ) => {
		event.preventDefault()
		const pastedData = event.clipboardData.getData( "text" ).trim()
		if ( !/^\d{6}$/.test( pastedData ) ) return

		setOtp( pastedData.split( "" ) )
	}

	const handleSubmit = async () => {
		if ( timeLeft <= 0 ) {
			setErrorMessage( "OTP has expired. Please request a new one." )
			return
		}

		try {
			const otpValue = otp.join( "" )
			const response = await otpLoginMutation( {
				otp: otpValue,
				email: getValues( "email" ),
				password: getValues( "password" ),
			} )

			if ( response && response.data.body.data.user ) {
				const user = response.data.body.data.user
				localStorage.setItem( "user", JSON.stringify( user ) )
				localStorage.setItem( "token", response.data.body.data.accessToken )
				setSession( user )

				switch ( user.specialRole ) {
					case "admin":
						window.location.href = "/managment?status=active"
						break
					case "doctor":
						window.location.href = "/doctor?status=active"
						break
					case "none":
						window.location.href = "/operator?status=active"
						break
					case "sales":
						window.location.href = "/sales?status=active"
						break
					default:
						console.warn( "Unknown role" )
				}
			}
		} catch ( error ) {
			setErrorMessage( "OTP validation failed. Please try again." )
			console.error( "OTP validation failed:", error )
		}
	}

	const handleCancel = () => {
		setOtpModal( false )
		setOtp( new Array( 6 ).fill( "" ) )
		setErrorMessage( null )
	}

	const formatTime = () => {
		const minutes = Math.floor( timeLeft / 60 )
		const seconds = timeLeft % 60
		return `${minutes}:${seconds.toString().padStart( 2, "0" )}`
	}

	return (
		<Modal show={otpModal} centered>
			<Modal.Body style={{ textAlign: "center", padding: "2rem" }}>
				<h4 style={{ marginBottom: "1rem" }}>Verify</h4>
				<p style={{ color: "#6c757d", marginBottom: "1.5rem" }}>
					Your code was sent to you via email
				</p>
				<p style={{ color: "#dc3545", marginBottom: "1.5rem" }}>OTP expires in: {formatTime()}</p>
				{errorMessage && <p style={{ color: "red", marginBottom: "1.5rem" }}>{errorMessage}</p>}
				<div style={{ display: "flex", justifyContent: "center", marginBottom: "1.5rem" }}>
					{otp.map( ( data, index ) => (
						<input
							key={index}
							type="text"
							maxLength={1}
							value={data}
							onChange={( e ) => handleChange( e.target, index )}
							onKeyDown={( e ) => handleKeyDown( e, index )}
							onPaste={handlePaste}
							style={{
								width: "40px",
								height: "40px",
								textAlign: "center",
								fontSize: "18px",
								border: "1px solid #ccc",
								borderRadius: "5px",
								margin: "0 5px",
								outline: "none",
							}}
						/>
					) )}
				</div>
				<div style={{ display: "flex", justifyContent: "center", gap: "1rem", marginTop: "1rem" }}>
					<Button variant="primary" onClick={handleSubmit} style={{ padding: "0.5rem 2rem" }}>
						Verify
					</Button>
					{/* <p style={{ marginTop: "1rem" }}>
          Didn’t receive code? <a href="#" style={{ textDecoration: "none", color: "#007bff" }}>Request again</a>
        </p> */}
					<Button variant="secondary" onClick={handleCancel} style={{ padding: "0.5rem 2rem" }}>
						Cancel
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default OTPModal
