import React from "react"
import { PuffLoader } from "react-spinners"
import { userSessionStore } from "../../../store/userSessionStore"
import CustomerTimeline from "../../graph/CustomerGraph"
import AppGraph from "../../graph/Graph"

const TimeLine = () => {
	const sessionStore = userSessionStore()
	return (
		<React.Suspense
			fallback={
				<PuffLoader
					speedMultiplier={2}
					style={{
						position: "absolute",
						top: "50%",
						transform: "translateX(calc(-17vw))",
						right: 0,
					}}
				/>
			}
		>
			{sessionStore.user.isDoctor ? <CustomerTimeline /> : <AppGraph />}
		</React.Suspense>
	)
}

export default TimeLine
