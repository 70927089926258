import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Button, Form, Modal, Table } from "react-bootstrap"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { useLocation } from "react-router"
import LoadingOverlay from "../../../assets/styles/components/loader/loadingOverlay"
import { queryClient } from "../../../providers/QueryProvider"
import { constants } from "../../../services/CelebApiConstants"
import { ServiceAddress, ServiceSchemaForm } from "../../../services/CelebService.Service"
import { useProfileDialogStore } from "../../../store/profileDialogStore"
import { useServiceDialogStore } from "../../../store/serviceDialogStore"
import CloseDialog from "../../utlity/CloseDialog"
import { EditTrash } from "../../utlity/EditTrash"
import { clearFormState, clearHash, infoMapper } from "../../utlity/GenricFunctions"
import ProfileAddressAddDialog from "../profile-v2/ProfileAddressAdd.dialog"
import { ServiceFormInput, ServiceFormType } from "./serviceFunctions/ServiceFormFields"
import { ServiceNavbar } from "./servicePages/serviceNavbarPage"
import { ServicePricingSetter } from "./servicePages/servicePricingSetter"
import ServiceTaskBox from "./ServiceTaskForm"

const ServicesAddDialog = React.memo( () => {
	const serviceData = window.localStorage.getItem( "serviceData" )
	const methods = useForm<ServiceFormType>( {
		resolver: zodResolver( ServiceSchemaForm ),
		mode: "all",
		defaultValues: serviceData && serviceData.length >= 1 ? JSON.parse( serviceData ) : {},
	} )
	const serviceStore = useServiceDialogStore()
	const clearHashClear = clearHash()
	const form = JSON.parse( localStorage.getItem( "serviceData" ) + "" )

	methods.watch( () => {
		localStorage.setItem( "serviceData", JSON.stringify( methods.getValues() ) )
	} )

	const handleClose = ( action: string ) => {
		if ( action === "minimize" ) {
			return handleMinimize()
		}
		closeForm()
	}

	const closeForm = () => {
		serviceStore.resetTaskForms()
		clearFormState( form, methods.unregister )
		clearHashClear()
		queryClient.invalidateQueries()
	}

	const handleMinimize = () => {
		methods.watch( async ( formValues ) => {
			await localStorage.setItem( "serviecData", JSON.stringify( formValues ) )
		} )
		serviceStore.draftState()
	}

	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<FormProvider {...methods}>
				<Modal
					show={serviceStore.createOpen}
					size="xl"
					animation={true}
					backdrop="static"
					keyboard={false}
				>
					<ServiceNavbar />
					<TabShowCase />
				</Modal>
			</FormProvider>
			<CloseDialog visible={serviceStore.closeModalDialog} callBackFunction={handleClose} />
		</React.Suspense>
	)
} )

const TabShowCase = React.memo( () => {
	const location = useLocation()
	switch ( location.hash ) {
		case "#ServiceInfo":
			return <ServiceDetails />
		case "#Tasks":
			return <ServiceTaskBox />
		default:
			return <ServiceDetails />
	}
} )

const ServiceDetails = React.memo( () => {
	const methods = useFormContext<ServiceFormType>()
	const [ logo, setLogo ] = React.useState<FileList | null>()
	const profileStore = useProfileDialogStore()

	const addresses = React.useMemo( () => methods.watch( "address" ), [ methods ] )

	const addAdress = ( address: ServiceAddress ) => {
		methods.setValue( "address", address )
	}

	const DeleteAdress = ( index: number ) => {
		return methods.setValue( `address.${index}.deleted`, true )
	}
	return (
		<Modal.Body>
			<FormProvider {...methods}>
				<Form>
					<span className="d-inline-flex justify-content-evenly w-100">
						<ServiceFormInput label="Service Name" autoFocus name="name" />
						<div>
							<Form.Label>Service Logo</Form.Label>
							<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<Form.Control
									id="logo-upload"
									type="file"
									accept="image/*"
									onChange={( e: React.ChangeEvent<HTMLInputElement> ) => {
										const files = e.currentTarget?.files
										if ( files && files.length > 0 ) {
											setLogo( files )
											methods.setValue( "logo", files )
										}
									}}
									hidden
								/>
								<label htmlFor="logo-upload">
									<img
										src={
											methods.watch( "logo" )?.length > 0
												? URL.createObjectURL( methods.watch( "logo" )?.[ 0 ] )
												: "/building.png"
										}
										style={{
											objectFit: "contain",
											width: "2.5rem",
											height: "2.5rem",
											cursor: "pointer",
											borderRadius: "5px",
										}}
										alt="Selected Logo"
										onError={( { currentTarget } ) => {
											currentTarget.onerror = null
											currentTarget.src = constants.IMAGEURL + "building.png"
										}}
									/>
								</label>
							</div>
						</div>
						<ServiceFormInput
							label="Contact No"
							name="contactNo"
							placeholder="Contact No"
							type="number"
						/>
						<ServiceFormInput label="Email" name="email" placeholder="Email" />
						<div>
							<Form.Label>Service Status</Form.Label>
							<Form.Check
								type="checkbox"
								defaultChecked={true}
								{...methods.register( "status" )}
								label={"Make Active"}
							/>
						</div>
					</span>

					{/* take out a seprate component for pricing which will be used in both add and edit */}
					<span className="d-inline-flex justify-content-evenly w-100">
						<ServicePricingSetter
							name="Price Upper"
							formControlAccess={{
								...methods.register( "priceUpper", {
									valueAsNumber: true,
									onChange: ( e ) => {
										methods.setValue( "priceUpper", parseFloat( e.currentTarget.value ) )
									},
								} ),
							}}
							error={!!methods.formState.errors.priceUpper}
							errorMessage={methods.formState.errors.priceUpper?.message}
						/>
						<ServicePricingSetter
							name="Price Lower"
							formControlAccess={{
								...methods.register( "priceLower", {
									valueAsNumber: true,
									onChange: ( e ) => {
										methods.setValue( "priceLower", parseFloat( e.currentTarget.value ) )
									},
								} ),
							}}
							error={!!methods.formState.errors.priceLower}
							errorMessage={methods.formState.errors.priceLower?.message}
						/>
						<ServicePricingSetter
							name="Price Both"
							formControlAccess={{
								...methods.register( "priceBoth", {
									valueAsNumber: true,

									onChange: ( e ) => {
										methods.setValue( "priceBoth", parseFloat( e.currentTarget.value ) )
									},
								} ),
							}}
							error={!!methods.formState.errors.priceBoth}
							errorMessage={methods.formState.errors.priceBoth?.message}
						/>
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							<Button
								onClick={() => {
									profileStore.setCreateOpen( true )
								}}
							>
								Add Address
							</Button>
						</div>
					</span>

					{/* take out a seprate component for address */}
					<Table striped bordered hover className="my-1">
						<thead>
							<tr>
								<th>Office Name</th>
								<th>Address</th>
								<th>Modify</th>
							</tr>
						</thead>
						<tbody>
							{addresses?.map( ( add, index: number ) => (
								<>
									<tr hidden={add.deleted}>
										<td>{add?.name}</td>
										<td>
											{infoMapper( add, [
												"id",
												"name",
												"deleted",
												"deletedAt",
												"deletedBy",
												"doctorId",
												"category",
												"createdAt",
												"updatedAt",
											] )}
										</td>
										<td className="text-end">
											<EditTrash
												editIcon={{ icon: faPencil, color: "#8faadc" }}
												trashIcon={{ icon: faTrash, color: "#d9d9d9" }}
												deleteCallBack={() => {
													DeleteAdress( index )
												}}
												editCallback={() => {
													profileStore.setEditOpen( add )
												}}
											/>
										</td>
									</tr>
								</>
							) )}
						</tbody>
					</Table>
					<ProfileAddressAddDialog cb={addAdress} />
					{/* take out a seprate component for address */}
				</Form>
			</FormProvider>
		</Modal.Body>
	)
} )

export default ServicesAddDialog
