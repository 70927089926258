import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { useFormContext } from "react-hook-form"
import { ReplaceOrderSchemaType } from "../../../services/CelebPatient.Service"
import { ToothSelectionComponent } from "../../utlity/quickSelection"
import { PatientOtherForm } from "./PatientFormFields"

const PatientTreatmentInstructionsTab = () => {
	return (
		<React.Suspense>
			<PatientTreatmentInstructionsTabImpl />
		</React.Suspense>
	)
}

const PatientTreatmentInstructionsTabImpl = () => {
	const methods = useFormContext<ReplaceOrderSchemaType>()

	return (
		<React.Fragment>
			<Form>
				{/* Upper Section */}
				<div className="d-flex justify-content-left">
					<section>
						<div className="d-flex gap-5">
							<div>
								<p style={{ color: "red", position: "relative" }}>
									{methods.formState.errors.root?.message}
								</p>
								<p style={{ color: "red", position: "relative" }}>
									{methods.formState.errors.replaceOrder?.root?.message}
								</p>

								{/* Upper Wires Selection */}
								<div className="d-flex gap-5">
									<p>
										Upper <b className="text-danger">*</b>
									</p>
									<Form.Check
										reverse
										{...methods.register( "replaceOrder.upperWires" )}
										value="AIW-1"
										label="AIW-1"
									/>
									<Form.Check
										reverse
										{...methods.register( "replaceOrder.upperWires" )}
										value="AIW-2"
										label="AIW-2"
									/>
									<Form.Check
										reverse
										{...methods.register( "replaceOrder.upperWires" )}
										value="AIW-3"
										label="AIW-3"
									/>
								</div>

								{/* Lower Wires Selection */}
								<div className="d-flex gap-5">
									<p>
										Lower <b className="text-danger">*</b>
									</p>
									<Form.Check
										reverse
										{...methods.register( "replaceOrder.lowerWires" )}
										value="AIW-1"
										label="AIW-1"
									/>
									<Form.Check
										reverse
										{...methods.register( "replaceOrder.lowerWires" )}
										value="AIW-2"
										label="AIW-2"
									/>
									<Form.Check
										reverse
										{...methods.register( "replaceOrder.lowerWires" )}
										value="AIW-3"
										label="AIW-3"
									/>
								</div>
							</div>
						</div>
					</section>
				</div>

				{/* Brackets and IDB Section */}
				<div className="d-flex gap-5 w-100 my-2">
					<p style={{ width: "8rem" }} className="align-items-center d-grid">
						Brackets and IDB
					</p>
					<div>
						<ToothSelectionComponent usedFor="teethReplacement" />
					</div>
					<p style={{ color: "red" }}>
						{methods.formState.errors.replaceOrder?.treatmentFiles?.message}
					</p>
				</div>

				{/* Instruction Text Area */}
				<div className="w-50">
					<PatientOtherForm name="replaceOrder" required label="Instruction">
						<Form.Control
							as="textarea"
							rows={6}
							{...methods.register( "replaceOrder.note", {
								required: "Instruction is required",
							} )}
						/>
						<p className="text-danger">{methods.formState.errors.replaceOrder?.note?.message}</p>
					</PatientOtherForm>
				</div>
			</Form>

			{/* Navigation Buttons */}
			<footer className="d-flex flex-row-reverse gap-2 position-absolute m-3 end-0 bottom-0">
				<Button onClick={() => ( window.location.hash = "#Photos" )}>
					<FontAwesomeIcon
						icon={faChevronRight}
						style={{ objectFit: "contain", width: "1rem", height: "1rem" }}
						className="m-0 p-0"
					/>
				</Button>
				<Button onClick={() => ( window.location.hash = "#Scan" )}>
					<FontAwesomeIcon
						icon={faChevronLeft}
						style={{ objectFit: "contain", width: "1rem", height: "1rem" }}
						className="m-0 p-0"
					/>
				</Button>
			</footer>
		</React.Fragment>
	)
}

export default PatientTreatmentInstructionsTab
