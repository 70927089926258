import { Nav } from "react-bootstrap"
import "../sidebar.css"
import SideNavbarInfoDummy from "./asidePatientInfo"

const NavbarNavigationDummy = () => {
	return (
		<div className="d-flex" style={{ height: "-webkit-fill-available" }}>
			<Nav variant="tabs" className="sidebar border-0">
				<Nav.Item className="active">Info</Nav.Item>
				<Nav.Item>Timeline</Nav.Item>
				<Nav.Item>Records</Nav.Item>
				<Nav.Item>Financials</Nav.Item>
				<Nav.Item>Instructions</Nav.Item>
			</Nav>
			<SideNavbarInfoDummy />
		</div>
	)
}

export default NavbarNavigationDummy
