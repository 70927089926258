import React from "react"
import { Outlet } from "react-router"
import { useSearchParams } from "react-router-dom"
import { PulseLoader } from "react-spinners"
import "../../../../assets/styles/components/celebServiceTabs.css"
import "../../../../assets/styles/components/navbar.css"
import "../../../../assets/styles/pages/landing.page.css"
import DashBoardNavbar from "../../../../components/navbars/DashBoardNavbar"
import SubNavBar from "../../../../components/navbars/SubNavBar"
import NavbarNavigationDummy from "../../../../components/navbars/sideNavbar/dummySidebar/navbarNavigation"
import SideNavbar from "../../../../components/navbars/sideNavbar/sideNavbar"
import SubNavbarSkelton from "../../../../skelton-components/subNavbar"

// Memoize Navbar components
const MemoizedDashBoardNavbar = React.memo( DashBoardNavbar )
const MemoizedSubNavBar = React.memo( SubNavBar )
const MemoizedNavbarNavigationDummy = React.memo( NavbarNavigationDummy )
const MemoizedSideNavbar = React.memo( SideNavbar )

const Dashboard = () => {
	const [ searchParams ] = useSearchParams()
	const patientId = searchParams.get( "PatientId" )

	return (
		<React.Suspense
			fallback={
				<PulseLoader
					color="#141e3b"
					cssOverride={{}}
					loading
					margin={20}
					size={15}
					speedMultiplier={2}
				/>
			}
		>
			<React.Suspense fallback={<SubNavbarSkelton />}>
				<MemoizedDashBoardNavbar />
			</React.Suspense>
			<main className="d-flex" style={{ height: "90vh" }}>
				{/* this is dashboard navbar */}
				<section
					style={{
						width: "67%",
						border: "5px solid #d9d9d9",
						borderRadius: "12px",
						scrollbarWidth: "none",
					}}
				>
					{/* this is dashboard subnavbar */}

					<React.Suspense fallback={<SubNavbarSkelton />}>
						<MemoizedSubNavBar />
					</React.Suspense>

					{/* this is dashboard content */}

					<React.Suspense fallback={<PulseLoader />}>
						<Outlet />
					</React.Suspense>
				</section>
				{/* this is sidebar */}
				<aside className="w-33" style={{ width: "33%", height: "90vh" }}>
					<React.Suspense fallback={<PulseLoader />}>
						{patientId ? <MemoizedSideNavbar /> : <MemoizedNavbarNavigationDummy />}
					</React.Suspense>
				</aside>
			</main>
		</React.Suspense>
	)
}

export default Dashboard
