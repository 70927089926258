import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Modal } from "react-bootstrap"
import { FormProvider, useForm } from "react-hook-form"
import { useLocation } from "react-router"
import { useSearchParams } from "react-router-dom"
import { ReplaceOrderSchema, ReplaceOrderSchemaType } from "../../../services/CelebPatient.Service"
import {
	getPatientByIdQuery,
	useGetReplacementOrderDetail,
} from "../../../services/CelebTreatment.Service"
import { getPatientTreatmentFilesQuery } from "../../../services/CelebTreatmentFiles.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { useTeethStore } from "../../../store/toothSelectionStore"
import { clearHash } from "../../utlity/GenricFunctions"
import { photosLibraryReset } from "../editPatients-v2/defaultFileShowCase"
import PatientCbctTab from "./PatientCBCT.tab"
import PatientInfoTab from "./PatientInfo.tab"
import PatientNavbar from "./PatientNavbar"
import PatientPhotosTab from "./PatientPhotos.tab"
import PatientScanTab from "./PatientScan.tab"
import PatientXrayTab from "./PatientXray.tab"
import PatientTreatmentInstructionTab from "./ReplacementInstructions.tab"

const PatientReplaceDialog = () => {
	const PatientDialog = usePatientDialogStore()
	const teethStore = useTeethStore()
	const clearHashClear = clearHash()
	const { data: defaultValuesEdit } = getPatientByIdQuery( PatientDialog.replaceOpen.id )
	const { data: defaultTreatmentFiles } = getPatientTreatmentFilesQuery(
		PatientDialog.replaceOpen.id,
	)
	const { data } = useGetReplacementOrderDetail( PatientDialog.replaceOpen.id )
	const {
		toggleToothBondingJsonFile,
		toggleToothExtractionJsonFile,
		toggleReplacementToothBondingJsonFile,
	} = useTeethStore()

	const methods = useForm<ReplaceOrderSchemaType>( {
		resolver: zodResolver( ReplaceOrderSchema ),
		mode: "all",
		defaultValues: {
			...defaultValuesEdit.patient,
			...defaultValuesEdit,
			replaceOrder: !PatientDialog.replaceOpen.isReplacementNew && {
				braces: data?.orders?.braces,
				note: data?.orders?.note,
				upperWires: data?.orders?.upperWires,
				lowerWires: data?.orders?.lowerWires,
				treatmentFiles: defaultTreatmentFiles,
			},
		},
	} )

	React.useEffect( () => {
		toggleToothBondingJsonFile( defaultValuesEdit.teethBonding )
		toggleToothExtractionJsonFile( defaultValuesEdit.teethExtraction )
		if ( data?.orders.braces && !PatientDialog.replaceOpen.isReplacementNew ) {
			toggleReplacementToothBondingJsonFile( data?.orders.braces )
			methods.setValue( "replaceOrder.upperWires", data?.orders?.upperWires )
			methods.setValue( "replaceOrder.lowerWires", data?.orders?.lowerWires )
			methods.setValue( "replaceOrder.note", data?.orders?.note ?? "" )
		}

		PatientDialog.replaceOpen == null
			? PatientDialog.setModalOpen( false )
			: PatientDialog.setModalOpen( true )
	}, [ data ] )

	const clearFormData = () => {
		methods.reset()
		teethStore.resetTeethSelection()
		PatientDialog.clearState()
		clearHashClear()
		photosLibraryReset()
	}
	return (
		<React.Fragment>
			<FormProvider {...methods}>
				<Modal show={PatientDialog.modalOpen} onHide={clearFormData} size="xl" backdrop="static">
					<PatientNavbar />
					<PatientAddImpl />
				</Modal>
			</FormProvider>
		</React.Fragment>
	)
}

const PatientAddImpl = () => {
	return (
		<React.Fragment>
			<main className="p-3">
				<ActiveTabExporter />
			</main>
		</React.Fragment>
	)
}

const ActiveTabExporter = () => {
	const location = useLocation()

	switch ( location.hash ) {
		case "#PatientInfo":
			return <PatientInfoTab />
		case "#Scan":
			return <PatientScanTab />
		case "#Replace":
			return <PatientTreatmentInstructionTab />
		case "#Photos":
			return <PatientPhotosTab />
		case "#X-rays":
			return <PatientXrayTab />
		case "#CBCT":
			return <PatientCbctTab />
		default:
			return <PatientInfoTab />
	}
}
export default PatientReplaceDialog
