import { faChevronLeft, faChevronRight, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { useFormContext } from "react-hook-form"
import ModalImage from "react-modal-image"
import addbutton from "../../../assets/img/add-button.svg"
import { CelebMedicalFileService } from "../../../services/CelebMedicalFile.Service"
import { ReplaceOrderSchemaType } from "../../../services/CelebPatient.Service"
import {
	fileUploadingType,
	PatientDialogState,
	usePatientDialogStore,
} from "../../../store/patientDailogStore"
import { EditTrash } from "../../utlity/EditTrash"

const PatientPhotosTab = () => {
	return (
		<React.Fragment>
			<ImageGrid />
			<footer className="position-absolute bottom-0 text-start end-0 m-3">
				<Button onClick={( e ) => ( window.location.hash = "#Replace" )}>
					<FontAwesomeIcon
						icon={faChevronLeft}
						style={{ objectFit: "contain", width: "1rem", height: "1rem" }}
						className="m-0 p-0"
					/>
				</Button>
				<Button className="ms-2" onClick={( e ) => ( window.location.hash = "#X-rays" )}>
					<FontAwesomeIcon
						icon={faChevronRight}
						style={{ objectFit: "contain", width: "1rem", height: "1rem" }}
						className="m-0 p-0"
					/>
				</Button>
			</footer>
		</React.Fragment>
	)
}

export const ImageGrid = () => {
	const { replaceOrderPhoto, setPatientReplaceOrderPhoto } = usePatientDialogStore()
	const methods = useFormContext<ReplaceOrderSchemaType>()
	const files: fileResult[] = (
		( methods.watch( "replaceOrder.treatmentFiles" ) as fileResult[] ) || []
	).filter( ( file ) => file?.fileCategory?.includes( "replace_order_photo" ) )
	const CreateExistingFiles = async ( fileInfo: fileResult ) => {
		const FileActualURL = await CelebMedicalFileService.getInstance()
			.getFileReadUrl( fileInfo?.fileUri )
			.then( ( res ) => res.data.data )
			.then( ( res ) => res )

		const blobData = await fetch( FileActualURL ).then( ( res ) => res.blob() )
		setPatientReplaceOrderPhoto( fileInfo?.fileSubCategory, {
			file: new File( [ blobData ], fileInfo?.originalFileName + "$%alreadyExist" ),
			additionalInfo: {
				note: fileInfo?.note,
			},
		} )
		return blobData
	}

	React.useEffect( () => {
		Promise.all(
			files.map( async ( x ) => {
				await CreateExistingFiles( x )
			} ),
		)
	}, [] )

	const handleAddMore = () => {
		const current = Object.keys( replaceOrderPhoto ).length
		setPatientReplaceOrderPhoto( `replace_order_photo_${current}`, {
			file: null,
			additionalInfo: {},
		} )
	}
	return (
		<React.Fragment>
			<section className="d-flex justify-content-center">
				<div>
					<span className="d-grid gap-3 my-2">
						{Object.keys( replaceOrderPhoto ).map(
							( _, index ) =>
								!replaceOrderPhoto[ `replace_order_photo_${index}` ]?.deleted && (
									<CardComponent
										key={index}
										index={index}
										property={`replace_order_photo_${index}`}
										file={replaceOrderPhoto[ `replace_order_photo_${index}` ]}
									/>
								),
						)}
					</span>
				</div>
			</section>
			<center>
				<Button onClick={handleAddMore}>Add More</Button>
			</center>
		</React.Fragment>
	)
}

const CardComponent = ( {
	property,
	file,
}: {
	index: number
	property: keyof PatientDialogState["replaceOrderPhoto"]
	file: fileUploadingType
} ) => {
	const imageRef = React.useRef<HTMLInputElement | null>( null )
	const { replaceOrderPhoto, setPatientReplaceOrderPhoto } = usePatientDialogStore()

	const fileImageSaver = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		e.preventDefault()
		if ( e.currentTarget.files ) {
			setPatientReplaceOrderPhoto( property, {
				file: e.currentTarget.files[ 0 ],
				additionalInfo: {
					note: replaceOrderPhoto[ property ]?.additionalInfo.note
						? replaceOrderPhoto[ property ]?.additionalInfo?.note
						: "",
				},
			} )
		}
	}

	const onRemove = () => {
		const tempData = {
			...replaceOrderPhoto,
		}
		tempData[ property ].deleted = true
		setPatientReplaceOrderPhoto( property, tempData[ property ] )
	}

	return (
		<div>
			<div className="d-flex align-items-baseline">
				<Form.Control
					type="file"
					accept="image/*"
					ref={imageRef}
					onChange={fileImageSaver}
					style={{
						border: "2px solid #ccc",
						borderRadius: "5px",
						width: "100%",
						cursor: "pointer",
					}}
				/>{" "}
				<Form.Control
					as="textarea"
					value={
						replaceOrderPhoto[ property ]?.additionalInfo?.note
							? replaceOrderPhoto[ property ]?.additionalInfo.note
							: ""
					}
					onChange={( e ) =>
						setPatientReplaceOrderPhoto( property, {
							file: replaceOrderPhoto[ property ]?.file ? replaceOrderPhoto[ property ]?.file : null,
							additionalInfo: { note: e.target.value },
						} )
					}
					rows={1}
					placeholder="Note..."
					className="mx-5"
					style={{ width: "100%", minWidth: "150px", maxWidth: "250px" }}
				></Form.Control>
				<div
					style={{
						width: "200px",
						maxHeight: "200px",
					}}
				>
					{file?.file ? (
						<ModalImage
							alt="Replace Order Photo"
							small={file.file && URL.createObjectURL( file?.file )}
							large={file.file && URL.createObjectURL( file?.file )}
							className="img-fluid"
							style={{
								maxWidth: "100%",
								maxHeight: "100%",
								objectFit: "contain",
							}}
						/>
					) : (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						></div>
					)}
				</div>
				<EditTrash
					editIcon={{ icon: faPencil, color: "#8faadc" }}
					trashIcon={{ icon: faTrash, color: "#d9d9d9", height: "3rem", width: "3rem" }}
					deleteCallBack={() => {
						onRemove()
					}}
					editShow={false}
				/>
			</div>
		</div>
	)
}

export const Addbutton = () => {
	return (
		<div
			className="card-img-overlay"
			style={{
				display: "flex",
				justifyContent: "end",
				height: "-webkit-fill-available",
				alignItems: "end",
				padding: "6px",
			}}
		>
			<img src={addbutton} alt="" />
		</div>
	)
}

export default PatientPhotosTab
export interface fileResult {
	id: string
	fileType: string
	fileUri: any
	note: string
	originalFileName: string
	fileCategory: string
	fileSubCategory: string
	patientId: string
	fileTreatmentPlanId: string
	doctorId: string
	deleted?: boolean
	createdAt: string
	updatedAt: string
}
