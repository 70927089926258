import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { Form, FormControlProps } from "react-bootstrap"
import { get, useFormContext } from "react-hook-form"
import { ReplaceOrderSchemaType } from "../../../services/CelebPatient.Service"

export const PatientInputForm = ( { name, label, required, ...props }: ServiceInputProps ) => {
	const { register, formState: errors } = useFormContext<ReplaceOrderSchemaType>()
	return (
		<Form.Group>
			<Form.Label>
				{label}
				{required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			<Form.Control {...props} isInvalid={get( errors.errors, name )} {...register( name )} />
			<p className="text-danger">
				{get( errors.errors[ name ], name )} {errors.errors[ name ]?.message}
			</p>
		</Form.Group>
	)
}

export const PatientSelectForm = ( { name, label, required, ...props }: ServiceInputProps ) => {
	const { register, formState: errors } = useFormContext<ReplaceOrderSchemaType>()
	return (
		<Form.Group>
			<Form.Label>
				{label}
				{required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			<Form.Select {...register( name )}>{props.children}</Form.Select>
			<p className="text-danger">
				{get( errors.errors[ name ], name )} {errors.errors[ name ]?.message}
			</p>
		</Form.Group>
	)
}

export const PatientOtherForm = ( { name, label, required, ...props }: ServiceInputProps ) => {
	const { formState: errors } = useFormContext<ReplaceOrderSchemaType>()
	return (
		<Form.Group>
			<Form.Label>
				{label}
				{required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			{props.children}
			<p className="text-danger">
				{get( errors.errors[ name ], name )} {errors.errors[ name ]?.message}
			</p>
		</Form.Group>
	)
}

export const PatientCheckForm = ( { name, label, required }: ServiceInputProps ) => {
	const { register, formState: errors } = useFormContext<ReplaceOrderSchemaType>()
	return (
		<Form.Group>
			<Form.Label>
				{label}
				{required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			<Form.Check {...register( name )}></Form.Check>
			<p className="text-danger">
				{get( errors.errors[ name ], name )} {errors.errors[ name ]?.message}
			</p>
		</Form.Group>
	)
}

export const PatientDateForm = ( {
	onChange,
	defaultValue,
	name,
	label,
	required,
	...props
}: ServiceInputProps ) => {
	const { watch, setValue, formState: errors } = useFormContext<ReplaceOrderSchemaType>()
	return (
		<Form.Group>
			<Form.Label className="mb-1">
				{label}
				{required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			<Form.Group>
				<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ISO 8601">
					<DesktopDatePicker
						sx={{
							minWidth: "355px",
							"& .MuiInputBase-root": {
								height: "40px",
								padding: "4px",
							},
							"& .MuiOutlinedInput-root": {
								height: "37px",
							},
							"& .MuiIconButton-root": {
								paddingRight: "15px",
							},
						}}
						format="MM-DD-YYYY"
						onChange={( e ) => {
							if ( e && dayjs( e ).isValid() ) {
								const isoDate = dayjs.utc( e ).startOf( "day" ).toISOString()
								setValue( "dob", isoDate )
							} else {
								console.error( "Invalid date selected:", e )
							}
						}}
						value={watch( "dob" ) ? dayjs( watch( "dob" ) ) : null}
					/>
				</LocalizationProvider>
			</Form.Group>
			<p className="text-danger">
				{get( errors.errors[ name ], name )} {errors.errors[ name ]?.message}
			</p>
		</Form.Group>
	)
}

export type ServiceInputProps = Omit<FormControlProps, "name"> & {
	name: keyof ReplaceOrderSchemaType
	label?: string
	required?: boolean
	error?: string
}

export default PatientInputForm
