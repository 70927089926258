import { Button } from "react-bootstrap"
import "./generic.css"
interface PaginationType {
	total: number
	onChange: ( pageNumber: number ) => void
	currentPage: number
}
const CustomPagination = ( { currentPage, onChange, total }: PaginationType ) => {
	return (
		<div className="p-1 custom-paginators">
			<span
				style={{
					textAlign: "center",
					display: "grid",
					placeItems: "center",
				}}
			>
				<p style={{ margin: 0 }}>
					{total > 0 ? (
						<>
							{currentPage * 10 + 1}-
							{Math.floor( total / 10 ) <= currentPage ? total : ( currentPage + 1 ) * 10} out of{" "}
							{total}
						</>
					) : (
						"0-0 out of 0"
					)}
				</p>
			</span>

			<span className="d-flex gap-3">
				<Button
					disabled={currentPage <= 0}
					onClick={() => currentPage >= 0 && onChange( currentPage - 1 )}
				>
					Prev
				</Button>
				<Button
					disabled={Math.floor( total / 10 ) <= currentPage}
					onClick={() => onChange( currentPage + 1 )}
				>
					Next
				</Button>
			</span>
		</div>
	)
}

export default CustomPagination
