import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import { zodResolver } from "@hookform/resolvers/zod"
import React, { useEffect } from "react"
import { Alert, Button, Form, Modal, Nav, Navbar, Spinner, Table } from "react-bootstrap"
import { FieldErrors, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form"
import { useLocation } from "react-router-dom"
import "../../../assets/styles/components/celebAddServiceDialog.css"
import LoadingOverlay from "../../../assets/styles/components/loader/loadingOverlay"
import { useCustomErrorMarkerWithService } from "../../../hooks/patient/customErrorMarker"
import { constants } from "../../../services/CelebApiConstants"
import {
	ServiceAddress,
	ServiceSchemaForm,
	useCategoryfetchDetails,
	useEditServiceMutation,
	useServicefetchDetailsMutate,
} from "../../../services/CelebService.Service"
import { useProfileDialogStore } from "../../../store/profileDialogStore"
import { useServiceDialogStore } from "../../../store/serviceDialogStore"
import { TaskCategory } from "../../../utils/appConstants"
import { EditTrash } from "../../utlity/EditTrash"
import { infoMapper } from "../../utlity/GenricFunctions"
import { uploadLogo } from "../../utlity/uploadFiles"
import ProfileAddressAddDialog from "../profile-v2/ProfileAddressAdd.dialog"
import { ServiceFormInput, ServiceFormType } from "./serviceFunctions/ServiceFormFields"
import { ServicePricingSetter } from "./servicePages/servicePricingSetter"
import ServiceTaskBox, { labelFinder } from "./ServiceTaskForm"

const ServicesEditdialog = React.memo( () => {
	const serviceStore = useServiceDialogStore()
	const modalShowCase = serviceStore.editOpen === null ? false : true
	const serviceId = serviceStore.editOpen
	const { mutate: serviceCall, data: serviceData, isPending } = useServicefetchDetailsMutate()
	const methods = useForm<ServiceFormType>( {
		resolver: zodResolver( ServiceSchemaForm ),
		mode: "all",
		defaultValues: {
			...serviceData?.services,
			status: serviceData?.services?.isActive,
		},
	} )
	useEffect( () => {
		serviceCall( serviceId! )
	}, [] )

	const tasksData = useFieldArray( { name: "tasks", control: methods.control, keyName: "uid" } )

	React.useEffect( () => {
		serviceStore.hasMapped == false && tasksData.fields.length >= 1 && !isPending && setMapping()
	}, [ tasksData.fields, isPending ] )

	const setMapping = async () => {
		await editServiceTasksMapping()
		serviceStore.setHasMapped( true )
	}

	const editServiceTasksMapping = React.useCallback( async () => {
		await Promise.all(
			tasksData.fields.map( async ( taskObject, index ) => {
				// Handling previousTasks asynchronously
				const previousTasks = await Promise.all(
					taskObject.previousTasks?.map( async ( id ) => {
						const data = await labelFinder( tasksData.fields, id )
						if ( data && typeof id === "string" ) {
							return {
								label: data,
								value: id,
							}
						}
					} ) || [],
				)

				// Handling rollBackToTask asynchronously
				const rollBackToTask = await Promise.all(
					taskObject.rollBackToTask?.map( async ( id ) => {
						const data = await labelFinder( tasksData.fields, id )
						return {
							label: data,
							value: id,
						}
					} ) || [],
				)
				const tasksDataObj = {
					...taskObject,
					previousTasks: previousTasks.filter( Boolean ),
					rollBackToTask: rollBackToTask.filter( Boolean ),
					serviceId: serviceId,
					hasCustomMessage: taskObject.hasCustomMessage,
				}

				tasksData.update( index, tasksDataObj )
				return tasksDataObj
			} ),
		)

		// You can do something with tasksDataArray if needed
	}, [ tasksData.fields ] )

	const { data: categoryData } = useCategoryfetchDetails( serviceId! )
	const getCategory: { [key: string]: ReactSelectCreatableCustom[] } = {
		provide: serviceStore.serviceProviderCategories,
		consume: serviceStore.serviceConsumerCategories,
	}

	const createCategory: { [key: string]: serviceStoreCreateCallback } = {
		provide: serviceStore.setServiceProviderCategories,
		consume: serviceStore.setServiceConsumerCategories,
	}

	if ( serviceStore.serviceProviderCategories.length == 0 ) {
		categoryData.forEach( ( x: categoryResponseType ) => {
			const dataToCheck = getCategory[ x.type ].filter(
				( y: ReactSelectCreatableCustom ) => y.label === x.name,
			)
			if ( dataToCheck?.length >= 1 ) return
			createCategory[ x.type ]( {
				value: x.id,
				type: x.type,
				label: x.name,
			} )
		} )
	}

	React.useEffect( () => {
		methods.reset( {
			...serviceData?.services,
			status: serviceData?.services?.isActive,
		} )
	}, [ serviceData ] )

	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<FormProvider {...methods}>
				<Modal show={modalShowCase} size="xl" backdrop="static" animation={true}>
					<ServiceNavbar />
					<ServiceEditImpl />
				</Modal>
			</FormProvider>
		</React.Suspense>
	)
} )

const ServiceEditImpl = React.memo( () => {
	return (
		<React.Fragment>
			<main className="p-3">
				<TabShowCase />
			</main>
		</React.Fragment>
	)
} )

const TabShowCase = React.memo( () => {
	const location = useLocation()
	switch ( location.hash ) {
		case "#ServiceInfo":
			return <ServicesEditDetialsImpl />
		case "#Tasks":
			return <ServiceTaskBox />
		default:
			return <ServicesEditDetialsImpl />
	}
} )

//repeated
const ServiceNavbar = React.memo( () => {
	const locationHash = useLocation()
	const serviceStore = useServiceDialogStore()
	const methods = useFormContext<ServiceFormType>()
	const { mutate: serviceEditSave } = useEditServiceMutation()
	const { customErrorMarker } = useCustomErrorMarkerWithService()
	const serviceId = serviceStore.editOpen
	const saveEditedService = async ( value: ServiceFormType ) => {
		if ( serviceStore.isCyclicDependancy ) {
			return ( window.location.href = "#Tasks" )
		}
		const logo = value.logo as FileList
		const logoUrl = typeof value.logo == "object" ? await uploadLogo( logo ) : value.logo
		const formObj = {
			...value,
			categories: [
				...serviceStore.serviceConsumerCategories,
				...serviceStore.serviceProviderCategories,
			],
			logo: logoUrl,
		}
		serviceEditSave( { serviceId, requestPayload: formObj } )
		serviceStore.setEditOpen( null )
		methods.reset( {
			name: "",
		} )
		serviceStore.resetTaskForms()
	}
	const closeService = () => {
		methods.reset()
		serviceStore.setEditOpen( null )
		serviceStore.updateserviceConsumerCategories( [] )
		serviceStore.updateserviceProviderCategories( [] )
		serviceStore.resetTaskForms()
	}

	const onSubmitError = ( errors2: FieldErrors<ServiceFormType> ) => {
		if ( customErrorMarker() ) return null
		console.error( { errors2 } )
		methods.setValue( "taskErrors", errors2.tasks?.message )
	}

	return (
		<React.Fragment>
			<FormProvider {...methods}>
				<Navbar id="navbarScroll" as={Modal.Header}>
					<Nav variant="tabs" defaultActiveKey="#ServiceInfo" className="d-flex mt-2 me-auto">
						<Nav.Link className="" active={locationHash.hash == "#ServiceInfo"} href="#ServiceInfo">
							Edit Service
						</Nav.Link>
						<Nav.Link
							disabled={!serviceStore.hasMapped}
							active={locationHash.hash == "#Tasks"}
							href="#Tasks"
						>
							Tasks
						</Nav.Link>
					</Nav>
					<span>
						<Button
							type="submit"
							disabled={!serviceStore.hasMapped}
							onClick={methods.handleSubmit( saveEditedService, onSubmitError )}
						>
							{methods.formState.isSubmitting ? (
								<>
									<Spinner animation="border" role="status" size="sm" /> Saving..{" "}
								</>
							) : (
								"Save"
							)}
						</Button>
						<Button variant="secondary" className="mx-2" onClick={closeService}>
							Cancel
						</Button>
					</span>
				</Navbar>
			</FormProvider>
		</React.Fragment>
	)
} )

//repeated
const ServicesEditDetialsImpl = React.memo( () => {
	const methods = useFormContext<ServiceFormType>()
	const serviceData = methods.watch()
	const profileStore = useProfileDialogStore()
	const addAdress = ( address: ServiceAddress ) => {
		methods.setValue( "address", address )
	}
	const DeleteAdress = ( index: number ) => {
		return methods.setValue( `address.${index}.deleted`, true )
	}
	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<Modal.Body>
				<FormProvider {...methods}>
					<Form>
						<span className="d-inline-flex justify-content-evenly w-100">
							<div className="justify-content-center">
								<Form.Label>Service ID</Form.Label>
								<br />
								<Form.Label>{serviceData?.entityId}</Form.Label>
							</div>
							<ServiceFormInput label="Service Name" name="name" />
							<div>
								<Form.Label>Service Logo</Form.Label>
								<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
									<Form.Control
										id="logo-upload"
										type="file"
										accept="image/*"
										onChange={async ( e: React.ChangeEvent<HTMLInputElement> ) => {
											if ( e.currentTarget?.files ) {
												const logoUrl = await uploadLogo( e.currentTarget?.files )
												methods.setValue( "logo", logoUrl )
											}
										}}
										hidden
									/>
									<label htmlFor="logo-upload">
										<img
											src={constants.IMAGEURL + methods.watch( "logo" )}
											style={{
												objectFit: "contain",
												width: "2.5rem",
												height: "2.5rem",
												cursor: "pointer",
												borderRadius: "5px",
											}}
											alt="Selected Logo"
											onError={( { currentTarget } ) => {
												currentTarget.onerror = null
												currentTarget.src = constants.IMAGEURL + "building.png"
											}}
										/>
									</label>
								</div>
							</div>
							<ServiceFormInput
								label="Contact No"
								name="contactNo"
								placeholder="Contact No"
								type="number"
							/>
							<ServiceFormInput label="Email" name="email" placeholder="Email" />
							<div>
								<Form.Label>Service Status</Form.Label>
								<Form.Check type="checkbox" {...methods.register( "status" )} label={"Make Active"} />
							</div>
						</span>
						<span className="d-inline-flex justify-content-evenly w-100">
							<ServicePricingSetter
								name="Price Upper"
								formControlAccess={{
									...methods.register( "priceUpper", {
										valueAsNumber: true,
										onChange: ( e ) => {
											methods.setValue( "priceUpper", parseFloat( e.currentTarget.value ) )
										},
									} ),
								}}
								error={!!methods.formState.errors.priceUpper}
								errorMessage={methods.formState.errors.priceUpper?.message}
							/>
							<ServicePricingSetter
								name="Price Lower"
								formControlAccess={{
									...methods.register( "priceLower", {
										valueAsNumber: true,
										onChange: ( e ) => {
											methods.setValue( "priceLower", parseFloat( e.currentTarget.value ) )
										},
									} ),
								}}
								error={!!methods.formState.errors.priceLower}
								errorMessage={methods.formState.errors.priceLower?.message}
							/>
							<ServicePricingSetter
								name="Price Both"
								formControlAccess={{
									...methods.register( "priceBoth", {
										valueAsNumber: true,
										onChange: ( e ) => {
											methods.setValue( "priceBoth", parseFloat( e.currentTarget.value ) )
										},
									} ),
								}}
								error={!!methods.formState.errors.priceBoth}
								errorMessage={methods.formState.errors.priceBoth?.message}
							/>
							<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								<Button
									onClick={() => {
										profileStore.setCreateOpen( true )
									}}
								>
									Add Address
								</Button>
							</div>
						</span>
					</Form>
					<Alert hidden={!methods.formState.errors.tasks?.message} variant="warning">
						{methods.formState.errors.tasks?.message}
					</Alert>
					<Table striped bordered hover className="my-1">
						<thead>
							<tr>
								<th>Office Name</th>
								<th>Address</th>
								<th>Modify</th>
							</tr>
						</thead>
						<tbody>
							{serviceData.address?.map( ( add, index ) => (
								<tr hidden={add.deleted}>
									<td>{add?.name}</td>
									<td>
										{infoMapper( add, [
											"id",
											"name",
											"deleted",
											"deletedAt",
											"deletedBy",
											"doctorId",
											"category",
											"createdAt",
											"updatedAt",
										] )}
									</td>
									<td className="text-end">
										<EditTrash
											editIcon={{ icon: faPencil, color: "#8faadc" }}
											trashIcon={{ icon: faTrash, color: "#d9d9d9" }}
											deleteCallBack={() => {
												DeleteAdress( index )
											}}
											editCallback={() => {
												profileStore.setEditOpen( add )
											}}
										/>
									</td>
								</tr>
							) )}
						</tbody>
					</Table>
					<ProfileAddressAddDialog cb={addAdress} />
				</FormProvider>
			</Modal.Body>
		</React.Suspense>
	)
} )

export type ReactSelectCreatableCustom = {
	value: string
	label: string
	type: string
	edited?: boolean
	show?: TaskCategory.PROVIDER | "consumer" | string
}
type serviceStoreCreateCallback = ( next: ReactSelectCreatableCustom ) => void
export interface categoryResponseType {
	id: string
	name: string
	type: string
	deleted: boolean
	deletedBy: any
	deletedOn: any
	serviceId: string
	createdAt: string
	updatedAt: string
}

export default ServicesEditdialog
