import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Button, Form, Modal, Nav, Navbar, Spinner } from "react-bootstrap"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import Select from "react-select"
import {
	RoleCreationSchema,
	RoleCreationSchemaType,
	SpecialRoleEnumType,
	useCurrentRoleDetailsQuery,
	useEditRoleMutaion,
	useGetRoleMutaion,
	useListRolesQuery,
} from "../../../services/CelebRole.Service"
import { TaskPermissionType, useAccountsDialogStore } from "../../../store/accountsDialogStore"
import { clearHash, sortForReactSelect, SortReactSelectType } from "../../utlity/GenricFunctions"
import { BoundControlTypes } from "./AccountFormFields"
import { ExpandableNavbarTabs } from "./AccountTypesAdd.dialog"

const AccountTypesEditDialog = () => {
	const methods = useForm<RoleCreationSchemaType>({
		resolver: zodResolver(RoleCreationSchema),
		mode: "all",
	})
	const { typesEditOpen, setTypesEditOpen, resetState } = useAccountsDialogStore()
	const clearHashClear = clearHash()
	const modalShowCase = typesEditOpen ? true : false
	const dialogClose = () => {
		resetState()
		clearHashClear()
		methods.reset()
		setTypesEditOpen(null)
	}
	return (
		<React.Suspense>
			<FormProvider {...methods}>
				<Modal show={modalShowCase} size="xl" onHide={dialogClose}>
					<Navbar style={{ backgroundColor: "#d9d9d9" }} as={Modal.Header} closeButton>
						<Nav>
							<Nav.Link className="nav-link-opposite mt-1" active={true}>
								Edit Account Type
							</Nav.Link>
						</Nav>
					</Navbar>

					<AccountTabImpl />
				</Modal>
			</FormProvider>
		</React.Suspense>
	)
}

const AccountTabImpl = () => {
	const method = useFormContext<RoleCreationSchemaType>()
	const {
		data: { data: roles },
	} = useListRolesQuery()
	const { setPermissions, setGenericPermissions, setServiceOpted, typesEditOpen, resetState } =
		useAccountsDialogStore()
	const [rolesOption, setRolesOption] = React.useState<SortReactSelectType>([])
	const { mutateAsync: getRoleInformation } = useGetRoleMutaion()
	const { mutateAsync: getData, data: accountTypeInformation } = useCurrentRoleDetailsQuery(
		typesEditOpen + "",
	)
	const { mutateAsync: updateRole } = useEditRoleMutaion()
	React.useEffect(() => {
		getAccountTypeData()
		setRolesOption(sortForReactSelect(roles, "id", "name"))
		typesEditOpen ? setRoleInformatopm(typesEditOpen) : null
	}, [])

	const getAccountTypeData = async () => {
		const data = await getData()
		method.setValue("name", data?.name)
		method.setValue("status", data?.status)
		method.setValue("specialRole", data?.specialRole as SpecialRoleEnumType)
	}

	const setRoleInformatopm = async (value: string) => {
		const data = await getRoleInformation(value)
		const services: string[] = []
		const mappedPermissions = data.taskPermissions.map((item: TaskPermissionType) => {
			services.includes(item.roleTaskPermissionTask.serviceId) ||
				services.push(item.roleTaskPermissionTask.serviceId)
			return {
				taskId: item.taskId,
				permission: item.permission,
				serviceId: item.roleTaskPermissionTask.serviceId,
			}
		})

		const genericPermissionsMapped = data.genericPermissions.map(
			(item: { genericPermissionId: string; permission: string }) => ({
				permissionId: item.genericPermissionId,
				permission: item.permission,
			}),
		)

		setServiceOpted(services)
		setPermissions(mappedPermissions)
		setGenericPermissions(genericPermissionsMapped)
	}

	const onSave = (value: RoleCreationSchemaType) => {
		updateRole({ roleId: typesEditOpen, value: value })
		resetState()
	}

	return (
		<React.Suspense>
			<Modal.Body>
				{accountTypeInformation ? (
					<Form onSubmit={method.handleSubmit(onSave)}>
						<section className="d-flex w-100 justify-content-between mb-3">
							<span className="w-25">
								<BoundControlTypes name="name" label="Account Role Name" />{" "}
							</span>
							<span className="w-25">
								<Form.Label>Start From</Form.Label>
								<div style={{ zIndex: 101, position: "relative" }}>
									<Select
										options={rolesOption}
										onChange={(value) => setRoleInformatopm(value?.value)}
									></Select>
								</div>
							</span>

							<span className="auto">
								<Form.Label>Account Type</Form.Label>
								<Form.Select
									defaultValue={method.getValues("specialRole")}
									onChange={(e) =>
										method.setValue("specialRole", e.currentTarget.value as SpecialRoleEnumType)
									}
								>
									<option value="doctor">Doctor</option>
									<option value="none">Operator</option>
									<option value="sales">Sales</option>
								</Form.Select>
							</span>
							<span>
								<Form.Label>Account Status</Form.Label>
								<Form.Check
									defaultChecked={method.getValues("status")}
									label={"Active"}
									onChange={(e) => method.setValue("status", e.currentTarget.checked)}
								/>
							</span>
							<span>
								<Button className="mt-4" type="submit">
									Update Account Type
								</Button>
							</span>
						</section>

						<ExpandableNavbarTabs />
					</Form>
				) : (
					<Spinner />
				)}
			</Modal.Body>
		</React.Suspense>
	)
}

export default AccountTypesEditDialog
