import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { useFormContext } from "react-hook-form"
import { PatientSchemaType } from "../../../services/CelebPatient.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { useTeethStore } from "../../../store/toothSelectionStore"
import { QuickSelection, ToothSelectionComponent } from "../../utlity/quickSelection"
import { PatientOtherForm } from "./PatientFormFields"

const PatientTreatmentInstructionsTab = () => {
	return (
		<React.Suspense>
			<PatientTreatmentInstructionsTabImpl />
		</React.Suspense>
	)
}

const PatientTreatmentInstructionsTabImpl = () => {
	const methods = useFormContext<PatientSchemaType>()
	const { extractionSelection, setExtractionSelection } = usePatientDialogStore()
	const { resetUpperTeethSelection, teethExtraction } = useTeethStore()

	//could be used directly with method watch
	const [ braceSelection, setbraceSelection ] = React.useState( {
		upperBracketSlotSize: methods.watch( "treatmentPlan.upperBracketSlotSize" ) == "" ? true : false,
		lowerBracketSlotSize: methods.watch( "treatmentPlan.lowerBracketSlotSize" ) == "" ? true : false,
		extraction: true,
		toothBonding: false,
	} )

	React.useEffect( () => {
		const teethExtractionValues = Object.values( teethExtraction )
		const extractionSelected = teethExtractionValues.some( ( value ) => value === "selected" )
		setExtractionSelection( extractionSelected )
		setbraceSelection( ( prevState ) => {
			return {
				...prevState,
				extraction: extractionSelected,
			}
		} )
	}, [ teethExtraction ] )

	return (
		<React.Fragment>
			<Form>
				<div>
					{/* upperSection */}
					<div className="d-flex justify-content-around align-items-center">
						{
							<section>
								<div className="d-flex gap-5">
									<div>
										<div className="d-flex gap-5">
											<p>
												Upper <b className="text-danger">*</b>
											</p>
											<Form.Check
												reverse
												type="radio"
												label="Celebrace"
												defaultChecked={methods.watch( "treatmentPlan.upperBracketSlotSize" ) !== ""}
												name="upperBrace"
												onChange={() => {
													methods.setValue( "treatmentPlan.upperBracketSlotSize", "0.018" )
													setbraceSelection( { ...braceSelection, [ "upperBracketSlotSize" ]: false } )
													resetUpperTeethSelection( "U" )
												}}
											/>
											<Form.Check
												reverse
												type="radio"
												label="No treatment"
												name="upperBrace"
												checked={methods.watch( "treatmentPlan.upperBracketSlotSize" ) === ""}
												onChange={() => {
													methods.setValue( "treatmentPlan.upperBracketSlotSize", "" )
													setbraceSelection( { ...braceSelection, [ "upperBracketSlotSize" ]: true } )
													resetUpperTeethSelection( "U" )
												}}
											/>
										</div>
										{/* <p className="text-danger">
											{methods.formState.errors.treatmentPlan?.lowerBracketSlotSize?.message}
										</p>
										{braceSelection.upperBracketSlotSize || (
											<div className="d-flex gap-5">
												<p>
													Bracket Slot Size
													<b className="text-danger">*</b>
												</p>

												<Form.Check
													reverse
													type="radio"
													{...methods.register( "treatmentPlan.upperBracketSlotSize" )}
													value="0.022"
													label="0.022"
												/>
												<Form.Check
													reverse
													type="radio"
													{...methods.register( "treatmentPlan.upperBracketSlotSize" )}
													label="0.020"
													value="0.020"
												/>
												<Form.Check
													reverse
													type="radio"
													{...methods.register( "treatmentPlan.upperBracketSlotSize" )}
													label="0.018"
													value="0.018"
												/>
											</div>
										)} */}
									</div>
								</div>
								<div className="d-flex gap-5">
									<div>
										<div className="d-flex gap-5">
											<p>
												Lower <b className="text-danger">*</b>
											</p>
											<Form.Check
												reverse
												type="radio"
												label="Celebrace"
												defaultChecked={methods.watch( "treatmentPlan.lowerBracketSlotSize" ) !== ""}
												name="lowerBracketSlotSize"
												onChange={() => {
													methods.setValue( "treatmentPlan.lowerBracketSlotSize", "0.018" )
													setbraceSelection( { ...braceSelection, [ "lowerBracketSlotSize" ]: false } )
													resetUpperTeethSelection( "L" )
												}}
											/>
											<Form.Check
												reverse
												type="radio"
												label="No treatment"
												checked={methods.watch( "treatmentPlan.lowerBracketSlotSize" ) === ""}
												name="lowerBracketSlotSize"
												onChange={() => {
													methods.setValue( "treatmentPlan.lowerBracketSlotSize", "" )
													setbraceSelection( { ...braceSelection, [ "lowerBracketSlotSize" ]: true } )
													resetUpperTeethSelection( "L" )
												}}
											/>
										</div>
										{/* <p className="text-danger">
											{methods.formState.errors.treatmentPlan?.lowerBracketSlotSize?.message}
										</p>
										{braceSelection.lowerBracketSlotSize || (
											<div className="d-flex gap-5">
												<p>
													Bracket Slot Size
													<b className="text-danger">*</b>
												</p>
												<Form.Check
													reverse
													type="radio"
													defaultChecked
													{...methods.register( "treatmentPlan.lowerBracketSlotSize" )}
													label="0.022"
													value="0.022"
												/>
												<Form.Check
													reverse
													type="radio"
													{...methods.register( "treatmentPlan.lowerBracketSlotSize" )}
													label="0.020"
													value="0.020"
												/>
												<Form.Check
													reverse
													type="radio"
													{...methods.register( "treatmentPlan.lowerBracketSlotSize" )}
													label="0.018"
													value="0.018"
												/>
											</div>
										)} */}
									</div>
								</div>
							</section>
						}
						<div className="w-50">
							<PatientOtherForm name="treatmentPlan" required label="Instruction">
								<Form.Control
									as="textarea"
									rows={6}
									{...methods.register( "treatmentPlan.instruction" )}
								/>
								<p className="text-danger">
									{methods.formState.errors.treatmentPlan?.instruction?.message}
								</p>
							</PatientOtherForm>
						</div>
					</div>
					{/* lowerSection */}
					<div className="px-2">
						<div className="d-flex gap-5">
							<Form.Check
								reverse
								type="radio"
								label="Non-Ext"
								name="Extraction"
								checked={!extractionSelection}
								onChange={() => {
									setbraceSelection( { ...braceSelection, [ "extraction" ]: true } )
									setExtractionSelection( false )
								}}
							/>
							<Form.Check
								reverse
								type="radio"
								label="Extraction"
								name="Extraction"
								checked={extractionSelection}
								onChange={() => {
									setbraceSelection( { ...braceSelection, [ "extraction" ]: false } )
									setExtractionSelection( true )
								}}
							/>
						</div>
						<div className="d-flex gap-5 w-100 ml-2">
							{!extractionSelection || (
								<p style={{ width: "7rem" }} className="align-items-center d-grid">
									Extraction
								</p>
							)}
							<div>
								{!extractionSelection || (
									<ToothSelectionComponent usedFor="extraction" braceSelection={braceSelection} />
								)}
							</div>
						</div>
						{/* Boonding Teeth */}
						<div className="d-flex gap-5 w-100 mt-4">
							<p style={{ width: "7rem" }} className="align-items-center d-grid">
								Tooth Bonding
							</p>
							<div>
								{braceSelection.toothBonding || (
									<ToothSelectionComponent usedFor="bonding" braceSelection={braceSelection} />
								)}
							</div>
							<div className="align-items-end d-grid">
								<QuickSelection />
							</div>
						</div>
						<p className="text-danger textw" style={{ marginInline: "15%" }}>
							{methods.formState.errors.treatmentPlan?.root?.message}
						</p>
					</div>
				</div>
			</Form>
			<footer className="d-flex flex-row-reverse gap-2 position-absolute m-3 end-0 bottom-0 ">
				<Button onClick={( e ) => ( window.location.hash = "#Photos" )}>
					<FontAwesomeIcon
						icon={faChevronRight}
						style={{ objectFit: "contain", width: "1rem", height: "1rem" }}
						className="m-0 p-0"
					/>
				</Button>
				<Button onClick={( e ) => ( window.location.hash = "#Scan" )}>
					<FontAwesomeIcon
						icon={faChevronLeft}
						style={{ objectFit: "contain", width: "1rem", height: "1rem" }}
						className="m-0 p-0"
					/>
				</Button>
			</footer>
		</React.Fragment>
	)
}

export default PatientTreatmentInstructionsTab
