/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { useSearchParams } from "react-router-dom"
import { JawType } from "../../utils/appConstants"

export const sortForReactSelect = (
	listOption: {}[],
	id: string,
	label: string,
	extraLabel?: string | undefined,
) => {
	return Array.isArray(listOption)
		? listOption.map((x: any) => {
				const secondLabel = extraLabel ? x[extraLabel] : ""
				return {
					value: x[id],
					label: x[label] + " " + secondLabel,
				}
			})
		: []
}

export const loadingProgressBar = (loadingPercentage: number) => {
	sessionStorage.setItem("loading", loadingPercentage + "")
}

export const clearHash = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	return React.useCallback(() => {
		if (window.location.hash) {
			window.location.hash = ""
			const newParams = new URLSearchParams(searchParams)
			setSearchParams(newParams)
		}
	}, [searchParams, setSearchParams])
}

export const fetchImageAsBlob = async (url: string) => {
	const response = await fetch(url)
	if (!response.ok) {
		throw new Error("Failed to fetch image")
	}
	const blob = await response.blob()
	const fileName = url?.split("/").pop() || "unknown"
	const file = new File([blob], fileName, { type: blob.type })

	return file
}
export const infoMapper = (data: { [key: string]: any }, excludeKeys: string[] = []) => {
	if (data == null) return ""
	const formattedData = Object.entries(data)
		.filter(([key, value]) => value != null && value !== "null" && !excludeKeys.includes(key))
		.map(([key, value]) => value)
		.join(", ")
	return formattedData
}

export const infoMapperNames = (data: { [key: string]: any }, excludeKeys: string[] = []) => {
	if (data == null) return ""
	const formattedData = Object.entries(data)
		.filter(([key, value]) => value !== null && value !== undefined && !excludeKeys.includes(key))
		.map(([key, value]) => value)
		.join(" ")

	return formattedData
}

export const formatletters = (type: string) => {
	if (!type) return ""
	return type.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
}

export const fileReadableName = (name: string): string => {
	return name
		.replace(/patient/gi, "")
		.replace(/cbct/gi, "CBCT")
		.replace(/xray_skull/gi, "lateral_ceph")
		.replace(/xray_teeth/gi, "panoramic_xray")
		.replace(/_/g, " ")
		.trim()
		.replace(/\b\w/g, (char) => char.toUpperCase())
}

export const clearFormState = (keys: string[], actionFunction: any) => {
	if (keys) {
		Object.keys(keys).forEach((x: any) => {
			setTimeout(() => {
				actionFunction(x)
			}, 10)
		})
	}
}

export type SortReactSelectType = {
	value: any
	label: string
}[]

export const descriptionMap: { [key in JawType]: string } = {
	[JawType.BOTH]: "Upper and Lower Both Braces",
	[JawType.LOWER]: "Lower Braces",
	[JawType.UPPER]: "Upper Braces",
}

// flattenObject will convert nested object to flat object
export const flattenObject = (obj: any, prefix = ""): { [key: string]: any } => {
	const result: { [key: string]: any } = {}
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const newKey = prefix ? `${prefix}.${key}` : key

			if (typeof obj[key] === "object" && obj[key] !== null) {
				if (Array.isArray(obj[key])) {
					obj[key].forEach((item, index) => {
						Object.assign(result, flattenObject(item, `${newKey}[${index}]`))
					})
				} else {
					Object.assign(result, flattenObject(obj[key], newKey))
				}
			} else {
				result[newKey] = obj[key]
			}
		}
	}

	return result
}
