import { zodResolver } from "@hookform/resolvers/zod"
import React, { Suspense } from "react"
import { Alert, Button, Col, Form, Modal, Nav, Navbar, Row } from "react-bootstrap"
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form"
import Select from "react-select"
import { OverlaySpinner } from "../../../assets/styles/components/loader/loadingOverlay"
import "../../../assets/styles/pages/Theme.form.css"
import { queryClient } from "../../../providers/QueryProvider"
import { ResponseTypeRole, useListRolesQuery } from "../../../services/CelebRole.Service"
import { getSalesRepresentativesApi } from "../../../services/CelebSalesRepresentative.service"
import { useListServicesQuery } from "../../../services/CelebService.Service"
import {
	CreateUserSchema,
	CreateUserType,
	useCreateUserMutation,
	useListUsersQuery,
} from "../../../services/CelebUser.Service"
import { useAccountsDialogStore } from "../../../store/accountsDialogStore"
import { userSessionStore } from "../../../store/userSessionStore"
import CloseDialog from "../../utlity/CloseDialog"
import { clearFormState, clearHash, sortForReactSelect } from "../../utlity/GenricFunctions"
import {
	BoundCheckbox,
	BoundControl,
	BoundControlPricing,
	BoundSelect,
	DoctorSpecificInput,
	SelectOption,
	Submit,
} from "./AccountFormFields"

export const AccountsAddDialog = () => {
	const accountStore = useAccountsDialogStore()
	const createOpen = useAccountsDialogStore((state) => state.createOpen)

	return (
		<Modal show={createOpen} size="xl" backdrop="static">
			<Navbar className="d-flex me-auto" style={{ backgroundColor: "#d9d9d9" }} as={Modal.Header}>
				<Nav className="w-100" style={{ marginTop: "5px" }}>
					<Nav.Link className="nav-link-opposite active">Create New Account</Nav.Link>
				</Nav>
				<span>
					<Button
						variant="secondary"
						className="mx-2"
						onClick={() => accountStore.setCloseModalDialog(true)}
					>
						Cancel
					</Button>
				</span>
			</Navbar>
			<AccountsAddDialogImpl />
		</Modal>
	)
}

const AccountsAddDialogImpl: React.FC = () => {
	const sessionStore = userSessionStore()
	const accountData = JSON.parse(localStorage.getItem("accountData") + "")
	const methods = useForm<CreateUserType>({
		resolver: zodResolver(CreateUserSchema),
		defaultValues: accountData ? accountData : {},
	})
	const clearHashClear = clearHash()
	const form = JSON.parse(localStorage.getItem("accountData") + "")
	const accountStore = useAccountsDialogStore()

	methods.watch(() => {
		localStorage.setItem("accountData", JSON.stringify(methods.getValues()))
	})

	const {
		data: { data: users },
	} = useListUsersQuery()

	const {
		data: { data: roles },
	} = useListRolesQuery()

	const {
		data: { data: listSalesRepresentatives },
	} = getSalesRepresentativesApi({ role: "sales" })

	const { mutateAsync: creatUserAsync } = useCreateUserMutation()

	const onSubmit = async (value: CreateUserType) => {
		const response = await creatUserAsync(value)
		if (response.data.status === 400) {
			sessionStore.setShowAlertJson(response.data)
			return sessionStore.setShowAlert("visible", true)
		}
		sessionStore.setShowAlert("visible", false)
		useAccountsDialogStore.getState().resetState()
		queryClient.invalidateQueries()
	}

	const roleOptions = roles.map(
		(role: ResponseTypeRole) => ({ id: role.id, label: role.name }) as SelectOption,
	)

	const salesRepresentativesOptions = sortForReactSelect(
		listSalesRepresentatives,
		"id",
		"firstName",
		"lastName",
	)
	const parentUserOptions = users.map(
		(user: any) =>
			({
				id: user.id,
				label: `${user.firstName} ${user.lastName}`,
				role: `${user.role.name}`,
			}) as SelectOption,
	)

	const docSalesRep =
		salesRepresentativesOptions &&
		salesRepresentativesOptions
			.filter((e) => methods.watch("salesRepId")?.includes(e.value))
			.map((e) => ({
				value: e.value,
				label: e.label,
			}))

	const handleClose = (action: string) => {
		if (action === "minimize") {
			return handleMinimize()
		}
		closeForm()
	}

	const closeForm = () => {
		accountStore.resetState()
		clearFormState(form, methods.unregister)
		clearHashClear()
		sessionStore.setShowAlert("visible", false)
		accountStore.setCreateOpen(false)
		queryClient.invalidateQueries()
	}

	const handleMinimize = () => {
		methods.watch(async (formValues) => {
			await localStorage.setItem("accountData", JSON.stringify(formValues))
		})
		accountStore.setDraftState()
		accountStore.setCreateOpen(false)
		accountStore.setCloseModalDialog(false)
	}

	return (
		<React.Fragment>
			<FormProvider {...methods}>
				<CloseDialog visible={accountStore.closeModalDialog} callBackFunction={handleClose} />
				<Form onSubmit={methods.handleSubmit(onSubmit)}>
					<Modal.Body>
						<Alert variant="danger" show={sessionStore.showAlert.visible}>
							{sessionStore.showAlert.message}
						</Alert>
						<Row>
							<Col>
								<BoundControl
									required
									name="firstName"
									placeholder="First Name"
									label="First Name"
									autoFocus
								/>
							</Col>
							<Col>
								<BoundControl required name="lastName" placeholder="Last Name" label="Last Name" />
							</Col>
							<Col className="d-flex align-items-center justify-content-center">
								<BoundCheckbox
									className="ms-4 mt-4"
									label="Teeth Movement"
									name="allowTeethMovement"
								/>
							</Col>
							<Col className="text-end">
								<Submit />
							</Col>
						</Row>
						<br />
						<Row>
							<Col>
								<BoundControl required name="email" placeholder="Email" label="Email" />
							</Col>
							<Col>
								<BoundControl
									type="number"
									required
									name="contactNumber"
									placeholder="Phone Number"
									label="Contact Number"
								/>
							</Col>
							<Col />
						</Row>
						<br />
						<Row>
							<Col>
								<BoundSelect
									label="Select User Type"
									name="roleId"
									required
									options={roleOptions}
									placeholder="Select Role"
								/>
							</Col>
							<Col>
								<BoundCheckbox
									className="ms-4 mt-4"
									defaultChecked={true}
									label="Make Account Active"
									name="status"
								/>
							</Col>
							<Col>
								<BoundCheckbox
									className="ms-4 mt-4"
									label="Two-Factor Authentication"
									name="enableTwoFactorAuth"
								/>
							</Col>
						</Row>
						<br />
						<DoctorSpecificInput>
							<Row>
								<Col>
									<BoundSelect
										label="Select parent user"
										name="parentAccountId"
										options={parentUserOptions}
										placeholder="Add Parent User if Applicable"
									/>
								</Col>

								<Col>
									<Form.Label>Sales Representative</Form.Label>
									<Select
										options={salesRepresentativesOptions}
										isSearchable={true}
										isClearable={true}
										isMulti
										value={docSalesRep}
										onChange={(event) => {
											const id = event.map((e) => e?.value)
											methods.setValue("salesRepId", [...id])
										}}
									/>
								</Col>
								<Col />
							</Row>
						</DoctorSpecificInput>
						<br />
						<DoctorSpecificInput>
							<Suspense fallback={<OverlaySpinner />}>
								<ServicePricing />
							</Suspense>
						</DoctorSpecificInput>
					</Modal.Body>
				</Form>
			</FormProvider>
		</React.Fragment>
	)
}

export default AccountsAddDialog

const ServicePricing = () => {
	const {
		data: { services },
	} = useListServicesQuery()
	const { control } = useFormContext<CreateUserType>()
	const arrayMethods = useFieldArray({ name: "servicePricings", control })

	const handleServiceSelection: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
		const selectedService = services.find((service) => service.id === e.target.value)

		if (!selectedService) {
			throw new Error("Unhandled: Non existent service selected")
		}

		arrayMethods.append({
			serviceId: selectedService?.id,
		})
	}

	return (
		<React.Fragment>
			<Row className="mt-4 mb-4">
				<Col md="4">
					<Form.Group>
						<Form.Label>Set Pricing for Services</Form.Label>
						<Form.Select onChange={handleServiceSelection} value="">
							<option value="" disabled>
								Select service
							</option>
							{services
								.filter((s) => !arrayMethods.fields.find((f) => f.serviceId === s.id))
								.map((service) => (
									<option value={service.id} key={service.id}>
										{service.name}
									</option>
								))}
						</Form.Select>
					</Form.Group>
				</Col>
			</Row>
			{arrayMethods.fields.length > 0 ? (
				<Row className="mb-2">
					<Col></Col>
					<Col>Upper Jaw price</Col>
					<Col>Lower Jaw price</Col>
					<Col>Both Jaw price</Col>
					<Col>Remove Service</Col>
				</Row>
			) : null}
			{arrayMethods.fields.map((item, index) => {
				const current = services.find((service) => service.id === item.serviceId)
				if (!current) {
					return null
				}
				return (
					<>
						<Row key={item.id}>
							<Col>{current.name}</Col>
							<Col>
								<BoundControlPricing
									name={`servicePricings.${index}.priceUpper`}
									placeholder="Upper jaw price"
								/>
							</Col>
							<Col>
								<BoundControlPricing
									name={`servicePricings.${index}.priceLower`}
									placeholder="Lower jaw price"
								/>
							</Col>
							<Col>
								<BoundControlPricing
									name={`servicePricings.${index}.priceBoth`}
									placeholder="Both jaw price"
								/>
							</Col>

							<Col>
								<Button
									variant="secondary"
									className="mx-2"
									onClick={() => arrayMethods.remove(index)}
								>
									Remove
								</Button>
							</Col>
						</Row>
					</>
				)
			})}
		</React.Fragment>
	)
}
