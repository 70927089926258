import React, { Suspense } from "react"
import { Modal, Nav, Navbar, Table } from "react-bootstrap"
import {
	AddressSchemaType,
	useListAddressQuery,
} from "../../../services/CelebDoctorAddress.Service"
import { infoMapper } from "../../utlity/GenricFunctions"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import { useAccountsDialogStore } from "../../../store/accountsDialogStore"

const ViewDoctorClinic = () => {
	const { viewClinic, setViewClinic } = useAccountsDialogStore()

	return viewClinic ? (
		<Suspense>
			<Modal
				show={viewClinic != ""}
				autoFocus={true}
				onHide={() => setViewClinic( "" )}
				size="lg"
				style={{ backdropFilter: "blur(2px)" }}
			>
				<Navbar style={{ backgroundColor: "#d9d9d9" }} as={Modal.Header} closeButton>
					<Nav>
						<Nav.Link className="nav-link-opposite mt-1" active={true}>
							Offices
						</Nav.Link>
					</Nav>
				</Navbar>
				<ViewDoctorClinicImp />
			</Modal>
		</Suspense>
	) : null
}

const ViewDoctorClinicImp = () => {
	const { viewClinic } = useAccountsDialogStore()
	const { data } = useListAddressQuery( viewClinic )
	const addressData: AddressSchemaType[] = data.Address
	return (
		<React.Fragment>
			<Modal.Body>
				<Table striped bordered hover className="my-1">
					<thead>
						<tr>
							<th>Office Name</th>
							<th>Address</th>
						</tr>
					</thead>
					<tbody>
						<CheckGenericPermission genericLabel="Office List">
							{addressData.map( ( office: AddressSchemaType ) => {
								return (
									<tr>
										<td>{office.name}</td>
										<td>
											{infoMapper( office, [ "id", "createdAt", "updatedAt", "doctorId", "deleted" ] )}
										</td>
									</tr>
								)
							} )}
						</CheckGenericPermission>
					</tbody>
				</Table>
			</Modal.Body>
		</React.Fragment>
	)
}

export default ViewDoctorClinic
