import { zodResolver } from "@hookform/resolvers/zod"
import React, { Suspense } from "react"
import { Button, Form, Modal, Nav, Navbar, Row, Table } from "react-bootstrap"
import { FormProvider, get, useForm, useFormContext } from "react-hook-form"
import { z } from "zod"
import { OverlaySpinner } from "../../../../assets/styles/components/loader/loadingOverlay"
import { queryClient } from "../../../../providers/QueryProvider"
import {
	useGetPaymentPriceQuery,
	useUpdateTreatmentPricing,
} from "../../../../services/CelebPayments.Service"
import { useCommonStore } from "../../../../store/commonStore"

const CustomPricing = () => {
	const commonStore = useCommonStore()

	return (
		<Modal
			show={commonStore.openCustomPricing !== null}
			size="lg"
			onHide={() => commonStore.setOpenCustomPricing( null )}
		>
			<Navbar
				style={{ backgroundColor: "#f5f5f5", borderBottom: "1px solid #ccc" }}
				as={Modal.Header}
				closeButton
			>
				<Nav className="w-100" style={{ marginTop: "5px" }}>
					<Nav.Link
						className="nav-link-opposite"
						eventKey="#CustomPricing"
						active
						href="#CustomPricing"
					>
						Pricing
					</Nav.Link>
				</Nav>
			</Navbar>
			<CustomPricingImp />
		</Modal>
	)
}

const CustomPricingImp = () => {
	return (
		<Modal.Body>
			<Suspense fallback={<OverlaySpinner />}>
				<ServicePricing />
			</Suspense>
		</Modal.Body>
	)
}

const ServicePricing = () => {
	const commonStore = useCommonStore()
	const { data } = useGetPaymentPriceQuery( commonStore.openCustomPricing! )
	const activePricing = data?.applicablePricing?.from
	const { mutateAsync: update } = useUpdateTreatmentPricing()
	const methods = useForm<PatientCustomPricingType>( {
		resolver: zodResolver( CustomPatientPricingSchema ),
	} )

	React.useEffect( () => {
		methods.setValue( "price", activePricing == "treatmentPricing" ? data[ activePricing ]?.price : 0 )
	}, [ data?.applicablePricing ] )

	const onSubmit = ( values: PatientCustomPricingType ) => {
		update( { treatmentId: commonStore.openCustomPricing!, payload: values } )
		methods.unregister( "price" )
		queryClient.invalidateQueries()
		commonStore.setOpenCustomPricing( null )
	}

	return (
		<FormProvider {...methods}>
			<Form onSubmit={methods.handleSubmit( onSubmit )}>
				<Table striped bordered hover className="mb-4 shadow-sm">
					<thead className="thead-light">
						<tr>
							<th>Pricing Type</th>
							<th>Upper Jaw Price</th>
							<th>Lower Jaw Price</th>
							<th>Both Jaw Price</th>
						</tr>
					</thead>
					<tbody>
						<tr hidden={!data[ "userServicePricings" ]}>
							<td>Doctor Pricing</td>
							<td>{data[ "userServicePricings" ]?.priceUpper}</td>
							<td>{data[ "userServicePricings" ]?.priceLower}</td>
							<td>{data[ "userServicePricings" ]?.priceBoth}</td>
						</tr>
						<tr hidden={data[ "servicePricing" ]?.priceUpper == undefined}>
							<td>Global Pricing</td>
							<td>{data[ "servicePricing" ]?.priceUpper}</td>
							<td>{data[ "servicePricing" ]?.priceLower}</td>
							<td>{data[ "servicePricing" ]?.priceBoth}</td>
						</tr>

						<tr hidden={data[ "treatmentPricing" ]?.price == undefined}>
							<td>Treatment Pricing</td>
							<td>{data[ "treatmentPricing" ]?.price}</td>
						</tr>
					</tbody>
				</Table>

				<Row className="mb-3 d-flex justify-content-center">
					<BoundControlPricing name="price" placeholder="Enter New Pricing" />
				</Row>

				<div className="d-flex justify-content-end gap-2 mt-4">
					<Button variant="secondary" onClick={() => commonStore.setOpenCustomPricing( null )}>
						Cancel
					</Button>
					<Button variant="primary" type="submit">
						Submit
					</Button>
				</div>
			</Form>
		</FormProvider>
	)
}

const BoundControlPricing = ( {
	name,
	placeholder,
}: {
	name: keyof PatientCustomPricingType
	placeholder: string
} ) => {
	const methods = useFormContext<PatientCustomPricingType>()
	const handleInputChange = () => ( e: React.ChangeEvent<HTMLInputElement> ) => {
		const inputValue = e.currentTarget.value

		const parsedValue = parseFloat( inputValue )
		if ( parsedValue >= 0 || inputValue === "" ) {
			methods.setValue( name, parsedValue )
		} else {
			methods.setValue( name, 0 )
		}
	}

	return (
		<div>
			<div className="input-group">
				<Form.Label className="d-flex align-self-end">Set Custom Pricing</Form.Label>
				<div className="input-group-prepend" style={{ marginLeft: "10px" }}>
					<span
						className="input-group-text"
						style={{ borderEndEndRadius: "0px", borderStartEndRadius: "0px" }}
					>
						$
					</span>
				</div>
				<Form.Control
					type="number"
					step="0.01"
					value={methods.watch( "price" )}
					style={{ maxWidth: "15rem", borderEndEndRadius: "4px", borderStartEndRadius: "4px" }}
					placeholder={placeholder}
					{...methods.register( name, {
						valueAsNumber: true,
						onChange: handleInputChange,
					} )}
					isInvalid={!!get( methods.formState.errors, name )}
				/>
				<Form.Control.Feedback type="invalid" className="d-block">
					{get( methods.formState.errors, name )?.message}
				</Form.Control.Feedback>
			</div>
		</div>
	)
}

export const CustomPatientPricingSchema = z.object( {
	price: z
		.number( {
			required_error: "Pricing is required",
			invalid_type_error: "Pricing must be a number",
		} )
		.min( 0, "Price must be non-negative" )
		.optional(),
} )

export type PatientCustomPricingType = z.infer<typeof CustomPatientPricingSchema>

export default CustomPricing
